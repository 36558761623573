/* eslint-disable no-param-reassign */
import React, {
  useEffect, useRef, useState,
} from 'react';
import { toast } from 'react-toastify';
import { isDesktop } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import * as c from './components';
import WebsocketHandler from '../../socket/websocket';
import { useUserInfoContext } from '../../contexts/userInfoContext';
import Streams from './streams';
import Sidebar, { SideViewType } from './sidebar';
import Info from './info';
import { useMembers } from './context/members.context';
import { useConferenceInfo } from './context/conferenceInfo.context';
import RequestNotif from './toast/request';
import { generateUUID, sleep } from '../../commons/helper';
import ConferenceEventType from '../../types/conferenceEventType';
import ConferenceApi from '../../services/gateway/conferenceApi';
import ConfirmDialog from '../confirmDialog';
import { PubSubEvent, useSub } from './hook/pubSub';
import RoomMemberRoleType from '../../types/roomMemberRoleType';
import WebRTCManagerApi from '../../services/gateway/webRTCManagerApi';
import ConferenceJoinStatus from '../../types/conferenceJoinStatus';

interface MediaInfo {
  clientId: string;
  mid: string;
}

export interface ConferenceDataInterface {
  id: string;
  joinInfo: {
    id: string;
    createdAt: Date;
  };
  rtcConfiguration: any,
}

function Conference(
  {
    info,
  } :
  {
    readonly info: ConferenceDataInterface,
  },
) {
  const componentHashName = '#home';
  const { userInfo } = useUserInfoContext();
  const { conference, setConference } = useConferenceInfo();
  const [micEnable, setMicEnable] = useState(false);
  const [camEnable, setCamEnable] = useState(false);
  const [leaveConfirmDialog, setLeaveConfirmDialog] = useState(false);
  const [mediaStream, setMediaStream] = useState<MediaStream|null>(null);
  const [displayStream, setDisplayStream] = useState<MediaStream|null>(null);
  const [sideViewEnable, setSideViewEnable] = useState(false);
  const [newMessageEnable, setNewMessageEnable] = useState(false);
  // const [peerConn, setPeerConnection] = useState<RTCPeerConnection|null>(null);
  const peerConn = useRef<RTCPeerConnection|null>(null);
  const {
    members, setMembers, addMember, removeMember,
  } = useMembers();
  // const makingOffer = useRef<{[key: string] : boolean}>({});
  const makingOffer = useRef(false);
  const iceQueue = useRef<{
    local: any[];
    remote: any[];
  }>({
    local: [],
    remote: [],
  });
  const mediaInfo = useRef<MediaInfo[]>([]);
  const [showInfo, setShowInfo] = useState(!!conference?.code);
  const [reRender, setRerender] = useState(false);
  const isPolite = true;
  const navigate = useNavigate();

  const onMessage = async ({ message }: any) => {
    const { serverAppId, content } = message;
    // console.log('onMessage', content.type, serverAppId, content);
    if (serverAppId === 'CONFERENCE') {
      switch (content.type) {
        case ConferenceEventType.WEBRTC_NEGOTIATE: {
          const {
            roomId, fromJoinId, description, mediaInfo: mInfo,
          } = content.content;
          if (roomId !== info.id) return;
          mediaInfo.current = mInfo;
          let pCon;

          if (fromJoinId) {
            const jInfo = getJoinInfo(fromJoinId);
            pCon = jInfo?.peerConnection;
          } else {
            pCon = peerConn.current;
          }

          if (pCon) {
            const offerCollision = description.type === 'offer'
            && (makingOffer.current || pCon.signalingState !== 'stable');

            const ignoreOffer = !isPolite && offerCollision;
            // console.log('WEBRTC_NEGOTIATE', pCon.signalingState, ignoreOffer, offerCollision);

            if (ignoreOffer) {
              return;
            }

            // if (offerCollision) {
            //   await pCon.setLocalDescription({ type: 'rollback' });
            // }

            await pCon.setRemoteDescription(description);
            if (description.type === 'offer') {
              makingOffer.current = true;
              await pCon.setLocalDescription();

              // console.log('WEBRTC_NEGOTIATE_01', pCon.localDescription?.sdp);
              await ConferenceApi.webrtcNegotiate({
                roomId: info.id,
                fromJoinId: info.joinInfo.id,
                // toJoinId: fromJoinId,
                description: pCon.localDescription,
              });
              makingOffer.current = false;
            }

            await handleQueueIceCandidate();
          }
          break;
        }

        case ConferenceEventType.WEBRTC_ICE_CANDIDATE: {
          const candidateContent = content.content;
          if (candidateContent.roomId !== info.id) return;

          // console.log('candidate_02', candidateContent.candidate?.candidate);
          try {
            if (peerConn.current?.remoteDescription) {
              await peerConn.current?.addIceCandidate(candidateContent.candidate);
            } else {
              iceQueue.current.remote.push(candidateContent.candidate);
            }
          } catch (e) {
            console.log('addIceCandidate_exp', e);
          }
          break;
        }

        case ConferenceEventType.LEAVED: {
          const { room, user, joinId } = content.content;
          if (room.id !== info.id) return;

          let changed = false;
          members.forEach((member) => {
            if (member.user.id === user.id) {
              const joinsInfo = member.joinsInfo.filter(
                (jInfo) => jInfo.id !== joinId,
              );

              if (member.joinsInfo.length !== joinsInfo.length) {
                member.joinsInfo = joinsInfo;
                changed = true;
              }
            }
          });

          if (changed) {
            const usr = user;
            const msg = `${usr.username || usr.nickname} leave call`;
            toast.info(msg);
            setMembers([...members]);
          } else {
            console.log('user is not joineddddddddd');
          }
          break;
        }

        case ConferenceEventType.JOINED: {
          const {
            user, roomId, joinInfo, memberRole, memberId,
          } = content.content;
          if (roomId !== info.id) return;
          const memInfo = members.find((member) => member.user.id === user.id);
          if (memInfo) {
            const currentJinfo = memInfo.joinsInfo.find((jInfo) => jInfo.id === joinInfo.id);

            if (currentJinfo) {
              currentJinfo.status = ConferenceJoinStatus.CONNECTED;
            } else {
              memInfo.joinsInfo.push(mapJoinInfo(joinInfo));
            }
          } else {
            members.push(mapMember({
              user,
              joinsInfo: [joinInfo],
              role: memberRole,
              id: memberId,
            }));
          }
          setMembers([...members]);
          break;
        }

        case ConferenceEventType.REQUEST: {
          const { user, room, requestId } = content.content;
          if (room.id !== info.id) return;

          const toastId = generateUUID();

          toast(
            RequestNotif({
              user,
              onAccept: async () => {
                try {
                  const res = await ConferenceApi.requestAccept({
                    requestId,
                  });
                  addMember({
                    id: res.memberId,
                    user,
                    joinsInfo: [],
                    role: RoomMemberRoleType.MEMBER,
                  });
                  toast.dismiss(toastId);
                } catch (e: any) {
                  toast.error(e.message);
                }
              },
              onReject: async () => {
                try {
                  await ConferenceApi.requestReject({
                    requestId,
                  });
                } catch (e: any) {
                  toast.error(e.message);
                } finally {
                  toast.dismiss(toastId);
                }
              },
            }),
            {
              position: 'top-center',
              hideProgressBar: true,
              closeOnClick: false,
              icon: false,
              toastId,
              autoClose: false,
              closeButton: false,
            },
          );
          break;
        }

        case ConferenceEventType.REMOVED: {
          removeMember(content.content.removedMember.id);

          if (userInfo?.id === content.content.removedMember.user.id) {
            leaveCall(true);

            const removerUser = content.content.removerMember.user;
            if (userInfo?.id !== removerUser.id) {
              toast.error(`you removed by ${removerUser.username || removerUser.nickname}`);
            }
          }

          break;
        }

        case ConferenceEventType.JOIN_REMOVED: {
          const {
            room, removerMember, removedMember, removedJoinId,
          } = content.content;
          if (room.id !== info.id) return;

          let changed = false;
          members.forEach((member) => {
            if (member.id === removedMember.id) {
              const joinsInfo = member.joinsInfo.filter(
                (jInfo) => jInfo.id !== removedJoinId,
              );

              if (member.joinsInfo.length !== joinsInfo.length) {
                member.joinsInfo = joinsInfo;
                changed = true;
              }
            }
          });

          if (changed) {
            const removerUser = removerMember.user;
            const removedUser = removedMember.user;

            if (removerUser.id !== userInfo?.id) {
              let msg;
              if (removerUser.id === removedMember.id) {
                msg = `${removedUser.username || removedUser.nickname} leaved.`;
              } else {
                msg = `${removedUser.username || removedUser.nickname} removed by ${removerUser.username || removerUser.nickname}`;
              }
              toast.info(msg);
            }

            if (info.joinInfo.id === removedJoinId) {
              leaveCall(true);
            } else {
              setMembers([...members]);
            }
          } else {
            console.log('user is not joineddddddddd');
          }

          break;
        }

        case ConferenceEventType.JOIN_CONNECTED: {
          const { room, user, joinId } = content.content;
          if (room.id !== info.id) return;

          members.forEach((member) => {
            if (member.user.id === user.id) {
              member.joinsInfo.filter(
                (jInfo) => jInfo.id !== joinId,
              );
              member.joinsInfo.forEach((joinInfo) => {
                if (joinInfo.id === joinId) {
                  joinInfo.status = ConferenceJoinStatus.CONNECTED;
                }
              });
            }
          });

          setMembers([...members]);
          break;
        }

        case ConferenceEventType.JOIN_DISCONNECTED: {
          const { room, user, joinId } = content.content;
          if (room.id !== info.id) return;

          members.forEach((member) => {
            if (member.user.id === user.id) {
              member.joinsInfo.filter(
                (jInfo) => jInfo.id !== joinId,
              );
              member.joinsInfo.forEach((joinInfo) => {
                if (joinInfo.id === joinId) {
                  joinInfo.status = ConferenceJoinStatus.DISCONNECTED;
                }
              });
            }
          });

          setMembers([...members]);
          break;
        }

        default:
            //
      }
    }
  };

  const getJoinIdFromTrack = (pConn: RTCPeerConnection, track: MediaStreamTrack) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const transceiver of pConn.getTransceivers()) {
      if (transceiver.receiver?.track?.id === track.id) {
        // eslint-disable-next-line no-restricted-syntax
        for (const mInfo of mediaInfo.current) {
          if (mInfo.mid === transceiver.mid) {
            return mInfo.clientId;
          }
        }
      }
    }
    return '';
  };

  /*
    PeerConnection event callbacks
  */
  const oniceconnectionstatechange = async () => {
    // console.log('oniceconnectionstatechange', peerConn.current?.iceConnectionState);
    if (
      peerConn.current?.iceConnectionState === 'disconnected'
      && (mediaStream !== null || displayStream !== null)) {
      // if (userInfo?.id === '39391089-2340-447a-b886-20c9375eb46b') return;

      // peerConn.current?.restartIce();

      createConnection(info.rtcConfiguration);

      members.forEach((member) => {
        member.joinsInfo.forEach((joinInfo) => {
          if (joinInfo.id === info.joinInfo.id) return;
          joinInfo.remoteStreams.forEach((stream) => {
            removeStreamFromParticipant({
              stream,
              joinId: joinInfo.id,
            });
          });
        });
      });

      // for definePeerConnectionEvent
      setRerender(!reRender);

      // await sleep(3000);

      await createOffer({ peerConnection: peerConn.current });
    }
  };

  const onicecandidate = async (e: RTCPeerConnectionIceEvent) => {
    if (!e.candidate) return;
    // console.log('onicecandidate', e.candidate?.candidate);
    if (!peerConn.current?.remoteDescription) {
      iceQueue.current.local.push(e.candidate);
      return;
    }

    try {
      await ConferenceApi.webrtcIceCandidate({
        roomId: info.id,
        fromJoinId: info.joinInfo.id,
        candidate: e.candidate,
      });
    } catch (exp) {
      console.log('conferenceWebrtcIceCandidate_exp', exp);
    }
  };

  const onicegatheringstatechange = () => {
    // console.log('onicegatheringstatechange', peerConn.current?.iceConnectionState);
    if (peerConn.current?.iceConnectionState === 'failed') {
      peerConn.current?.restartIce();
    }
  };

  const onnegotiationneeded = async () => {
    // console.log('negotiationneeded');
    if (!peerConn.current) return;
    await createOffer({ peerConnection: peerConn.current });
  };

  const ontrack = ({ track, streams: [stream] }: RTCTrackEvent) => {
    if (!peerConn.current) return;

    const jId = getJoinIdFromTrack(peerConn.current, track);
    // console.log('ontrack', track.kind, track.id, stream.id);
    addStreamToParticipant({ joinId: jId, stream });

    // track.onended = () => {
    //   console.log('bbbbbbbbbbbbbbbbbbbb');
    // };

    stream.onremovetrack = () => {
      // console.log('onremovetrack', e.track.kind, e.track.id, stream.getTracks().length);
      // e.track.stop();
      if (stream.getTracks().length === 0) {
        removeStreamFromParticipant({
          stream,
          joinId: jId,
        });
      } else {
        setMembers([...members]);
      }
    };
  };

  const onicecandidateerror = (e: Event) => { console.log('onicecandidateerror', e); };

  const handleQueueIceCandidate = async () => {
    iceQueue.current.remote.forEach(async (candidate) => {
      try {
        await peerConn.current?.addIceCandidate(candidate);
      } catch (e) {
        console.log('handleQueueIceCandidate_e', e);
      }
    });

    iceQueue.current.local.forEach(async (candidate) => {
      try {
        await ConferenceApi.webrtcIceCandidate({
          roomId: info.id,
          fromJoinId: info.joinInfo.id,
          candidate,
        });
      } catch (exp) {
        console.log('handleQueueIceCandidate_exp', exp);
      }
    });

    iceQueue.current.remote = [];
    iceQueue.current.local = [];
  };

  const createOffer = async (
    {
      peerConnection,
      // tryCount = 0,
      offerToReceiveAudio,
      offerToReceiveVideo,
    }:
    {
      peerConnection: RTCPeerConnection,
      tryCount?: number,
      offerToReceiveAudio?: boolean,
      offerToReceiveVideo?: boolean
    },
  ) => {
    // console.log('createOffer_01', peerConnection.signalingState, makingOffer.current);
    // if (makingOffer.current || peerConnection.signalingState !== 'stable') {
    //   return;
    // }

    makingOffer.current = true;
    if (typeof offerToReceiveAudio === 'boolean') {
      const sdp = await peerConnection.createOffer({
        offerToReceiveAudio,
        offerToReceiveVideo,
      });
      await peerConnection.setLocalDescription(sdp);
    } else {
      await peerConnection.setLocalDescription();
    }

    try {
      const res = await ConferenceApi.webrtcNegotiate({
        roomId: info.id,
        fromJoinId: info.joinInfo.id,
        description: peerConnection.localDescription,
      });

      if (res.answer) {
        // console.log('1111111', peerConnection.localDescription?.sdp);
        // console.log('2222222', res.answer?.sdp);
        await peerConnection.setRemoteDescription(res.answer);
      }
      await handleQueueIceCandidate();
      makingOffer.current = false;
    } catch (e: any) {
      // if (e.status >= 500 && tryCount < 3) {
      //   await createOffer({ peerConnection, tryCount: tryCount + 1 });
      // }
    }
    // console.log('createOffer_02', peerConnection.signalingState);
  };

  const mapJoinInfo = (joinInfo: any) => ({
    id: joinInfo.id,
    status: joinInfo.status,
    createdAt: new Date(joinInfo.createdAt),
    remoteStreams: [],
  });

  const mapMember = (member: any) => ({
    user: member.user,
    joinsInfo: member.joinsInfo.map(mapJoinInfo),
    role: member.role,
    id: member.id,
  });

  const getJoinInfo = (joinId: string) => {
    const partic = members.find(
      (p) => p.joinsInfo.some((jInfo) => jInfo.id === joinId),
    );

    return partic?.joinsInfo.find((joinInfo) => joinInfo.id === joinId);
  };

  // const isPolite = (joinId: string) => {
  //   const joinInfo = getJoinInfo(joinId);
  //   if (joinInfo?.createdAt) {
  //     return info.joinInfo.createdAt > joinInfo.createdAt;
  //   }
  //   return true;
  // };

  const onReconnect = async ({ peerData }: any) => {
    // console.log('on reconnect', peerData);

    try {
      await ConferenceApi.reconnect({
        roomId: info.id,
        joinId: info.joinInfo.id,
        peerId: peerData.peerId,
      });
    } catch (e: any) {
      console.log('reconnect_err', e);
      if (e.status === 404) {
        leaveCall();
      }
    }
  };

  const toggleMic = async () => {
    const enable = !micEnable;
    if (enable) {
      try {
        await checkMedia({ micEnable: true, camEnable: false });
        setMicEnable(true);
      } catch (e: any) {
        toast.error(e.message);
        setMicEnable(false);
      }
    } else {
      if (mediaStream) {
        const tracks = mediaStream.getAudioTracks();
        tracks.forEach((track: MediaStreamTrack) => {
          track.stop();
          mediaStream.removeTrack(track);
          if (peerConn.current) {
            removeTrackFeomPeerConnection({
              track,
              peerConnection: peerConn.current,
            });
          }
        });

        if (mediaStream.getTracks().length === 0) {
          setMediaStream(null);
        }
      }
      setMicEnable(false);
    }
  };

  const toggleCam = async () => {
    const enable = !camEnable;
    if (enable) {
      try {
        await checkMedia({ micEnable: false, camEnable: true });
        setCamEnable(true);
      } catch (e: any) {
        toast.error(e.message);
        setCamEnable(false);
      }
    } else {
      if (mediaStream) {
        const tracks = mediaStream.getVideoTracks();
        tracks.forEach((track: MediaStreamTrack) => {
          track.stop();
          mediaStream.removeTrack(track);
          if (peerConn.current) {
            removeTrackFeomPeerConnection({
              track,
              peerConnection: peerConn.current,
            });
          }
        });

        if (mediaStream.getTracks().length === 0) {
          setMediaStream(null);
        }
      }
      setCamEnable(false);
    }
  };

  const checkMedia = async ({
    camEnable: camEnb, micEnable: micEnb,
  }: {
    camEnable: boolean;
    micEnable: boolean;
  }) => {
    if (!camEnb && !micEnb) return;
    if (mediaStream) {
      const videoTracks = mediaStream.getVideoTracks();
      const audioTracks = mediaStream.getAudioTracks();

      if ((camEnb && videoTracks.length === 0)
         || (micEnb && audioTracks.length === 0)) {
        const mStream = await navigator.mediaDevices.getUserMedia({
          video: camEnb,
          audio: micEnb,
        });

        const tracks = mStream.getTracks();

        if (tracks.length > 0) {
          tracks.forEach((track) => {
            mediaStream.addTrack(track);
          });

          setRerender(!reRender);
        }
      }
    } else {
      const mStream = await navigator.mediaDevices.getUserMedia({
        video: camEnb,
        audio: micEnb,
      });
      setMediaStream(mStream);
      setRerender(!reRender);
    }
  };

  const toggleShare = async () => {
    if (displayStream == null) {
      let captureStream = null;

      try {
        const params: any = {
          video: {
            displaySurface: 'window',
          },
          audio: {
            echoCancellation: true,
            noiseSuppression: true,
            sampleRate: 44100,
            suppressLocalAudioPlayback: true,
          },
        };
        captureStream = await navigator.mediaDevices.getDisplayMedia(params);

        captureStream.getTracks().forEach((track) => {
          track.addEventListener('ended', () => {
            // remove track from all of the connection
            if (peerConn.current) {
              removeTrackFeomPeerConnection({
                track,
                peerConnection: peerConn.current,
              });
            }
            setDisplayStream(null);
          });
        });
        setDisplayStream(captureStream);
      } catch (err: any) {
        // toast.error(err.message);
      }
    } else {
      const tracks = displayStream.getVideoTracks();
      tracks.forEach((track: MediaStreamTrack) => {
        track.stop();
        displayStream.removeTrack(track);
        if (peerConn.current) {
          removeTrackFeomPeerConnection({
            track,
            peerConnection: peerConn.current,
          });
        }
      });

      if (displayStream.getTracks().length === 0) {
        setDisplayStream(null);
      }
    }
  };

  const leaveCall = async (preventCallLeave = false) => {
    removeOnHashChangeEvent();
    await sleep(100);
    stopConnections();

    toast.dismiss();

    if (window.location.hash === componentHashName) {
      navigate(-1);
      await sleep(100);
    }

    setConference(null);

    if (conference?.code) {
      navigate(`/code/${conference.code}`, { replace: true });
    } else if (conference?.id) {
      navigate(`/id/${conference.id}`, { replace: true });
    }

    if (!preventCallLeave) {
      try {
        await ConferenceApi.leaveRoomJoin({
          roomId: info.id,
          joinId: info.joinInfo.id,
        });
      } catch (e) {
        console.log('leave_call_err', e);
      }
    }
  };

  const stopConnections = () => {
    mediaStream?.getTracks().forEach((track) => {
      track.stop();
    });

    displayStream?.getTracks().forEach((track) => {
      track.stop();
    });
    members.forEach((member) => {
      member.joinsInfo.forEach((joinInfo) => {
        if (joinInfo.peerConnection) {
          joinInfo.peerConnection.close();
        }
      });
    });

    peerConn.current?.close();
  };

  const createConnection = (config: any) => {
    // console.log('createConnection');
    const peerConnection = new RTCPeerConnection(config);

    peerConn.current = peerConnection;

    mediaStream?.getTracks().forEach((track: any) => {
      addTrackToPeerConnection({
        peerConnection,
        track,
        stream: mediaStream,
      });
    });

    displayStream?.getTracks().forEach((track) => {
      addTrackToPeerConnection({
        peerConnection,
        track,
        stream: displayStream,
      });
    });
  };

  const initialize = async () => {
    try {
      if (!userInfo) return;

      const { room: cnf } = await ConferenceApi.getRoom({
        roomId: info.id,
      });

      setConference(cnf);

      createConnection(info.rtcConfiguration);

      const { members: membrs } = await ConferenceApi.getRoomMembers({
        roomId: info.id,
      });

      const memberList: any = [];

      membrs.forEach((member) => {
        memberList.push(mapMember(member));
      });

      setMembers(memberList);

      try {
        const audio = new Audio('/assets/audios/join_call.ogg');
        await audio.play();
      } catch (e) {
        // TODO
      }

      await WebRTCManagerApi.sync({
        roomId: info.id,
        clientId: info.joinInfo.id,
      });
    } catch (e) {
      console.log('exp_getJoinUsers', e);
    }
  };

  const isTrackExistInPeerConnection = (
    { peerConnection, track }:
    {
      track: MediaStreamTrack;
      peerConnection: RTCPeerConnection
    },
  ) => peerConnection.getSenders().some((sender) => sender.track?.id === track.id);

  const addTrackToPeerConnection = async ({ track, peerConnection, stream }:
    { track: MediaStreamTrack; peerConnection: RTCPeerConnection, stream?: MediaStream }) => {
    // console.log('addTrackToPeerConnection', peerConnection.signalingState, stream);
    if (isTrackExistInPeerConnection({ track, peerConnection })) return;

    // console.log('addTrackToPeerConnection_1', peerConnection.signalingState);
    // if (peerConnection.signalingState === 'have-local-offer') {
    //   await peerConnection.setRemoteDescription({ type: 'rollback' });
    // }

    if (stream) {
      peerConnection.addTrack(track, stream);
    } else {
      peerConnection.addTrack(track);
    }
  };

  const removeTrackFeomPeerConnection = ({ track, peerConnection }:
    { track: MediaStreamTrack; peerConnection: RTCPeerConnection}) => {
    const sender = peerConnection.getSenders().find((snd) => snd.track?.id === track.id);
    if (sender) {
      peerConnection.removeTrack(sender);
    }
  };

  const addStreamToParticipant = ({
    stream, joinId,
  }:
    {stream: MediaStream; joinId: string}) => {
    const jInfo = getJoinInfo(joinId);
    // console.log('addStreamToParticipant_01', joinId);
    if (jInfo) {
      const isExist = jInfo.remoteStreams.some((str) => str.id === stream.id);

      // console.log('addStreamToParticipant_02', isExist);
      if (!isExist) {
        jInfo.remoteStreams.push(stream);
      }
      setMembers([...members]);
    }
  };

  const removeStreamFromParticipant = ({
    stream, joinId,
  }:
    {stream: MediaStream; joinId: string}) => {
    const jInfo = getJoinInfo(joinId);

    if (jInfo) {
      jInfo.remoteStreams = jInfo.remoteStreams.filter((str) => str.id !== stream.id);
      setMembers([...members]);
      setRerender(!reRender);
    }
  };

  const definePeerConnectionEvent = (peerConnection: RTCPeerConnection) => {
    // console.log('definePeerConnectionEvent');
    peerConnection.oniceconnectionstatechange = oniceconnectionstatechange;
    peerConnection.onicegatheringstatechange = onicegatheringstatechange;
    peerConnection.onnegotiationneeded = onnegotiationneeded;
    peerConnection.onicecandidateerror = onicecandidateerror;
    peerConnection.onicecandidate = onicecandidate;
    peerConnection.ontrack = ontrack;
  };

  const removePeerConnectionEvent = (peerConnection: RTCPeerConnection) => {
    // console.log('removePeerConnectionEvent');
    peerConnection.oniceconnectionstatechange = null;
    peerConnection.onicegatheringstatechange = null;
    peerConnection.onnegotiationneeded = null;
    peerConnection.onicecandidateerror = null;
    peerConnection.onicecandidate = null;
    peerConnection.ontrack = null;
  };

  const openSidebar = () => {
    if (newMessageEnable) {
      setNewMessageEnable(false);
    }
    setSideViewEnable(true);
  };

  const closeSidebar = () => {
    setSideViewEnable(false);
  };

  const toggleSidebar = () => {
    if (sideViewEnable) {
      closeSidebar();
    } else {
      openSidebar();
    }
  };

  const openLeaveConfDialog = () => {
    setLeaveConfirmDialog(true);
  };

  const closeLeaveConfDialog = () => {
    setLeaveConfirmDialog(false);
  };

  const handleOnHashChange = (e: any) => {
    const { oldURL } = e;
    const oldHash = `#${oldURL.split('#')[1]}`;
    // console.log('handleOnHashChange', window.location.hash, leaveConfirmDialog, oldHash);
    if (window.location.hash === '' && oldHash === componentHashName) {
      if (leaveConfirmDialog) return;
      // navigate(componentHashName);
      openLeaveConfDialog();
    }
  };

  const removeOnHashChangeEvent = () => {
    window.removeEventListener('hashchange', handleOnHashChange);
  };

  const onChatMessage = () => {
    if (!sideViewEnable && !newMessageEnable) {
      setNewMessageEnable(true);
    }
  };

  useEffect(() => {
    if (peerConn.current) {
      definePeerConnectionEvent(peerConn.current);
    }

    return () => {
      if (peerConn.current) {
        removePeerConnectionEvent(peerConn.current);
      }
    };
  }, [oniceconnectionstatechange]);

  useEffect(() => {
    WebsocketHandler.addEventListener('message', onMessage);
    WebsocketHandler.addEventListener('reconnect', onReconnect);
    WebsocketHandler.addEventListener('connect', onReconnect);

    return () => {
      WebsocketHandler.removeEventListener('message', onMessage);
      WebsocketHandler.removeEventListener('reconnect', onReconnect);
      WebsocketHandler.removeEventListener('connect', onReconnect);
    };
  }, [onMessage]);

  useEffect(() => {
    (async () => {
      initialize();
      try {
        await checkMedia({ micEnable, camEnable });
      } catch (e) {
        if (micEnable) {
          setMicEnable(false);
        }
        if (camEnable) {
          setCamEnable(false);
        }
      }
    })();
  }, []);

  useEffect(() => {
    if (!mediaStream) return;

    mediaStream.getTracks().forEach((track) => {
      if (!peerConn.current) return;
      addTrackToPeerConnection({
        peerConnection: peerConn.current,
        track,
        stream: mediaStream,
      });
    });
  }, [mediaStream?.getTracks().length ?? 0]);

  useEffect(() => {
    if (!displayStream) return;

    displayStream.getTracks().forEach((track) => {
      if (!peerConn.current) return;
      addTrackToPeerConnection({
        peerConnection: peerConn.current,
        track,
        stream: displayStream,
      });
    });
  }, [displayStream?.getTracks().length ?? 0]);

  useEffect(() => () => {
    mediaStream?.getTracks().forEach((track: any) => track.stop());
  }, [mediaStream]);

  useEffect(() => () => {
    displayStream?.getTracks().forEach((track: any) => track.stop());
  }, [displayStream]);

  useEffect(() => {
    navigate(componentHashName);
    const intervalId = setInterval(async () => {
      try {
        await WebRTCManagerApi.ping({
          roomId: info.id,
          clientId: info.joinInfo.id,
        });
      } catch (e) {
        console.log('ping_exp', e);
      }
    }, 30000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    // window.onpopstate = function () {
    //   console.log('sssssssssssssss');
    // };
    window.addEventListener('hashchange', handleOnHashChange);
    return removeOnHashChangeEvent;
  }, [handleOnHashChange]);

  useSub(PubSubEvent.ROOM_CODE_CHANGE, (data) => {
    if (!conference) return;
    conference.code = data.code;
    setConference(conference);
  });

  useSub(PubSubEvent.ROOM_NAME_CHANGE, (data) => {
    if (!conference) return;
    conference.name = data.name;
    setConference(conference);
  });

  return (
    <c.Container>
      <c.ContainerInner>
        <c.TopContainer>
          {
            conference && (
            <Sidebar
              threadId={conference?.threadId}
              onClose={() => {
                closeSidebar();
              }}
              viewType={SideViewType.CHAT}
              open={sideViewEnable}
              room={conference}
              onNewMessage={onChatMessage}
            />
            )
          }
          <c.ViewContainer>
            <Streams
              localStream={mediaStream}
              displayStream={displayStream}
              joinId={info.joinInfo.id}
              roomId={info.id}
            />
            {/* <c.LargPlayContainer>
          </c.LargPlayContainer> */}
            {
            showInfo && (
              <Info
                code={conference?.code}
                id={conference?.id}
                onClose={() => { setShowInfo(false); }}
              />
            )
          }
          </c.ViewContainer>
        </c.TopContainer>
        <c.BottomContainer>
          <c.BottomRightContainer>
            <c.IconContainer
              $enable={!sideViewEnable}
              onClick={() => toggleSidebar()}
            >
              <c.MoreIcon />
              {newMessageEnable && <c.NewMessageIcon />}
            </c.IconContainer>
          </c.BottomRightContainer>
          <c.BottomCenterContainer>
            <c.IconContainer
              $enable={false}
              onClick={() => {
                openLeaveConfDialog();
              }}
            >
              <c.CallIcon />
            </c.IconContainer>
            <c.IconContainer
              $enable={micEnable}
              onClick={() => toggleMic()}
            >
              { micEnable ? <c.MicEnableIcon /> : <c.MicDisableIcon /> }
            </c.IconContainer>
            <c.IconContainer
              $enable={camEnable}
              onClick={() => toggleCam()}
            >
              { camEnable ? <c.CamEnableIcon /> : <c.CamDisableIcon /> }
            </c.IconContainer>
            {
              isDesktop
              && (
              <c.IconContainer
                $enable={displayStream !== null}
                onClick={() => toggleShare()}
              >
                <c.ShareScreenIcon />
              </c.IconContainer>
              )
            }
          </c.BottomCenterContainer>
          <c.BottomLeftContainer
            onClick={() => {
              setShowInfo(true);
            }}
          >
            <c.InfoIcon />
          </c.BottomLeftContainer>
        </c.BottomContainer>
      </c.ContainerInner>
      {leaveConfirmDialog && (
        <ConfirmDialog
          title="leave"
          description="do you want leave?"
          // acceptMessage="yes"
          // rejectMessage="no"
          onClose={() => {
            closeLeaveConfDialog();
          }}
          onAccept={() => {
            leaveCall();
          }}
          onReject={() => {
            closeLeaveConfDialog();
          }}
        />
      )}
    </c.Container>
  );
}

export default Conference;
