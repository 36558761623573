import Styled from 'styled-components';
import { PiUserBold, PiUsersThreeBold } from 'react-icons/pi';
import { RiLogoutBoxRLine } from 'react-icons/ri';
import { FaHome } from 'react-icons/fa';
import { LuContact } from 'react-icons/lu';

export const Container = Styled.div`
`;

export const ContainerInner = Styled.div`
  position: relative;
  backdrop-filter: blur(7px);
  background-color: rgb(26 25 25 / 40%);
  padding: 30px 15px 10px 15px;
  min-width: 300px;

`;

export const InfoContainer = Styled.div`
  text-align: center;
`;

export const ProfileImageContainer = Styled.div`
  
`;

export const NameContainer = Styled.div`
  margin-top: 5px;
`;

export const ItemsContainer = Styled.div`
  margin: 15px 0;
  display: flex;
  gap: 15px;
  justify-content: space-around;
`;

export const ItemIconContainer = Styled.div`
  background: white;
  display: flex;
  padding: 10px;
  border-radius: 50%;
  color: black;
  cursor: pointer;
  transition: ${(props) => props.theme.custom.transition};

  &:hover {
    transform: scale(1.1);
  }
`;

export const Divider = Styled.div`
  border-top: 1px solid #bbbbbb87;
  margin: 20px 0 10px 0;
  width: 100%;
`;

export const UserIcon = Styled(PiUserBold)`
  font-size: 3rem;
  padding: 8px;
  border-radius: 50%;
  background: gray;
  cursor: pointer;
`;

export const UsersIcon = Styled(PiUsersThreeBold)`
  font-size: 1.5rem;
`;

export const HomeIcon = Styled(FaHome)`
  font-size: 1.5rem;
`;

export const LogoutIcon = Styled(RiLogoutBoxRLine)`
  font-size: 1.5rem;
`;

export const UserImage = Styled.img`
    width: 64px;
    height: 64px;
    border-radius: 50%;
`;

export const ContactusIcon = Styled(LuContact)`
  font-size: 1.5rem;
`;
