import React from 'react';
import * as c from './components';

function RoomCard({
  name,
  time,
  selected,
  onClick,
}: {
  name: string;
  time: string;
  selected: boolean;
  onClick: () => void
}) {
  return (
    <c.Container onClick={onClick} selected={selected}>
      <c.ContainerInner>
        <div>
          <c.NameText selected={selected}>{name}</c.NameText>
          <c.TimeText selected={selected}>{time}</c.TimeText>
        </div>
      </c.ContainerInner>
    </c.Container>
  );
}

export default RoomCard;
