import React, { useState } from 'react';
import {
  FormControl, MenuItem, Select, SelectChangeEvent,
} from '@mui/material';
import { toast } from 'react-toastify';
import {
  Container, NameContainer, RemoveButton, RemoveContainer, RemoveIcon, RoleContainer,
} from './components';
import RoomMemberRoleType from '../../../../../../../../types/roomMemberRoleType';
import ConferenceApi from '../../../../../../../../services/gateway/conferenceApi';
import { useMembers } from '../../../../../../context/members.context';
import ConfirmDialog from '../../../../../../../confirmDialog';

function Member({
  name,
  role,
  memberId,
  roomId,
  disable,
}: {
  readonly name: string;
  readonly role: number;
  readonly memberId: string;
  readonly roomId: string;
  readonly disable: boolean;
}) {
  const [userRole, setUserRole] = useState(role);
  const [removeLoading, setRemoveLoading] = useState(false);
  const [leaveConfirmDialog, setLeaveConfirmDialog] = useState(false);
  const { removeMember } = useMembers();

  const handleChange = async (event: SelectChangeEvent) => {
    const roleType = Number(event.target.value);
    setUserRole(roleType);

    try {
      await ConferenceApi.editRoomMember({
        memberId,
        roomId,
        role: roleType,
      });
    } catch (e: any) {
      toast.error(e.message);
    }
  };

  const remove = async () => {
    setRemoveLoading(true);
    try {
      await ConferenceApi.removeRoomMember({
        memberId,
        roomId,
      });
      removeMember(memberId);
    } catch (e: any) {
      toast.error(e.message);
      setRemoveLoading(true);
    }
  };

  return (
    <Container>
      <NameContainer>{name}</NameContainer>
      <RoleContainer>
        <FormControl size="small" sx={{ minWidth: '100%' }}>
          <Select
            value={String(userRole)}
            onChange={handleChange}
            disabled={disable}
          >
            <MenuItem value={RoomMemberRoleType.MEMBER}>member</MenuItem>
            <MenuItem value={RoomMemberRoleType.ADMIN}>admin</MenuItem>
            { role === RoomMemberRoleType.CREATOR
              && <MenuItem value={RoomMemberRoleType.CREATOR}>creator</MenuItem> }
          </Select>
        </FormControl>
      </RoleContainer>
      <RemoveContainer>
        <RemoveButton
          $disabled={disable || removeLoading}
          onClick={() => setLeaveConfirmDialog(true)}
          // fullWidth
        >
          <RemoveIcon $disabled={disable || removeLoading} />
        </RemoveButton>
      </RemoveContainer>
      {leaveConfirmDialog && (
        <ConfirmDialog
          title="remove"
          description="are you sure?"
          // acceptMessage="yes"
          // rejectMessage="no"
          onClose={() => {
            setLeaveConfirmDialog(false);
          }}
          onAccept={() => {
            setLeaveConfirmDialog(false);
            remove();
          }}
          onReject={() => {
            setLeaveConfirmDialog(false);
          }}
        />
      )}
    </Container>
  );
}

export default Member;
