import Url from './url';
import User from '../../dto/user.dto';
import RequestHandler from './requestHandler';

class AuthApi {
  private static async request({
    method, url, data, form, headers,
  }:{method:'GET'|'POST'|'DELETE'|'PUT', url: string, data?: any, form?: any, headers: any})
  :Promise<any> {
    const res = await RequestHandler.request({
      method, url, data, form, headers,
    });
    return res;
  }

  static async login(
    {
      code, redirectUri, clientCode,
    }:
    {
      code:string;
      redirectUri?: string;
      clientCode: string;
    },
  )
  :Promise<{user: any, token: { accessToken: string}}> {
    const res = await this.request({
      method: 'GET',
      url: Url.AUTHENTICATION_LOGIN,
      data: {
        code, redirectUri, clientCode,
      },
      headers: {},
    });
    return res;
  }

  static async logout({ token }: {token?:string})
  :Promise<{}> {
    const headers:any = {};
    if (token) headers.token = token;

    const res = await this.request({
      method: 'DELETE',
      url: Url.AUTHENTICATION_LOGOUT,
      data: {},
      headers,
    });
    return res;
  }

  static async podOTPLogin({
    os, osVersion, deviceType,
    deviceId, deviceName,
    appVersion, cellphoneNumber,
    clientCode,
  }:
     {
      os: string;
      osVersion: string;
      deviceType: 'Mobile'|'Phone'|'Desktop'|'Tablet'|'Console'|'TV Device'|'Mobile Device'|'Unknown';
      deviceId: string;
      deviceName: string;
      appVersion: string;
      cellphoneNumber: string;
      clientCode: string;
    })
  :Promise<{expiresIn: number, keyId: string}> {
    const res = await this.request({
      method: 'POST',
      url: Url.AUTHENTICATION_OTP_POD_LOGIN,
      data: {
        os,
        osVersion,
        deviceType,
        deviceId,
        deviceName,
        appVersion,
        cellphoneNumber,
        clientCode,
      },
      headers: {},
    });
    return res;
  }

  static async podOTPVerify({
    cellphoneNumber, code, keyId, clientCode,
  }:
     {
      cellphoneNumber: string;
      code: string;
      keyId: string;
      clientCode: string;
    })
  :Promise<{user: any, token: { accessToken: string}}> {
    const res = await this.request({
      method: 'POST',
      url: Url.AUTHENTICATION_OTP_POD_VERIFY,
      data: {
        cellphoneNumber, code, keyId, clientCode,
      },
      headers: {},
    });
    return res;
  }

  static async getUserProfile({
    userId,
  }:
     {
      userId?: string;
    })
  :Promise<{user:User}> {
    let url = Url.AUTHENTICATION_USER_PROFILE;
    if (userId) {
      url += `/${userId}`;
    }

    const res = await this.request({
      method: 'GET',
      url,
      data: {
      },
      headers: {},
    });
    return res;
  }

  static async editUserProfile({
    firstName,
    lastName,
    username,
    description,
    imageUrl,
  }:
     {
      firstName?: string;
      lastName?: string;
      username?: string;
      description?: string;
      imageUrl?: string;
    })
  :Promise<{user:User}> {
    const res = await this.request({
      method: 'PUT',
      url: Url.AUTHENTICATION_USER_PROFILE,
      data: {
        firstName,
        lastName,
        username,
        description,
        imageUrl,
      },
      headers: {},
    });
    return res;
  }

  static async getUsers({
    filter,
    size,
    offset,
  }:
     {
      filter?: string;
      size?: number;
      offset?: number;
    })
  :Promise<{users:User[]}> {
    const res = await this.request({
      method: 'GET',
      url: Url.AUTHENTICATION_USERS,
      data: {
        filter,
        size,
        offset,
      },
      headers: {},
    });
    return res;
  }
}

export default AuthApi;
