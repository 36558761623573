import Styled from 'styled-components';
import { MdDelete } from 'react-icons/md';

export const Container = Styled.div`
  display: flex;
  padding: 15px 0;
  border-bottom: 1px solid gray;
  flex-wrap: wrap;
  align-items: center;
`;

export const NameContainer = Styled.div`
  /* flex: 1; */
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 0 15px 0;

  /* @media(min-width: 480px) {
    width: auto;
    flex: 1;
  } */
`;

export const RoleContainer = Styled.div`
  margin: 0 15px;
  display: flex;
  align-items: center;
  flex:1;
`;

export const RemoveContainer = Styled.div`
  text-align: center;
`;

export const RemoveButton = Styled.div<{$disabled: boolean}>`
  border-radius: 50%;
  padding: 5px;
  min-width: inherit;
  display: flex;
  background-color: white;
  pointer-events: ${(props) => (props.$disabled ? 'none' : 'all')};
  opacity: ${(props) => (props.$disabled ? 0.5 : 1)};
  cursor: pointer;
  transition: ${(props) => (props.theme.custom.transition)};

  &:hover {
    transform: scale(1.1);
  }
`;

export const RemoveIcon = Styled(MdDelete)<{$disabled: boolean}>`
  font-size: 1.6rem;
  color: ${(props) => (props.$disabled ? 'gray' : props.theme.custom.redDark)};
  display: flex;

  &:hover {
    color: ${(props) => props.theme.custom.redLight}
  }
`;
