import Styled from 'styled-components';
import { VscSymbolNamespace } from 'react-icons/vsc';
import { BiCode } from 'react-icons/bi';
import { PiUsersFourLight } from 'react-icons/pi';
import { GrConnect } from 'react-icons/gr';

export const Conitainer = Styled.div<{$enable: boolean}>`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 10px 80px 10px;
  pointer-events: ${(props) => (props.$enable ? 'all' : 'none')}; 
  opacity: ${(props) => (props.$enable ? 1 : 0.5)}; 
`;

export const RowContainer = Styled.div<{$margin?: string}>`
  margin: ${(props) => (props.$margin ?? 0)};
`;

export const NameIcon = Styled(VscSymbolNamespace)`
`;

export const CodeIcon = Styled(BiCode)`
`;

export const MembersIcon = Styled(PiUsersFourLight)`
`;

export const MemberSessionIcon = Styled(GrConnect)`
`;
