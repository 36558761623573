import { useEffect } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { EventEmitter } from 'eventemitter3';

const emitter = new EventEmitter();

export const useSub = (event: string, callback: (params: any) => void) => {
  const unsubscribe = () => {
    emitter.off(event, callback);
  };

  useEffect(() => {
    emitter.on(event, callback);
    return unsubscribe;
  }, []);

  return unsubscribe;
};

export const subscribe = (event: string, callback: (params: any) => void) => {
  emitter.on(event, callback);

  return () => {
    emitter.off(event, callback);
  };
};

export const usePub = () => (event: string, data: any) => {
  emitter.emit(event, data);
};

export const PubSubEvent = {
  ROOM_NAME_CHANGE: 'room-name-change',
  ROOM_CODE_CHANGE: 'room-code-change',
  ROOM_ACCESS_TYPE_CHANGE: 'room-access-type-change',
  ROOM_MAX_MEMBER_CHANGE: 'room-max-member-change',
  ROOM_MAX_MEMBER_JOIN_CHANGE: 'room-max-member-join-change',
  ROOM_MEMBERS_ADDED: 'room-members-added',
};
