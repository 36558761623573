import Styled from 'styled-components';

export const Container = Styled.div`
  border-bottom: 1px solid gray;
  margin-top: 15px;
  padding: 15px 15px;
  cursor: pointer;
`;

export const InnerContainer = Styled.div``;
