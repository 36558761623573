import React, { useState } from 'react';
import MemberModal from '../../memberModal';
import {
  AddMemberContainer, AddMemberIcon,
  AddMemberIconContainer, Conitainer, MembersContainer,
} from './components';
import Members from './components/members';
import UserDTO from '../../../../dto/user.dto';
import { useUserInfoContext } from '../../../../contexts/userInfoContext';
import { useMembers } from '../../context/members.context';
import RoomMemberRoleType from '../../../../types/roomMemberRoleType';

function MembersTab({
  roomId,
}: {
  readonly roomId: string,
}) {
  const [memberModal, setMemberModal] = useState(false);
  const { getMemberByUserId } = useMembers();
  const { userInfo } :
  {userInfo?:UserDTO|null} = useUserInfoContext();

  const currentMember = getMemberByUserId(userInfo?.id);
  return (
    <Conitainer>
      <MembersContainer>
        <Members roomId={roomId} />
      </MembersContainer>
      <AddMemberContainer>
        <AddMemberIconContainer
          onClick={() => setMemberModal(true)}
          disabled={currentMember.role !== RoomMemberRoleType.ADMIN
            && currentMember.role !== RoomMemberRoleType.CREATOR}
        >
          <AddMemberIcon />
        </AddMemberIconContainer>
      </AddMemberContainer>
      {
          memberModal && (
            <MemberModal
              onClose={() => { setMemberModal(false); }}
            />
          )
      }
    </Conitainer>
  );
}

export default MembersTab;
