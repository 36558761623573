import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { MembersProvider } from '../../components/conference/context/members.context';
import Conference, { ConferenceDataInterface } from '../../components/conference';
import { LoadingContainer } from './components';
import Loading1 from '../../components/loadings/loading1';
import ConferenceApi from '../../services/gateway/conferenceApi';
import WebsocketHandler from '../../socket/websocket';

function RoomPage() {
  const [info, setInfo] = useState<ConferenceDataInterface|null>(null);
  const { roomId, joinId } = useParams<{
    roomId: string;
    joinId: string;
  }>();
  const location = useLocation();

  useEffect(() => {
    document.title = 'Room';
    (async () => {
      if (!roomId || !joinId) return;
      try {
        let rtcConfig = location.state?.rtcConfiguration;
        if (location.hash !== '' || !rtcConfig) {
          const peerData = WebsocketHandler.getPeerData();
          const { rtcConfiguration } = await ConferenceApi.joinRoom({
            peerId: peerData.peerId,
            roomId,
          });

          rtcConfig = rtcConfiguration;
        }

        setInfo({
          id: roomId,
          joinInfo: {
            id: joinId,
            createdAt: new Date(),
          },
          rtcConfiguration: rtcConfig,
        });
      } catch (e) {
        // TODO
      }
    })();
  }, []);

  return (
    <MembersProvider>
      {
        info === null
          ? (
            <LoadingContainer>
              <Loading1 />
            </LoadingContainer>
          )
          : (
            <Conference
              info={info}
            />
          )
      }
    </MembersProvider>
  );
}

export default RoomPage;
