import React, { useState } from 'react';
import { toast } from 'react-toastify';
import {
  Container, Icon, IconContainer,
} from './components';

function Leave({
  onLeave,
}: {
  readonly onLeave: () => void
}) {
  const [loading, setLoading] = useState(false);

  const leave = async () => {
    setLoading(true);

    try {
      await onLeave();
    } catch (e: any) {
      toast.error(e.message);
      setLoading(false);
    }
  };

  return (
    <Container>
      <IconContainer
        onClick={leave}
        $disabled={loading}
      >
        <Icon />
      </IconContainer>
    </Container>
  );
}

export default Leave;
