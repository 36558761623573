import Styled from 'styled-components';
import { PiUserBold } from 'react-icons/pi';

export const HeaderContainer = Styled.div`
  display: flex;
  height: 75px;
  /* border-bottom: 1px solid gray; */
  align-items: center;
  padding: 0 15px;
  background: ${(props) => props.theme.custom.headerBackground};
  justify-content: space-between;
`;

export const MeetIconContainer = Styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const MeetIcon = Styled.div`
  border-radius: 50%;
  background: white;
  color: black;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4em;
`;

export const UserIconContainer = Styled.div`
  display: flex;  
  cursor: pointer;
`;

export const UserIcon = Styled(PiUserBold)`
  font-size: 1.6rem;
  padding: 9px;
  border-radius: 50%;
  background: white;
  color: black;
`;

export const UserImage = Styled.img`
    width: 45px;
    height: 45px;
    border-radius: 50%;
`;

export const Title = Styled.div`
  font-size: 1.2rem;
  font-weight: 400;
  padding-left: 2px;
`;
