import Styled from 'styled-components';

export const Container = Styled.div`
  width: 100%;
  height: 100%;
  background: black;
  z-index: 1200;
`;

export const LoadingContainer = Styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
