import { parseCookies } from 'nookies';
import Config from '../../commons/config';
import ServiceException from '../../commons/serviceException';

class RequestHandler {
  static token: string | undefined;

  static async request({
    method, url, data, form, headers,
  }:{method:'GET'|'POST'|'DELETE'|'PUT'|'PATCH', url: string, data?: any, form?: any, headers: any})
  :Promise<any> {
    const iHeaders = {
      ...headers,
      'content-type': 'application/json',
    };

    const cookies = parseCookies();

    if (cookies.token) {
      iHeaders.token = cookies.token;
    } else if (RequestHandler.token) {
      iHeaders.token = RequestHandler.token;
    }

    const config:any = {
      method,
      headers: iHeaders,
      // withCredentials: true,
    };

    let baseUrl = Config.GATEWAY_ADDRESS + url;

    if (method === 'GET') {
      const keys = data ? Object.keys(data) : null;
      if (keys && keys.length > 0) {
        const searchParams = new URLSearchParams();

        keys.forEach((key) => {
          if (typeof data[key] === 'undefined') return;
          if (Array.isArray(data[key])) {
            data[key].forEach((val:any) => {
              searchParams.append(key, val);
            });
          } else {
            searchParams.append(key, data[key]);
          }
        });

        baseUrl += `?${searchParams.toString()}`;
      }
    } else if (data) {
      config.headers['content-type'] = 'application/json';
      config.body = JSON.stringify(data);
    } else {
      config.body = form;
    }

    try {
      const res = await fetch(baseUrl, config);
      const resBody = await res.json();
      if (res.status >= 200 && res.status < 300) {
        return resBody;
      }
      throw new ServiceException(res.status, resBody.message);
    } catch (e: any) {
      if (e.response) {
        throw new ServiceException(e.response.status, e.response.data.message);
      } else {
        throw new ServiceException(e.status, e.message);
      }
    }
  }

  static setToken(token: string) {
    this.token = token;
  }

  static removeToken() {
    this.token = undefined;
  }
}

export default RequestHandler;
