import Styled from 'styled-components';
import { PiUserCircleThin } from 'react-icons/pi';

export const Container = Styled.div<{$own:boolean}>`
  display: flex;
  margin: 25px 0;
  flex-direction:${(props) => (props.$own ? 'row' : 'row-reverse')};
`;

export const ContainerInner = Styled.div<{$own:boolean}>`
  display: flex;
  flex-direction:${(props) => (props.$own ? 'row' : 'row-reverse')};
  align-items: center;
  gap: 15px;
`;

export const TextContainer = Styled.div`
  font-size: 1.3rem;
  white-space: break-spaces;
`;

export const TimeContainer = Styled.div`
  font-size: 0.75rem;
  color: #00000073;
`;

export const UserImage = Styled.img`
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
  max-width: 40px;
`;

export const UserIcon = Styled(PiUserCircleThin)`
  font-size: 3rem;
  color: white;
`;

export const ImageContainer = Styled.div`
  /* margin-right: 10px; */
`;

export const InfoContainer = Styled.div<{$own:boolean}>`
  background: ${(props) => props.theme.palette.grey['600']};
  padding: 8px 15px;
  border-radius: 10px;  
  min-width: 100px;
  word-break: break-all;
  position: relative;


  &:after {
    ${(props) => (!props.$own ? 'right: -15px;' : 'left: -15px;')};
    ${(props) => (!props.$own ? 'border-right: 15px solid transparent;' : 'border-left: 15px solid transparent;')};
    position: absolute;
    top: 10px;
    content: "";
    width: 0;
    height: 0;
    border-top: 15px solid ${(props) => props.theme.palette.grey['600']};
  }
`;
