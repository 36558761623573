import React, { useState } from 'react';
import * as c from './components';

function RoomCard({
  name,
  time,
  onClickJoin,
  onClick,
}: {
  readonly name: string;
  readonly time: string;
  readonly onClickJoin: () => void
  readonly onClick: () => void
}) {
  const [joinEnable, setJoinEnable] = useState(true);
  return (
    <c.Container onClick={onClick}>
      <c.ContainerInner>
        <div>
          <c.NameText>{name}</c.NameText>
          <c.TimeText>{time}</c.TimeText>
        </div>
        <c.JoinButton
          $enable={joinEnable}
          onClick={async (e) => {
            e.stopPropagation();
            setJoinEnable(false);
            await onClickJoin();
            setJoinEnable(true);
          }}
        >
          <c.JoinIcon />
        </c.JoinButton>
      </c.ContainerInner>
    </c.Container>
  );
}

export default RoomCard;
