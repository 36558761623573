import Styled from 'styled-components';
import { BiSend } from 'react-icons/bi';
import { FiPhoneCall } from 'react-icons/fi';

export const Container = Styled.div`
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

export const ContainerInner = Styled.div`
width: 100%;
height: 100%;  
background: ${(props) => props.theme.custom.Background};
position: relative;
display: flex;
flex-direction: column;
`;

export const IHeader = Styled.div`
  padding: 20px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const IBody = Styled.div`
  flex: auto;
  overflow-y: auto;
  padding: 0 15px;
`;

export const EmptyContainer = Styled.div`
  flex: auto;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IFooter = Styled.div`
  padding: 20px 10px;
  display: flex;
`;

export const InputElement = Styled.div`
  flex: auto;
  border: 1px solid gray;
  padding : 10px 10px;
  border-radius: 10px;
  outline: none;

  &[contenteditable=true]:empty:not(:focus):before {
    content:attr(placeholder);
    color:grey;
    font-style:italic;
  }
`;

export const SendButton = Styled.div`
  background: white;
  padding: 10px;
  border-radius: 50%;
  color: ${(props) => props.theme.custom.azureLight};
  cursor: pointer;
  margin: 0 5px ;
  transition: ${(props) => props.theme.custom.transition};
  display: flex;

  &:hover {
    background: ${(props) => props.theme.custom.azureLight};
    transform: scale(1.1);
    color: white;
  }
`;

export const SendIcon = Styled(BiSend)`
  font-size: 1.5rem;
`;

export const VoiceCallIcon = Styled(FiPhoneCall)`
  font-size: 2rem;
  margin: 0 20px;
  cursor: pointer;
`;

export const LogoIcon = Styled.div`
  font-size: 1.6rem;
  padding: 15px;
  border-radius: 50%;
  border: 1px solid white;
  display: flex;
  width: 50px;
  height: 50px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NoMessageContainer = Styled.div`
  background: #00000008s;
`;

export const NoMessageInnerContainer = Styled.div`
  padding: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const NoMsgTitleContainer = Styled.div`
  font-weight: bold;
  font-size: 1.5rem;
  text-align: center;
`;

export const NoMsgDescContainer = Styled.div`
  font-size: 0.8rem;
`;

export const NoMsgIconContainer = Styled.div`
  margin-top: 40px;
  display: flex;
`;

export const SendContainer = Styled.div`
  display: flex;
  align-items: end;
  justify-content: center;
`;
