import Url from './url';
import { format } from '../../commons/helper';
import RequestHandler from './requestHandler';

class WebRTCManagerApi {
  private static async request({
    method, url, data, form, headers,
  }:{method:'GET'|'POST'|'DELETE'|'PUT'|'PATCH', url: string, data?: any, form?: any, headers: any})
  :Promise<any> {
    const res = RequestHandler.request({
      method, url, data, form, headers,
    });
    return res;
  }

  static async ping({
    roomId,
    clientId,
  }: {
    roomId: string;
    clientId: string;
  }) :Promise<{ id: string }> {
    const res = await this.request({
      method: 'POST',
      url: `${format(Url.WEBRTC_MANAGER_CLIENT_PING, roomId, clientId)}`,
      data: {},
      headers: {},
    });
    return res;
  }

  static async sync({
    roomId,
    clientId,
  }: {
    roomId: string;
    clientId: string;
  }) :Promise<{ id: string }> {
    const res = await this.request({
      method: 'POST',
      url: `${format(Url.WEBRTC_MANAGER_CLIENT_SYNC, roomId, clientId)}`,
      data: {},
      headers: {},
    });
    return res;
  }
}

export default WebRTCManagerApi;
