import React, { useEffect, useState } from 'react';
import ModalPodOTPLogin from '../../components/modalPodOTPLogin';
import {
  BodyContainer, ContainerStyled, EmailIcon,
  IconContainer,
  InfosContainer,
  LinkdinIcon,
  PhoneIcon, SocialsContainer,
  Start,
  TelegramIcon,
  WhatsappIcon,
} from './components';
import ConfirmDialog from '../../components/confirmDialog';
import Header from '../../components/layout/header';

function ContactusPage() {
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [confirmData, setConfirmData] = useState<{
    title: string;
    description: string;
    onAccept?:() => void;
    onReject?:() => void;
    marginTop: number;
      }|null>(null);

  const click = async (link: string, text: string) => {
    navigator.clipboard?.writeText(text);
    // toast.success('copied to clipboard', {
    //   autoClose: 2000,
    // });

    setConfirmData({
      title: 'open link',
      description: 'do you want opent the link?',
      onAccept: () => {
        window.open(link);
      },
      marginTop: 75,
    });
  };

  useEffect(() => {
    document.title = 'Contact Us';
  }, []);

  return (
    <ContainerStyled>
      <Header />
      <BodyContainer>
        <Start
          $active={confirmData === null}
        >
          <InfosContainer>
            <SocialsContainer>
              <IconContainer
                style={{
                  position: 'absolute',
                  left: 0,
                }}
                href="https://www.linkedin.com/in/ali-khanbabaei"
                onClick={(e) => {
                  click('https://www.linkedin.com/in/ali-khanbabaei', 'https://www.linkedin.com/in/ali-khanbabaei');
                  e.preventDefault();
                }}
              >
                <LinkdinIcon />
              </IconContainer>
              <IconContainer
                href="mailto:khanbabaeiOfficial@gmail.com"
                onClick={(e) => {
                  click('mailto:khanbabaeiOfficial@gmail.com', 'khanbabaeiOfficial@gmail.com');
                  e.preventDefault();
                }}
              >
                <EmailIcon />
              </IconContainer>
              <IconContainer
                style={{
                  position: 'absolute',
                  right: 0,
                }}
                href="tel:+989127358933"
                onClick={(e) => {
                  click('tel:+989127358933', '+989127358933');
                  e.preventDefault();
                }}
              >
                <PhoneIcon />
              </IconContainer>
              <IconContainer
                style={{
                  position: 'absolute',
                  bottom: 0,
                }}
                href="https://wa.me/+989127358933"
                onClick={(e) => {
                  click('https://wa.me/+989127358933', 'https://wa.me/+989127358933');
                  e.preventDefault();
                }}
              >
                <WhatsappIcon />
              </IconContainer>
              <IconContainer
                style={{
                  position: 'absolute',
                  top: 0,
                }}
                href="https://t.me/+989127358933"
                onClick={(e) => {
                  click('https://t.me/+989127358933', 'https://t.me/+989127358933');
                  e.preventDefault();
                }}
              >
                <TelegramIcon />
              </IconContainer>
            </SocialsContainer>
          </InfosContainer>
        </Start>
      </BodyContainer>
      {loginModalOpen && <ModalPodOTPLogin onClose={() => { setLoginModalOpen(false); }} />}
      {confirmData && (
      <ConfirmDialog
        title={confirmData.title}
        description={confirmData.description}
        onClose={() => {
          setConfirmData(null);
        }}
        onAccept={() => {
          if (confirmData.onAccept) {
            confirmData.onAccept();
          }
          setConfirmData(null);
        }}
        onReject={() => {
          if (confirmData.onReject) {
            confirmData.onReject();
          }
          setConfirmData(null);
        }}
        marginTop={confirmData.marginTop}
      />
      )}
    </ContainerStyled>
  );
}

export default ContactusPage;
