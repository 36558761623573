import React, { useEffect, useState } from 'react';
import { Modal } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  AcceptContainer, AcceptIcon,
  ButtonsContainer,
  Container,
  ContainerInner,
  DescriptionText, RejectContainer, RejectIcon, TitleText,
} from './components';

function ConfirmDialog({
  onClose,
  onAccept,
  onReject,
  title,
  description,
  marginTop,
}: {
  readonly onClose:any;
  readonly onAccept: any;
  readonly onReject: any;
  readonly title: string;
  readonly description: string;
  readonly marginTop?: number;
}) {
  const dialogHashName = '#confirm-dialog';
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const accept = () => {
    navigate(-1);
    onAccept();
  };

  const reject = () => {
    navigate(-1);
    onReject();
  };

  const close = () => {
    navigate(-1);
    onClose();
  };

  const handleOnHashChange = () => {
    const isHashMatch = window.location.hash === dialogHashName;
    if (!isHashMatch && isOpen) {
      onClose();
    }
  };

  useEffect(() => {
    setIsOpen(true);
    navigate(dialogHashName);

    return () => {
      setIsOpen(false);
    };
  }, []);

  useEffect(() => {
    window.addEventListener('hashchange', handleOnHashChange);

    return () => window.removeEventListener('hashchange', handleOnHashChange);
  }, [handleOnHashChange]);

  return (
    <Modal
      open
      onClose={close}
      // sx={{
      //   '& .MuiModal-backdrop': {
      //     backgroundColor: 'transparent',
      //   },
      // }}
    >
      <Container>
        <ContainerInner $marginTop={marginTop || 0}>
          <TitleText>{title}</TitleText>
          <DescriptionText>{description}</DescriptionText>
          <ButtonsContainer>
            <RejectContainer onClick={reject}>
              <RejectIcon />
            </RejectContainer>
            <AcceptContainer onClick={accept}>
              <AcceptIcon />
            </AcceptContainer>
          </ButtonsContainer>
        </ContainerInner>
      </Container>

    </Modal>
  );
}

ConfirmDialog.defaultProps = {
  marginTop: 0,
};

export default ConfirmDialog;
