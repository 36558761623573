import React, {
  createRef, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
// import OtpImage from '../../../assets/images/otp.png';
import { toast } from 'react-toastify';
import { generateUUID, getBrowserData } from '../../../commons/helper';
import PodImage from '../../../assets/images/pod-logo.png';
import Config from '../../../commons/config';
import AuthApi from '../../../services/gateway/authApi';
import PhonenumberInput from '../components/phonenumberInput';
import {
  ButtonContainer,
  CloseIcon,
  DescriptionText,
  Divider,
  GoogleLogoContainer,
  GoogleLogoStyled,
  HeaderContainer,
  PhoneInputContainer,
  PodLogoContainer,
  PodLogoStyled,
  SendButton,
  SsoLoginContainer,
  TitleText,
  Video,
} from './components';

function FirstStep(
  {
    onComplete,
    onClose,
  }:
  {
    readonly onComplete: any;
    readonly onClose: any;
  },
) {
  const { t } = useTranslation('components');
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState('');
  const phoneInputRef = createRef<HTMLInputElement>();
  const location = useLocation();

  useEffect(() => {
    if (!phoneInputRef.current) return;
    phoneInputRef.current.focus();
  }, []);

  const loginWithPhone = async () => {
    setLoading(true);

    try {
      const browserData = getBrowserData();
      const res = await AuthApi.podOTPLogin({
        appVersion: '1.0.0',
        cellphoneNumber: phone,
        deviceId: generateUUID(),
        deviceName: 'browser',
        deviceType: 'Desktop',
        os: browserData.osName,
        osVersion: browserData.boewserVersion,
        clientCode: Config.POD_CLIENT_CODE,
      });

      const data = {
        ...res,
        cellphoneNumber: phone,
      };
      onComplete(data);
    } catch (e: any) {
      toast.error(e.message);
    }
    setLoading(false);
  };

  const onClickPodLogo = () => {
    if (location.pathname !== '/') {
      localStorage.setItem('redirectpath', location.pathname);
    }
    window.location.href = Config.POD_LOGIN_URL;
  };

  const onClickGoolgeLogo = () => {
    window.location.href = Config.GOOGLE_LOGIN_URL;
  };

  return (
    <>
      <HeaderContainer>
        <CloseIcon onClick={onClose} />
      </HeaderContainer>
      {/* <Image src={OtpImage} /> */}
      <Video autoPlay muted playsInline>
        <source src="assets/videos/phonenumber.mp4" type="video/mp4" />
      </Video>
      <TitleText>{t('modalPodOTPLogin.title')}</TitleText>
      <DescriptionText>{t('modalPodOTPLogin.description')}</DescriptionText>
      <PhoneInputContainer>
        <PhonenumberInput
          onChange={(num) => {
            setPhone(num);
          }}
        />
      </PhoneInputContainer>
      <ButtonContainer>
        <SendButton
          variant="contained"
          size="large"
          onClick={loginWithPhone}
          loading={loading}
          loadingPosition="end"
          style={{ textTransform: 'none' }}
          disabled={phone.length !== 10}
          $active={phone.length === 10}
          endIcon={<div />}
        >
          {t('modalPodOTPLogin.sendCode')}
        </SendButton>
      </ButtonContainer>
      <Divider />
      <SsoLoginContainer>
        <PodLogoContainer>
          <PodLogoStyled src={PodImage} onClick={onClickPodLogo} />
        </PodLogoContainer>
        <GoogleLogoContainer>
          <GoogleLogoStyled onClick={onClickGoolgeLogo} />
        </GoogleLogoContainer>
      </SsoLoginContainer>
    </>
  );
}

export default FirstStep;
