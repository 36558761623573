import React, {
  createRef, KeyboardEvent, useState, useEffect, useRef, UIEvent,
} from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Thread from '../../dto/thread.dto';
import ThreadMessage from '../../dto/threadMessage.dto';
import ThreadType from '../../types/threadType';
import Loading1 from '../loadings/loading1';
import * as c from './components';
import { useUserInfoContext } from '../../contexts/userInfoContext';
import MessageView from './message';
import WebsocketHandler from '../../socket/websocket';
import ChatApi from '../../services/gateway/chatApi';
import ChatEventType from '../../types/chatEventType';
import ConferenceEventType from '../../types/conferenceEventType';
import ConferenceApi from '../../services/gateway/conferenceApi';

function Chat({
  userId,
  threadId,
  header = true,
  onNewMessage,
}:{
  readonly userId?: string;
  readonly threadId?: string;
  readonly header: boolean;
  readonly onNewMessage?: Function;
}) {
  const loadSize = 20;
  const { userInfo } = useUserInfoContext();
  const [messages, setMessages] = useState<ThreadMessage[]>([]);
  const [thread, setThread] = useState<Thread>();
  const inputRef = createRef<HTMLDivElement>();
  const messageContainer = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(true);
  const [hasNext, setHasNext] = useState(true);
  const [currentOffset, setCurrentOffset] = useState(0);
  const [conferenceData, setConferenceData] = useState<{
    id: string,
    joinInfo: {
      id: string,
      createdAt: Date,
    },
    rtcConfiguration: any
  }>();
  const scrollingState = useRef(true);
  const navigate = useNavigate();

  const scrollTo = ({ top, behavior }: any) => {
    messageContainer.current?.scrollTo({
      top,
      behavior,
    });
  };

  const scrollToBottom = () => {
    // console.log('scrollToBottom', messageContainer.current?.scrollHeight);
    scrollTo({
      top: messageContainer.current?.scrollHeight,
      behavior: 'smooth',
    });
  };

  const addThread = async () => {
    if (userId) {
      const { thread: threadData } = await ChatApi.addThread({
        type: ThreadType.PRIVATE,
        participantUserId: userId,
      });
      setThread(threadData);
    } else if (threadId) {
      const { thread: threadData } = await ChatApi.getThread({
        threadId,
      });
      setThread(threadData);
    }
  };

  const updateMessages = async ({ offset }: any) => {
    if (!thread) return;
    setLoading(true);
    try {
      const { messages: messageList } = await ChatApi.getThreadMessages({
        size: 20,
        offset,
        threadId: thread.id,
      });
      setCurrentOffset(offset + messageList.length);
      messageList.reverse();
      scrollingState.current = offset === 0;
      if (offset === 0) {
        setMessages(messageList);
      } else {
        setMessages([...messageList, ...messages]);
      }
      if (messageList.length < loadSize) {
        setHasNext(false);
      }
    } catch (e:any) {
      // TODO
    }
    setLoading(false);
  };

  const onMessage = ({ message }: any) => {
    const { serverAppId, content } = message;
    if (serverAppId === 'CHAT') {
      switch (content.type) {
        case ChatEventType.NEW_MESSAGE: { // new message
          const msg = content.content.message;
          if (thread && msg.threadId === thread.id) {
            if (msg.user.id !== userInfo?.id) {
              scrollingState.current = true;
              setMessages([...messages, msg]);
              if (onNewMessage) {
                onNewMessage();
              }
            }
          }
          break;
        }
        default:
            //
      }
    } else if (serverAppId === 'CONFERENCE') {
      switch (content.type) {
        case ConferenceEventType.JOINED:
          if (!userInfo) return;

          if (conferenceData && conferenceData.id === content.content.roomId) {
            navigate(`/rooms/${conferenceData.id}/joins/${conferenceData.joinInfo.id}`, {
              state: { rtcConfiguration: conferenceData.rtcConfiguration },
            });
          }
          break;
        default:
            //
      }
    }
  };

  const onScroll = async (e: UIEvent<HTMLDivElement>) => {
    if (e.currentTarget.scrollTop === 0 && !loading && hasNext) {
      const prevHeight = messageContainer.current?.scrollHeight || 0;
      await updateMessages({ offset: currentOffset });
      const nextHeight = messageContainer.current?.scrollHeight || 0;
      scrollTo({
        top: nextHeight - prevHeight,
      });
    }
  };

  const sendMessage = async ({ text }: {text: string}): Promise<ThreadMessage|null> => {
    const txt = text.trim();
    if (!thread || !userInfo || txt.length === 0 || !inputRef.current) return null;
    try {
      inputRef.current.innerHTML = '';

      const { message } = await ChatApi.addThreadMessage({
        threadId: thread?.id,
        text: txt,
      });

      message.user = userInfo;

      if (message) {
        scrollingState.current = true;
        setMessages([...messages, message]);
      }
      return message;
    } catch (e: any) {
      toast.error(e.message);
    }
    return null;
  };

  const onKeyUp = async (e:KeyboardEvent<HTMLInputElement>) => {
    const text = inputRef.current?.innerText;
    if ((e.key === 'Enter' || e.code === 'NumpadEnter') && !e.shiftKey && text) {
      const message = {
        text,
      };
      await sendMessage(message);
    }
  };

  const createConference = async () => {
    if (!thread) return;
    try {
      const peerData = WebsocketHandler.getPeerData();

      if (!peerData) {
        throw new Error('socket is not connect');
      }

      const { id } = await ConferenceApi.addRoom({
        threadId: thread.id,
      });

      const { id: joinId, createdAt, rtcConfiguration } = await ConferenceApi.joinRoom({
        peerId: peerData.peerId,
        roomId: id,
      });

      setConferenceData({
        id,
        joinInfo: {
          id: joinId,
          createdAt: new Date(createdAt),
        },
        rtcConfiguration,
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        await addThread();
      } catch (e: any) {
        toast.error(e.message);
      }
    })();
  }, [threadId]);

  useEffect(() => {
    WebsocketHandler.addEventListener('message', onMessage);

    return () => {
      WebsocketHandler.removeEventListener('message', onMessage);
    };
  }, [onMessage]);

  useEffect(() => {
    if (messages.length < 5 || !scrollingState.current) return;
    scrollToBottom();
  }, [messages.length]);

  useEffect(() => {
    if (!thread) return;
    (async () => {
      setMessages([]);
      await updateMessages({ offset: 0 });
    })();
  }, [thread?.id]);

  return (
    <c.ContainerInner>
      {header && (
        <c.IHeader>
          header
          <c.VoiceCallIcon onClick={createConference} />
        </c.IHeader>
      )}
      {thread && messages.length > 0 ? (
        <c.IBody ref={messageContainer} onScroll={onScroll} className="scroll">
          {
              messages.map((message) => (
                <MessageView key={message.id} message={message} />
              ))
            }
        </c.IBody>
      ) : (
        <c.EmptyContainer>
          {
              loading ? (
                <Loading1 />
              ) : (
                <c.NoMessageContainer>
                  <c.NoMessageInnerContainer>
                    <c.NoMsgTitleContainer>no message here yet...</c.NoMsgTitleContainer>
                    <c.NoMsgDescContainer>send message</c.NoMsgDescContainer>
                    <c.NoMsgIconContainer>
                      <c.LogoIcon>M</c.LogoIcon>
                    </c.NoMsgIconContainer>
                  </c.NoMessageInnerContainer>
                </c.NoMessageContainer>
              )
          }
        </c.EmptyContainer>
      )}
      <c.IFooter>
        <c.InputElement
          contentEditable
          onKeyUp={onKeyUp}
          ref={inputRef}
          placeholder="Enter your message"
        />
        <c.SendContainer>
          <c.SendButton onClick={async () => {
            const text = inputRef.current?.textContent;
            if (text) {
              const message = {
                text,
              };
              await sendMessage(message);
            }
          }}
          >
            <c.SendIcon />
          </c.SendButton>
        </c.SendContainer>
      </c.IFooter>
    </c.ContainerInner>
  );
}

Chat.defaultProps = {
  userId: null,
  threadId: null,
  onNewMessage: null,
};

export default Chat;
