import React from 'react';
import ConferenceRoomMemberDTO from '../../../../dto/conferenceRoomMember.dto';
import { Container, LetterContainer } from './components';

function Member({
  member,
}: {
  readonly member: ConferenceRoomMemberDTO
}) {
  const name = member.user.username ?? member.user.nickname;
  return (
    <Container key={member.id}>
      <LetterContainer>
        {name?.[0]}
      </LetterContainer>
    </Container>
  );
}

export default Member;
