import Styled from 'styled-components';
import { BiUserCircle } from 'react-icons/bi';
import { SlDislike, SlLike } from 'react-icons/sl';

export const Container = Styled.div`
  display: flex;
  align-items: center;
  direction: ltr;
  gap: 10px;
`;

export const ImageContainer = Styled.div`

`;

export const NameContainer = Styled.div`
  margin: 0 15px;
  white-space: nowrap;
`;

export const UserImage = Styled.img`
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
  max-width: 80px;
`;

export const UserIcon = Styled(BiUserCircle)`
  font-size: 7rem;
  color: black;
`;

export const AcceptContainer = Styled.div`
  background: white;
  color: ${(props) => props.theme.custom.greenLight};
  transition: ${(props) => props.theme.custom.transition};
  padding: 10px;
  margin: 5px;
  border-radius: 50%;
  display: flex;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
    background: ${(props) => props.theme.custom.greenLight};
    color: white;
  }
`;

export const AcceptIcon = Styled(SlLike)`
  font-size: 1.2rem;
`;

export const RejectContainer = Styled.div`
  background: white;
  color: ${(props) => props.theme.custom.redLight};
  transition: ${(props) => props.theme.custom.transition};
  padding: 10px;
  margin: 5px;
  border-radius: 50%;
  display: flex;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
    background: ${(props) => props.theme.custom.redLight};
    color: white;
  }
`;

export const RejectIcon = Styled(SlDislike)`
  font-size: 1.2rem;
`;

export const ButtonsContainer = Styled.div`
    display: flex;
    justify-content: center;
    gap: 10px;
`;
