import React from 'react';
import { Popover } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  ContactusIcon,
  Container, ContainerInner, Divider, HomeIcon, InfoContainer,
  ItemIconContainer,
  ItemsContainer, LogoutIcon, NameContainer, ProfileImageContainer, UserIcon, UserImage, UsersIcon,
} from './components';
import { useUserInfoContext } from '../../../../../contexts/userInfoContext';
import UserInterface from '../../../../../dto/user.dto';
import Routes from '../../../../../commons/routs';

function ProfileDialog({
  onClose,
  anchorEl,
}:{
  readonly onClose: () => any;
  readonly anchorEl: HTMLElement;
}) {
  const { userInfo } :
  {userInfo?:UserInterface|null} = useUserInfoContext();
  const navigate = useNavigate();
  const location = useLocation();

  const showConferencesPage = () => {
    if (location.pathname !== Routes.ROOMS.path) {
      navigate(Routes.ROOMS.path);
    }
  };

  const showHomePage = () => {
    if (location.pathname !== Routes.HOME.path) {
      navigate(Routes.HOME.path);
    }
  };

  const showLogout = () => {
    if (location.pathname !== Routes.LOGOUT.path) {
      navigate(Routes.LOGOUT.path);
    }
  };

  const showContactus = () => {
    if (location.pathname !== Routes.Contactus.path) {
      navigate(Routes.Contactus.path);
    }
  };

  return (
    <Popover
      open
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Container>
        <ContainerInner>
          <InfoContainer>
            <ProfileImageContainer>
              {userInfo?.imageUrl ? <UserImage src={userInfo.imageUrl} /> : <UserIcon />}
            </ProfileImageContainer>
            <NameContainer>
              {
                userInfo?.username
                ?? userInfo?.nickname
                ?? userInfo?.firstName
              }
            </NameContainer>
          </InfoContainer>
          <Divider />
          <ItemsContainer>
            {location.pathname !== Routes.HOME.path
              && <ItemIconContainer onClick={showHomePage}><HomeIcon /></ItemIconContainer>}
            {location.pathname !== Routes.ROOMS.path
              && <ItemIconContainer onClick={showConferencesPage}><UsersIcon /></ItemIconContainer>}
            {location.pathname !== Routes.Contactus.path
              && <ItemIconContainer onClick={showContactus}><ContactusIcon /></ItemIconContainer>}
            <ItemIconContainer onClick={showLogout}><LogoutIcon /></ItemIconContainer>
          </ItemsContainer>
        </ContainerInner>
      </Container>
    </Popover>
  );
}

export default ProfileDialog;
