import Styled from 'styled-components';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

export const ContainerStyled = Styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export const LoadingIconStyled = Styled(AiOutlineLoading3Quarters)`
  color: black;
  font-size: 3em;
  margin-top: 200px;
`;
