const Config = {
  POD_LOGIN_URL: process.env.REACT_APP_POD_LOGIN_URL ?? '',
  GOOGLE_LOGIN_URL: process.env.REACT_APP_GOOGLE_LOGIN_URL ?? '',
  GOOGLE_CLIENT_CODE: process.env.REACT_APP_GOOGLE_CLIENT_CODE ?? '',
  GOOGLE_ANALYTICS_TRACKING_ID: process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID ?? '',
  POD_CLIENT_CODE: process.env.REACT_APP_POD_CLIENT_CODE ?? '',
  POD_LOGIN_REDIRECT_URI: process.env.REACT_APP_POD_LOGIN_REDIRECT_URI ?? '',
  GOOGLE_LOGIN_REDIRECT_URI: process.env.REACT_APP_GOOGLE_LOGIN_REDIRECT_URI ?? '',
  GATEWAY_ADDRESS: process.env.REACT_APP_GATEWAY_ADDRESS ?? '',
  WEBSOCKET_ADDRESS: process.env.REACT_APP_WEBSOCKET_ADDRESS,
  WEBSITE_ADDRESS: process.env.REACT_APP_WEBSITE_ADDRESS ?? '',
  TOKEN_MAX_AGE_SEC: Number(process.env.REACT_APP_TOKEN_MAX_AGE_SEC) ?? 31622400,
};

export default Config;
