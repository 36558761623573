import React from 'react';
import * as c from './components';

function RequestNotif({
  user,
  onAccept,
  onReject,
}:{
  readonly user: any,
  readonly onAccept: any,
  readonly onReject: any
}) {
  return (
    <c.Container>
      <c.ImageContainer>
        {user.imageUrl ? <c.UserImage src={user.imageUrl} /> : <c.UserIcon />}
      </c.ImageContainer>
      <c.NameContainer>{user.nickname}</c.NameContainer>
      <c.ButtonsContainer>
        <c.RejectContainer onClick={onReject}>
          <c.RejectIcon />
        </c.RejectContainer>
        <c.AcceptContainer onClick={onAccept}>
          <c.AcceptIcon />
        </c.AcceptContainer>
      </c.ButtonsContainer>
    </c.Container>
  );
}

export default RequestNotif;
