import Styled from 'styled-components';
import { FaRegUserCircle } from 'react-icons/fa';
import { SlDislike, SlLike } from 'react-icons/sl';

export const Conianer = Styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContainerInner = Styled.div`
  border-radius: 50%;
  background: #161616;
  display: flex;
  flex-direction: column;
  width: 200px;
  height: 200px;
  align-items: center;
  padding: 15px;

`;

export const Button = Styled.div`
  margin: 0 10px;
  padding: 5px 10px;
  background-color: #5b9950;
  border-radius: 10px;
  color: white;
  cursor: pointer;
  transition: ${(props) => props.theme.custom.transition};

  :hover {
    transform: scale(1.1);
  }
`;

export const ButtonContainer = Styled.div`
  display: flex;
  padding: 15px 10px;
  justify-content: center;
`;

export const UserImage = Styled.img`
  max-width: 70px;
  border-radius: 50%;
`;

export const UserIcon = Styled(FaRegUserCircle)`
  
`;

export const AcceptContainer = Styled.div`
  background: white;
  /* border: 1px solid #098809; */
  color: ${(props) => props.theme.custom.greenLight};
  transition: ${(props) => props.theme.custom.transition};
  padding: 10px;
  margin: 5px;
  border-radius: 50%;
  display: flex;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
    background: ${(props) => props.theme.custom.greenLight};
    color: white;
  }
`;

export const AcceptIcon = Styled(SlLike)`
  font-size: 1.2rem;
`;

export const RejectContainer = Styled.div`
  background: white;
  /* border: 1px solid #ad0000; */
  color: ${(props) => props.theme.custom.redLight};
  transition: ${(props) => props.theme.custom.transition};
  padding: 10px;
  margin: 5px;
  border-radius: 50%;
  display: flex;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
    background: ${(props) => props.theme.custom.redLight};
    color: white;
  }
`;

export const RejectIcon = Styled(SlDislike)`
  font-size: 1.2rem;
`;

export const ButtonsContainer = Styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
`;

export const UsernameContainer = Styled.div`
  margin: 20px 0;
  color: white;
`;

export const ImageContainer = Styled.div``;
