enum ConferenceEventType {
  INVITE = 1,
  JOINED = 2,
  LEAVED = 3,
  WEBRTC_NEGOTIATE = 4,
  WEBRTC_ICE_CANDIDATE = 5,
  REQUEST = 6,
  REQUEST_STATUS = 7,
  REMOVED = 8,
  JOIN_REMOVED = 9,
  JOIN_CONNECTED = 10,
  JOIN_DISCONNECTED = 11,
}

export default ConferenceEventType;
