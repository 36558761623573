import Styled from 'styled-components';
import { SiOpenaccess } from 'react-icons/si';

export const Container = Styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  padding: 15px 0;
`;

export const TitleText = Styled.div`
  
`;

export const ContainerInfo = Styled.div`
  flex:1;
  display: flex;
  align-items: center;
`;

export const Icon = Styled(SiOpenaccess)`
  padding-right: 15px;
`;

export const SelectContainer = Styled.div`
  flex: 1;
  text-align: right;  
`;
