import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  BodyContainer, ContactusIcon, ContainerStyled, HomeIcon, ILink,
  ItemContainer, ItemDivider, ItemIconContainer,
  ItemTextContainer, ItemsContainer, LogoutIcon, UsersIcon,
} from './components';
import Header from '../../../components/layout/header';
import Routes from '../../../commons/routs';

function ProfilePageMobile() {
  const navigate = useNavigate();

  const showConferencesPage = () => {
    navigate(Routes.ROOMS.path);
  };

  const showContactusPage = () => {
    navigate(Routes.Contactus.path);
  };

  const showHomePage = () => {
    navigate(Routes.HOME.path);
  };

  return (
    <ContainerStyled>
      <Header />
      <BodyContainer>
        <ItemsContainer>
          <ItemContainer onClick={showHomePage}>
            <ItemIconContainer><HomeIcon /></ItemIconContainer>
            <ItemTextContainer>home</ItemTextContainer>
          </ItemContainer>
          <ItemDivider />
          <ItemContainer onClick={showConferencesPage}>
            <ItemIconContainer><UsersIcon /></ItemIconContainer>
            <ItemTextContainer>rooms</ItemTextContainer>
          </ItemContainer>
          <ItemDivider />
          <ItemContainer onClick={showContactusPage}>
            <ItemIconContainer><ContactusIcon /></ItemIconContainer>
            <ItemTextContainer>contact us</ItemTextContainer>
          </ItemContainer>
          <ItemDivider />
          <ILink to="/logout">
            <ItemContainer>
              <ItemIconContainer><LogoutIcon /></ItemIconContainer>
              <ItemTextContainer>logout</ItemTextContainer>
            </ItemContainer>
          </ILink>
        </ItemsContainer>
      </BodyContainer>
    </ContainerStyled>
  );
}

export default ProfilePageMobile;
