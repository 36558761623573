import Styled from 'styled-components';
import { BiMicrophone, BiMicrophoneOff } from 'react-icons/bi';
import { BsCameraVideo, BsCameraVideoOff } from 'react-icons/bs';
import { IoHome } from 'react-icons/io5';
import { GrConnect } from 'react-icons/gr';

export const ContainerStyled = Styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const InnerContainerStyled = Styled.div`
  display: flex;
  flex: 1;
  flex-direction: column-reverse;
  justify-content: space-around;


  @media (min-width: 768px) {
    flex: 1;
    flex-direction: row-reverse;
    justify-content: center;
  }
`;

export const MediaContainer = Styled.div`
  display: flex;
  position: relative;
  /* flex: 0.9; */
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  align-self: center;
  width: 85vw;
  height: 85vw;
  /* min-height: 360px; */

  @media (min-width: 768px) {
    flex: 1;
    height: 500px;
    width: 500px;
    max-width: 500px;
  }
`;

export const Video = Styled.video`
  position: absolute;
  right: 0;
  object-fit: cover;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  border-radius: 10px;
  overflow: hidden;
  left: 0;
  top: 0;
  background-size: cover;
  background-color: #131415;
  transform: scaleX(-1);
`;

export const InfoContainer = Styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 25px 15px 15px 15px;
`;

export const Text1Container = Styled.div`
  font-weight: bold;
`;

export const Text2Container = Styled.div`
  font-size: small;
  margin-top: 5px;
  text-align: center;
`;

export const ButtonsContainer = Styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 10px;
`;

export const JoinButtonContainer = Styled.div<{$enable: boolean}>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 50%;
  background: white;
  color: ${(props) => props.theme.custom.greenLight};
  cursor: pointer;
  font-size: 1.5rem;
  transition: ${(props) => props.theme.custom.transition};
  pointer-events: ${(props) => (props.$enable ? 'all' : 'none')}; 
  opacity: ${(props) => (props.$enable ? 1 : 0.5)}; 

  &:hover {
    background: ${(props) => props.theme.custom.greenLight};
    transform: scale(1.1);
    color: white;
  }
`;

export const JoinButton = Styled(GrConnect)``;

export const ReturnButtonContainer = Styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 50%;
  background: white;
  color: ${(props) => props.theme.custom.blueLight};
  cursor: pointer;
  font-size: 1.5rem;
  transition: ${(props) => props.theme.custom.transition};


  &:hover {
    background: ${(props) => props.theme.custom.blueLight};
    transform: scale(1.1);
    color: white;
  }
`;

export const ReturnButton = Styled(IoHome)``;

export const IconsContainer = Styled.div`
  display: flex;
  /* margin: auto; */
  z-index: 2;
  align-items: end;
  justify-content: center;
  position: absolute;
  bottom: 20px;
`;

export const IconContainer = Styled.div<{$enable: boolean}>`
  border-radius: 50%;
  margin: 0 10px;
  background:  white;
  display: flex;
  padding: 12px;
  color: black;
  cursor: pointer;
  transition: ${(props) => props.theme.custom.transition};
  font-size: 2rem;

  &:hover {
    transform: scale(1.1);
    /* background: ${(props) => (props.$enable ? props.theme.custom.grayLight : props.theme.custom.redLight)}; */
  }
`;

export const MicEnableIcon = Styled(BiMicrophone)`
`;

export const MicDisableIcon = Styled(BiMicrophoneOff)`
`;

export const CamEnableIcon = Styled(BsCameraVideo)`
`;

export const CamDisableIcon = Styled(BsCameraVideoOff)`
`;

export const CameraOfText = Styled.div`
  color: white;
  z-index: 1;
  display: flex;
  align-items: center;
  font-size: 1.5rem;
`;

export const MembersContainer = Styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  gap: 5px;
`;

export const LoadinContainer = Styled.div`
  display: flex;
`;

export const NoMemberContainer = Styled.div`
  height: 37px;
`;
