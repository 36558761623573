import Styled from 'styled-components';

export const Container = Styled.div<{ selected: boolean}>`
  display: flex;
  flex: 1;
  border-bottom: 1px solid gray;
  cursor: pointer;
  background: ${(props) => (props.selected ? '#bcbcbc' : 'inherit')};
`;

export const ContainerInner = Styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  width: 100%;
  align-items: center;
`;

export const NameText = Styled.div<{ selected: boolean}>`
  color: ${(props) => (props.selected ? 'black' : 'inherit')};
`;

export const TimeText = Styled.div<{ selected: boolean}>`
  font-size: 0.8rem;
  color: ${(props) => (props.selected ? 'black' : 'gray')};
`;
