import React, { useEffect, useState } from 'react';
import { toast, Zoom } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import MemberModal from '../memberModal';
import * as c from './components';
import Config from '../../../commons/config';

function Info(
  {
    onClose,
    code,
    id,
  }:
  {
    readonly onClose: any;
    readonly code?: string;
    readonly id?: string
  },
) {
  let link: string = Config.WEBSITE_ADDRESS;
  if (code) {
    link += `/code/${code}`;
  } else {
    link += `/id/${id}`;
  }

  const [memberModal, setMemberModal] = useState(false);
  const dialogHashName = '#info-dialog';
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const copyLinkToClipboard = () => {
    navigator.clipboard?.writeText(link);
    toast.success('link copied to clipboard', {
      transition: Zoom,
      autoClose: 2000,
    });
  };

  const close = () => {
    navigate(-1);
    onClose();
  };

  const handleOnHashChange = () => {
    const isHashMatch = window.location.hash === dialogHashName;
    if (!isHashMatch && isOpen) {
      onClose();
    }
  };

  useEffect(() => {
    navigate(dialogHashName);
    setIsOpen(true);

    return () => {
      setIsOpen(false);
    };
  }, []);

  useEffect(() => {
    window.addEventListener('hashchange', handleOnHashChange);

    return () => window.removeEventListener('hashchange', handleOnHashChange);
  }, [handleOnHashChange]);

  return (
    <c.Container>
      <c.ContainerInner>
        <c.IHeader>
          <div>Your meeting&apos;s ready</div>
          <c.CloseIcon onClick={close} />
        </c.IHeader>
        <c.IBody>
          <c.AddMemberIconContainer>
            <c.AddMemberButton
              onClick={() => { setMemberModal(true); }}
            >
              <c.InviteIcon />
            </c.AddMemberButton>
          </c.AddMemberIconContainer>
          <c.infoText>
            share this meeting link with others you want in the meeting
          </c.infoText>
          <c.LinkContainer>
            <c.LinkText className="scroll">{link}</c.LinkText>
            <c.CopyIcon onClick={copyLinkToClipboard} />
          </c.LinkContainer>
          <c.infoText>
            People who use this meeting link must get your permission before they can join.
          </c.infoText>
        </c.IBody>
      </c.ContainerInner>
      {
          memberModal && (
          <MemberModal
            onClose={() => { setMemberModal(false); }}
          />
          )
      }
    </c.Container>
  );
}

Info.defaultProps = {
  code: null,
  id: null,
};

export default Info;
