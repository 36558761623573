import React, {
  ChangeEvent, createRef, KeyboardEvent, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
// import OtpImage from '../../../assets/images/otp.png';
import { convertSecToMinSec, generateUUID, getBrowserData } from '../../../commons/helper';
import useTimer from '../../../commons/hook/useTimer';
import * as c from './components';
import AuthApi from '../../../services/gateway/authApi';
import Config from '../../../commons/config';

function SecondStep({
  keyId = '',
  expiresIn = 120,
  cellphoneNumber = '',
  onPrevStep,
  onLogin,
  onClose,
}: {
  readonly keyId: string;
  readonly expiresIn: number;
  readonly cellphoneNumber: string;
  readonly onPrevStep: any;
  readonly onLogin: any;
  readonly onClose: any;
}) {
  const { t } = useTranslation('components');
  const [expIn, setExpIn] = useState(expiresIn);
  const [kId, setKId] = useState(keyId);
  const [timerId, setTimerId] = useState(generateUUID());
  const remainSec = useTimer({ seconds: expIn, id: timerId });
  const [loading, setLoading] = useState(false);
  const [resendEnable, setResendEnable] = useState(false);

  const inputsRef = [
    createRef<HTMLInputElement>(),
    createRef<HTMLInputElement>(),
    createRef<HTMLInputElement>(),
    createRef<HTMLInputElement>(),
    createRef<HTMLInputElement>(),
    createRef<HTMLInputElement>(),
  ];

  const getCode = () => {
    let code = '';
    inputsRef.forEach((element) => {
      if (!element.current) return;
      code += element.current.value;
    });

    return code;
  };

  const submit = async () => {
    setLoading(true);

    try {
      const res = await AuthApi.podOTPVerify({
        cellphoneNumber,
        code: getCode(),
        keyId: kId,
        clientCode: Config.POD_CLIENT_CODE,
      });
      onLogin(res);
    } catch (e: any) {
      toast.error(e.message);
    }
    setLoading(false);
  };

  const onChange = (e:ChangeEvent<HTMLInputElement>) => {
    const dataStr = e.target.value;
    const data = parseInt(dataStr, 10);
    const indStr = e.target.dataset.index || '0';
    const index:number = parseInt(indStr, 10);
    const { current } = inputsRef[index];
    if (!current) return;

    const next = inputsRef[index + 1] && inputsRef[index + 1].current;
    if (typeof data !== 'number' || Number.isNaN(data)) {
      if (current) {
        current.value = '';
      }
      return;
    }

    if (index <= inputsRef.length - 1) {
      if (next) {
        next.removeAttribute('disabled');
        next.focus();
      }

      if (dataStr.length > 1) {
        current.value = `${dataStr[0]}`;
      }

      if (index === inputsRef.length - 1) {
        inputsRef[5].current?.blur();
        submit();
      }
    }
  };

  const onKeyup = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== 'Backspace') return;

    const indStr = e.currentTarget.dataset.index || '0';
    const index:number = parseInt(indStr, 10);
    const { current } = inputsRef[index];
    const dataStr = e.currentTarget.value;
    const data = parseInt(dataStr, 10);
    const prev = inputsRef[index - 1] && inputsRef[index - 1].current;

    if (!current) return;

    if (data && !Number.isNaN(data)) {
      current.value = '';
    } else if (prev) {
      current.setAttribute('disabled', 'true');
      prev.value = '';
      prev.focus();
    }
  };

  const resend = async () => {
    setLoading(true);

    try {
      const browserData = getBrowserData();
      const res = await AuthApi.podOTPLogin({
        appVersion: '1.0.0',
        cellphoneNumber,
        deviceId: generateUUID(),
        deviceName: 'browser',
        deviceType: 'Desktop',
        os: browserData.osName,
        osVersion: browserData.boewserVersion,
        clientCode: Config.POD_CLIENT_CODE,
      });

      setExpIn(res.expiresIn);
      setKId(res.keyId);
      setResendEnable(false);
      setTimerId(generateUUID());
    } catch (e) {
      // TODO show error
    }
    setLoading(false);
  };

  const getRemainSecView = () => {
    const tData = convertSecToMinSec(remainSec);
    return (
      <>
        { tData.minutes }
        :
        {tData.seconds}
      </>
    );
  };

  useEffect(() => {
    if (remainSec === 0) {
      setResendEnable(true);
    }
  }, [remainSec]);

  useEffect(() => {
    if (!inputsRef[0].current) return;
    inputsRef[0].current.focus();
  }, []);

  // useEffect(() => {
  //   if (!navigator.credentials) return;
  //   const ac = new AbortController();
  //   const params = {
  //     otp: { transport: ['sms'] },
  //     signal: ac.signal,
  //   };

  //   navigator.credentials.get(params).then((otp: any) => {
  //     const { code }: { code: string } = otp;

  //     code.split('').forEach((num: string, index: number) => {
  //       const { current } = inputsRef[index];
  //       if (current) {
  //         current.value = num;
  //       }
  //     });
  //     ac.abort();
  //   }).catch((err) => {
  //     console.log('credentials_err', err);
  //     ac.abort();
  //   });
  // }, []);

  return (
    <>
      <c.HeaderContainer>
        <c.CloseIcon onClick={onClose} />
      </c.HeaderContainer>
      {/* <c.Image src={OtpImage} /> */}
      <c.Video autoPlay muted>
        <source src="assets/videos/phonenumber.mp4" type="video/mp4" />
      </c.Video>
      <c.TitleText>{t('modalPodOTPLogin.enrerCode')}</c.TitleText>
      <c.DescriptionText>{t('modalPodOTPLogin.codeSendTo')}</c.DescriptionText>
      <div>
        (
        {cellphoneNumber}
        )
      </div>
      <c.CodeInputContainer disabled={loading}>
        <c.CodeInput type="number" data-index={0} onKeyUp={onKeyup} onChange={onChange} ref={inputsRef[0]} />
        <c.CodeInput type="number" data-index={1} onKeyUp={onKeyup} onChange={onChange} ref={inputsRef[1]} disabled />
        <c.CodeInput type="number" data-index={2} onKeyUp={onKeyup} onChange={onChange} ref={inputsRef[2]} disabled />
        <c.CodeInput type="number" data-index={3} onKeyUp={onKeyup} onChange={onChange} ref={inputsRef[3]} disabled />
        <c.CodeInput type="number" data-index={4} onKeyUp={onKeyup} onChange={onChange} ref={inputsRef[4]} disabled />
        <c.CodeInput type="number" data-index={5} onKeyUp={onKeyup} onChange={onChange} ref={inputsRef[5]} disabled />
      </c.CodeInputContainer>
      <c.ButtonContainer>
        <c.SendButton
          variant="contained"
          size="large"
          loading={loading}
          onClick={submit}
          style={{ textTransform: 'none' }}
        >
          {t('modalPodOTPLogin.registerCode')}
        </c.SendButton>
      </c.ButtonContainer>
      <c.BottomContainer>
        <c.ResendText disabled={!resendEnable} onClick={resend}>
          <c.RetryIcon />
          <c.TimeContainer>
            { getRemainSecView()}
          </c.TimeContainer>
        </c.ResendText>
        <c.EditPhoneText onClick={onPrevStep}>
          <c.EditIcon />
        </c.EditPhoneText>
      </c.BottomContainer>
    </>
  );
}

export default SecondStep;
