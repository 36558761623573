import React, {
  RefObject, createRef, useEffect, useState,
} from 'react';
import {
  CodeContainer, Container, Dash, NumberContainer, NumberParentContainer, NumbersContainer,
} from './components';

const INITIAL: {
  ref: RefObject<HTMLInputElement>,
  value: undefined|number
}[] = new Array(10).fill(0).map(() => ({
  ref: createRef(),
  value: undefined,
}));

function PhonenumberInput({
  onChange,
}: {
  readonly onChange: (number:string) => void
}) {
  const [numbers, setNumbers] = useState(INITIAL);

  const onChangeNum = (value: string, index: number) => {
    // console.log('onChange', index, value.length, numbers[index].value);

    if (value) {
      numbers[index].value = Number(value);
      numbers[index + 1]?.ref?.current?.focus();
    } else {
      if (numbers[index].value === undefined) {
        numbers[index - 1]?.ref?.current?.focus();
      }
      numbers[index].value = undefined;
    }

    setNumbers([...numbers]);

    const num = numbers.map((data) => data.value).join('');

    if (num.length === numbers.length) {
      const activeIndex = numbers.findIndex((data) => document.activeElement === data.ref.current);
      numbers[activeIndex]?.ref?.current?.blur();
    }
    onChange(num);
  };

  const nextFocus = () => {
    const activeIndex = numbers.findIndex((num) => document.activeElement === num.ref.current);

    if (activeIndex >= 0 && activeIndex < numbers.length - 1) {
      numbers[activeIndex + 1]?.ref?.current?.focus();
    }
  };

  const prevFocus = () => {
    const activeIndex = numbers.findIndex((num) => document.activeElement === num.ref.current);

    if (activeIndex > 0 && activeIndex <= numbers.length - 1) {
      numbers[activeIndex - 1]?.ref?.current?.focus();
    }
  };

  useEffect(() => {
    numbers[0].ref.current?.focus();
    const nums = numbers.map((data) => ({ ref: data.ref, value: undefined }));
    setNumbers([...nums]);
  }, []);

  return (
    <Container>
      <CodeContainer>+98</CodeContainer>
      <NumbersContainer>
        {
          numbers.map((data, index) => (
            <NumberParentContainer key={`id-${index + 1}`}>
              <NumberContainer
                type="number"
                value={data.value ?? ''}
                ref={data.ref}
                onChange={(e) => {
                  onChangeNum(e.target.value, index);
                }}
                onKeyDown={(e) => {
                  const { key } = e;

                  if (key === 'ArrowRight') {
                    nextFocus();
                    return;
                  }

                  if (key === 'ArrowLeft') {
                    prevFocus();
                    return;
                  }

                  if ((index === 0 && key === '0') || key === 'e' || key === '-' || key === '+') {
                    e.preventDefault();
                    return;
                  }

                  if (key === 'Backspace') {
                    if (index > 0 || data.value !== undefined) {
                      onChangeNum('', index);
                    }
                    return;
                  }

                  if (data.value) {
                    e.preventDefault();
                    if (index < numbers.length - 1) {
                      onChangeNum(key, index + 1);
                    }
                  }
                }}
              />
              {(index === 2 || index === 5) && <Dash />}
            </NumberParentContainer>
          ))
        }
      </NumbersContainer>
    </Container>
  );
}

export default PhonenumberInput;
