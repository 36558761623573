import Styled from 'styled-components';
import { VscSymbolNamespace } from 'react-icons/vsc';
import { BiCode, BiCast } from 'react-icons/bi';
import { PiUsersFourLight } from 'react-icons/pi';
import { AiOutlineCloseCircle } from 'react-icons/ai';

export const Container = Styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  pointer-events: none;
`;

export const InnerContainer = Styled.div`
  pointer-events: all;
  backdrop-filter: blur(7px);
    background-color: rgb(26 25 25 / 40%);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: 100%;
  height: 100%;

  @media(min-width: 768px) {
    /* padding: 15px 15px 15px 15px; */
    width: 60%;
    max-width: 400px;
    max-height: 600px;
    height: 80%;
  }
`;

export const RowContainer = Styled.div<{$margin?: string}>`
  margin: ${(props) => (props.$margin ?? 0)};
`;

export const MembersContainer = Styled.div`
  flex:1;
  overflow: hidden;
  min-height: 250px;
`;

export const NameIcon = Styled(VscSymbolNamespace)`
`;

export const MembersIcon = Styled(PiUsersFourLight)`
`;

export const MemberSessionIcon = Styled(BiCast)`
`;

export const CodeIcon = Styled(BiCode)`
`;

export const Header = Styled.div`
  display: flex;
  align-items: center;
  height: 65px;
  justify-content: space-between;
  border-bottom: 1px solid gray;
  padding: 0 15px;
`;

export const Body = Styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 0 5px 0 15px;
`;

export const CloseIcon = Styled(AiOutlineCloseCircle)`
  font-size: 1.5rem;
  cursor: pointer;
`;
