import React from 'react';
import { toast, Zoom } from 'react-toastify';
import {
  Container, ContainerInfo, CopyContainer, CopyIcon, LinkIcon, LinkText, TitleText,
} from './components';

function RoomLink({
  link,
}: {
  link: string
}) {
  const copyLinkToClipboard = () => {
    navigator.clipboard?.writeText(link);
    toast.success('link copied to clipboard', {
      transition: Zoom,
      autoClose: 2000,
    });
  };

  return (
    <Container onClick={copyLinkToClipboard}>
      <ContainerInfo>
        <TitleText>
          <LinkIcon />
          copy link
        </TitleText>
        <LinkText>{link}</LinkText>
      </ContainerInfo>
      <CopyContainer>
        <CopyIcon />
      </CopyContainer>
    </Container>
  );
}

export default RoomLink;
