import Styled from 'styled-components';
import { AiOutlineMessage } from 'react-icons/ai';

export const Container = Styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  padding: 15px 0;
`;

export const TitleText = Styled.div`
  
`;

export const ContainerInfo = Styled.div`
  flex:1;
`;

export const Icon = Styled(AiOutlineMessage)`
  padding-right: 15px;
`;
