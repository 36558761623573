import Styled from 'styled-components';

export const Container = Styled.div`
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

`;

export const ContainerInner = Styled.div`
  pointer-events: all;
  width: 100%;
  height: 100%;  
  position: relative;

  @media(min-width: 768px) {
    width: 90%;
    height: 80%;  
    max-width:460px;
    width: 35%;
    width:460px;
  }

`;

export const ContentContainer = Styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${(props) => props.theme.palette.grey['900']};
  height: 100%;

  @media(min-width: 768px) {
    height: auto;
  }

`;
