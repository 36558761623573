import { Modal } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import UserDTO from '../../../dto/user.dto';
import AuthApi from '../../../services/gateway/authApi';
import ConferenceApi from '../../../services/gateway/conferenceApi';
import { useConferenceInfo } from '../context/conferenceInfo.context';
import { useMembers } from '../context/members.context';
import * as c from './components';
import MemberCard from './memberCard';
import Loading1 from '../../loadings/loading1';
import { useUserInfoContext } from '../../../contexts/userInfoContext';
// import { PubSubEvent, usePub } from '../hook/pubSub';

function SelectUser({ name, onClick }: { name: string; onClick: any}) {
  return (
    <c.SelectUserContainer onClick={onClick}>
      <c.SelectUserRemoveContainer>
        <c.CloseIcon />
      </c.SelectUserRemoveContainer>
      {name}
    </c.SelectUserContainer>
  );
}

function MemberModal({
  onClose,
}:{
  readonly onClose: any;
}) {
  const [users, setUsers] = useState<UserDTO[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<UserDTO[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);
  const { conference } = useConferenceInfo();
  const { addMembers, getMemberByUserId } = useMembers();
  const [loading, setLoading] = useState(false);
  const { userInfo } :
  {userInfo?:UserDTO|null} = useUserInfoContext();
  const dialogHashName = '#members-dialog';
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  // const publish = usePub();

  const getUsers = async ({ filter }: { filter?: string }) => {
    try {
      const params: any = {
        ...(filter && { filter }),
      };
      const { users: usrs } = await AuthApi.getUsers(params);
      const list = usrs.filter((user) => getMemberByUserId(user.id) === undefined);
      setUsers(list);
    } catch (e) {
      // TODO: handle error
    }
  };

  const initUser = async () => {
    setLoading(true);
    await getUsers({});
    setLoading(false);
  };

  const onTextChange = async (e: any) => {
    setLoading(true);
    await getUsers({ filter: e.target.value });
    setLoading(false);
  };

  const selectUser = (userId: string) => {
    const user = users.find((u) => u.id === userId);
    if (user) {
      setSelectedUsers([...selectedUsers, user]);
    }
  };

  const removeSelectUser = (userId: string) => {
    const usrs = selectedUsers.filter((u) => u.id !== userId);
    setSelectedUsers(usrs);
  };

  const isUserSelect = (userId: string) => selectedUsers.some((user: any) => user.id === userId);

  const addSelectedMembers = async () => {
    if (!conference?.id) return;
    try {
      const res = await ConferenceApi.invite({
        roomId: conference.id,
        members: selectedUsers.map((usr) => ({ userId: usr.id })),
      });

      // const members = selectedUsers.map((user) => ({
      //   user,
      //   joinsInfo: [],
      // }));

      const mems = res.members.map((member) => {
        const usr: any = selectedUsers.find((user) => user.id === member.userId);
        return {
          id: member.id,
          user: usr,
          role: member.role,
          joinsInfo: [],
        };
      });

      addMembers(mems);
      // publish(PubSubEvent.ROOM_MEMBERS_ADDED, mems);

      close();
    } catch (e: any) {
      toast.error(e.message);
    }
  };

  const close = () => {
    navigate(-1);
    onClose();
  };

  const handleOnHashChange = () => {
    const isHashMatch = window.location.hash === dialogHashName;
    if (!isHashMatch && isOpen) {
      onClose();
    }
  };

  useEffect(() => {
    initUser();
    navigate(dialogHashName);
    setIsOpen(true);

    return () => {
      setIsOpen(false);
    };
  }, []);

  useEffect(() => {
    window.addEventListener('hashchange', handleOnHashChange);

    return () => window.removeEventListener('hashchange', handleOnHashChange);
  }, [handleOnHashChange]);

  return (
    <Modal
      open
      onClose={close}
      style={{ zIndex: 1401 }}
    >
      <c.Container>
        <c.ContainerInner>
          <c.HeaderContainer>
            <div>add member</div>
            <c.CloseIcon onClick={close} />
          </c.HeaderContainer>
          { selectedUsers.length > 0
            && (
            <c.SelectedContainer>
              { selectedUsers.map((sUsr) => (
                <SelectUser
                  onClick={() => { removeSelectUser(sUsr.id); }}
                  key={sUsr.id}
                  name={sUsr.nickname}
                />
              ))}
            </c.SelectedContainer>
            )}
          <c.SearchInputContainer>
            <c.SearchInput
              ref={inputRef}
              onChange={onTextChange}
              placeholder="search hear"
            />
          </c.SearchInputContainer>
          <c.ResultContainer className="scroll">
            {
              loading && <c.LodingContainer><Loading1 /></c.LodingContainer>
            }
            {
              !loading && users.map((user) => {
                if (isUserSelect(user.id) || user.id === userInfo?.id) return null;
                return (
                  <MemberCard
                    onClick={() => {
                      selectUser(user.id);
                    }}
                    key={user.id}
                    name={user.nickname}
                  />
                );
              })
            }
          </c.ResultContainer>
          {selectedUsers.length > 0 && (
          <c.FooterContainer>
            <c.AddMemButton onClick={addSelectedMembers}>
              <c.AddMemberIcon />
            </c.AddMemButton>
          </c.FooterContainer>
          )}
        </c.ContainerInner>
      </c.Container>
    </Modal>
  );
}

export default MemberModal;
