import React, { useRef, useState } from 'react';
// import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import * as c from './components';
import ModalPodOTPLogin from '../../../components/modalPodOTPLogin';
import LoginConfirmDialog from '../../../components/confirmDialog';
import { useUserInfoContext } from '../../../contexts/userInfoContext';
import UserDTO from '../../../dto/user.dto';
import ConferenceApi from '../../../services/gateway/conferenceApi';
import WebsocketHandler from '../../../socket/websocket';
import ConferenceSetupModal from '../component/conferenceSetup';
import Header from '../../../components/layout/header';

function HomePageDesktop() {
  const { userInfo } :
  {userInfo?:UserDTO|null} = useUserInfoContext();
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [loginConfirmDialog, setLoginConfirmDialog] = useState(false);
  const [setupModalOpen, setSetupModalOpen] = useState(false);
  const [joinEnable, setJoinEnable] = useState(false);
  const codeRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  const showSetupConference = () => {
    if (!userInfo) {
      setLoginConfirmDialog(true);
      return;
    }
    setSetupModalOpen(true);
  };

  const addConference = async (
    {
      name,
      code: roomCode,
      accessType,
      maxMember,
      maxMemberJoin,
      nodeId,
    }:
    {
      name: string;
      code: string|undefined;
      accessType: number;
      maxMember?: number;
      maxMemberJoin?: number;
      nodeId?: string;
    },
  ) => {
    const { id } = await ConferenceApi.addRoom({
      name,
      code: roomCode,
      accessType,
      maxMember,
      maxMemberJoin,
      nodeId,
    });

    await joinConference(id);
  };

  const joinConference = async (roomId: string) => {
    try {
      const peerData = WebsocketHandler.getPeerData();

      const {
        id, rtcConfiguration,
      } = await ConferenceApi.joinRoom({
        peerId: peerData.peerId,
        roomId,
      });

      navigate(`/rooms/${roomId}/joins/${id}`, {
        state: { rtcConfiguration },
      });
    } catch (e: any) {
      toast.error(e.message);
    }
  };

  const request = async () => {
    if (!userInfo) {
      setLoginConfirmDialog(true);
      return;
    }

    if (!codeRef.current?.value || codeRef.current.value.length === 0) return;

    try {
      const peerData = WebsocketHandler.getPeerData();

      let code = codeRef.current.value;
      if (code.startsWith('http')) {
        // const codes = code.split('/');
        code = code.match(/[^/]+$/)?.[0] || '';
      }

      const params: any = {
        peerId: peerData.peerId,
      };

      if (code.length > 30) {
        params.id = code;
      } else {
        params.code = code.replace(/-/g, '').toLocaleLowerCase();
      }

      const { canJoin, room } = await ConferenceApi.roomRequest(params);

      if (canJoin) {
        joinConference(room.id);
      }
    } catch (e: any) {
      toast.error(e.message);
    }
  };

  const onConferenceCodeChange = (e: any) => {
    const text = e.target.value.trim();

    if (text.length >= 3) {
      if (!joinEnable) {
        setJoinEnable(true);
      }
    } else if (joinEnable) {
      setJoinEnable(false);
    }
  };

  // const { t } = useTranslation('loginPage');

  return (
    <c.ContainerStyled>
      <Header />
      <c.BodyContainer>
        <c.StartContainer>
          <c.Start
            $active={!setupModalOpen && !loginConfirmDialog}
          >
            <c.InfosContainer>
              <c.JoinInfoContainer>
                <c.JoinInputContainer>
                  <c.KeyboardIcon />
                  <c.JoinInput
                    ref={codeRef}
                    placeholder="Meetali code or id"
                    onChange={onConferenceCodeChange}
                  />
                </c.JoinInputContainer>
                <c.JoinButtonContainer onClick={request} $active={joinEnable}>
                  <c.JoinButton />
                </c.JoinButtonContainer>
              </c.JoinInfoContainer>
              <c.OrSeperate
                $visible={!joinEnable}
              >
                OR
              </c.OrSeperate>
              <c.AddIconContainer
                onClick={showSetupConference}
                $visible={!joinEnable}
              >
                <c.AddIcon />
              </c.AddIconContainer>
            </c.InfosContainer>
          </c.Start>
        </c.StartContainer>
      </c.BodyContainer>
      {/* <c.FooterContainer>
        <c.AuthContainerStyled onClick={onClickPodLogin}>
          <c.LoginIcon />
          <c.AuthTextStyled>
            {t('enter') }
          </c.AuthTextStyled>
        </c.AuthContainerStyled>
      </c.FooterContainer> */}
      {loginModalOpen && <ModalPodOTPLogin onClose={() => { setLoginModalOpen(false); }} />}
      {setupModalOpen && (
        <ConferenceSetupModal
          onClose={() => { setSetupModalOpen(false); }}
          onRegister={async (params) => {
            try {
              await addConference(params);
              setSetupModalOpen(false);
            } catch (e: any) {
              toast.error(e.message);
            }
          }}
        />
      )}
      {loginConfirmDialog && (
        <LoginConfirmDialog
          title="login"
          description="do you want login?"
          // acceptMessage="yes"
          // rejectMessage="no"
          onClose={() => {
            setLoginConfirmDialog(false);
          }}
          onAccept={() => {
            setLoginConfirmDialog(false);
            setLoginModalOpen(true);
          }}
          onReject={() => {
            setLoginConfirmDialog(false);
          }}
        />
      )}
    </c.ContainerStyled>
  );
}

export default HomePageDesktop;
