import Styled from 'styled-components';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { CiSettings } from 'react-icons/ci';
import { GrConnect } from 'react-icons/gr';

export const Container = Styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background: #212121;
`;

export const ContainerInner = Styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
`;

export const Header = Styled.div`
  display: flex;
  justify-content: space-between;
  height: 75px;
  padding: 0 15px;
  align-items: center;
  background: ${(props) => props.theme.custom.headerBackground};
`;

export const JoinButton = Styled.div<{$enable: boolean}>`
  padding: 10px;
  border-radius: 50%;
  color: ${(props) => props.theme.custom.greenLight};
  cursor: pointer;
  margin: 15px;
  font-size: 1.2rem;
  transition: ${(props) => props.theme.custom.transition};
  display: flex;
  background: white;
  pointer-events: ${(props) => (props.$enable ? 'all' : 'none')}; 
  opacity: ${(props) => (props.$enable ? 1 : 0.5)}; 
`;

export const JoinIcon = Styled(GrConnect)`
  
`;

export const CloseIcon = Styled(AiOutlineCloseCircle)`
  font-size: 1.5rem;
  cursor: pointer;
`;

export const Body = Styled.div`
  flex:1;
  overflow: scroll;
`;

export const NameContainer = Styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const SettingIcon = Styled(CiSettings)`
  font-size: 1.5rem;
  margin: 0 5px;
  color: gray;
`;

export const HeaderRightContainer = Styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
`;
