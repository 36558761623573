import Styled from 'styled-components';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { FiPhoneCall } from 'react-icons/fi';
import { HiUserPlus } from 'react-icons/hi2';

export const Container = Styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  width: 100%;
  height: 100%;
`;

export const ContainerInner = Styled.div`
pointer-events: all;
width: 50%;
height: 80%;  
max-height:460px;
max-width:460px;
min-width: 360px;
background: ${(props) => props.theme.custom.bodyBackground};
border-radius: 5px;
position: relative;
display: flex;
flex-direction: column;
`;

export const HeaderContainer = Styled.div`
  padding: 20px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${(props) => props.theme.custom.headerBackground};
`;

export const ResultContainer = Styled.div`
  flex: auto;
  overflow-y: auto;
  padding: 5px 15px 20px 15px;
`;

export const SearchInputContainer = Styled.div`
  height: 60px;
`;

export const SearchInput = Styled.input`
  width: 100%;
  height: 100%;
  padding: 5px 10px;
  box-sizing: border-box;
  background: #42424245;
  border-top: 1px solid grey;
  border-bottom: 1px solid grey;
  border-right: none;
  border-left: none;
  color: white;
  font-size: 1.3rem;


  &:focus {
    outline: none;
  }
`;

export const CloseIcon = Styled(AiOutlineCloseCircle)`
  font-size: 1.5rem;
  cursor: pointer;
`;

export const VoiceCallIcon = Styled(FiPhoneCall)`
  font-size: 2rem;
  margin: 0 20px;
  cursor: pointer;
`;

export const SelectedContainer = Styled.div`
  display: flex;
`;

export const FooterContainer = Styled.div`
  border-top: 1px solid #8080805c;
  display: flex;
  justify-content: end;
`;

export const SelectUserContainer = Styled.div`
  padding: 10px 15px;
  margin: 5px;
  background-color: white;
  border-radius: 30px;
  display: flex;
  align-items: center;
  color: black;
  cursor: pointer;
`;

export const SelectUserRemoveContainer = Styled.div`
  margin: 0 5px;
  display: flex;
  align-items: center;
  color: black;
`;

export const AddMemButton = Styled.button`
  border: none;
  padding: 15px;
  background-color: white;
  border-radius: 30px;
  color: ${(props) => props.theme.custom.azureLight};
  margin: 10px;
  cursor: pointer;
  font-size: 1.2rem;
  display: flex;
  transition: ${(props) => props.theme.custom.transition};

  &:hover {
    transform: scale(1.1);
    background-color: ${(props) => props.theme.custom.azureLight};
    color: white;
  }
`;

export const LodingContainer = Styled.div`
    height: 50px;
    margin-top: 30px;
    display: flex;
    justify-content: center;
`;

export const AddMemberIcon = Styled(HiUserPlus)`
  font-size: 1.5rem;
`;
