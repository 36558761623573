import React, { useEffect } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import ProfilePageMobile from './mobile';

function ProfilePage() {
  useEffect(() => {
    document.title = 'Profile';
  }, []);

  return (
    <>
      <MobileView renderWithFragment>
        <ProfilePageMobile />
      </MobileView>
      <BrowserView renderWithFragment>
        <ProfilePageMobile />
      </BrowserView>
    </>
  );
}

export default ProfilePage;
