import Styled from 'styled-components';
import { PiUsersThreeDuotone } from 'react-icons/pi';

export const Container = Styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 0;
  height: 100%;
`;

export const TitleContainer = Styled.div`
    display: flex;
    align-items: center;
`;

export const TitleText = Styled.div`
  
`;

export const ContainerInfo = Styled.div`
  flex:1;
`;

export const Icon = Styled(PiUsersThreeDuotone)`
  padding-right: 15px;
`;

export const MembersContainer = Styled.div`
  padding: 15px 0 35px 15px;
  overflow-y: auto;
`;
