import Styled from 'styled-components';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { MdCopyAll } from 'react-icons/md';
import { LiaUserPlusSolid } from 'react-icons/lia';

export const Container = Styled.div`
  display: flex;
  position: absolute;
  left: 15px;
  right: 15px;
  bottom: 0;
  z-index: 4;
`;

export const ContainerInner = Styled.div`
  max-width: 400px;
  background: ${(props) => props.theme.palette.grey['800']};
  border-radius: 15px;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 10px;
`;

export const IHeader = Styled.div`
  padding: 20px 0 5px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const IBody = Styled.div`
  flex: auto;
  overflow-y: auto;
  padding: 0;
  text-align: left;
`;

export const CloseIcon = Styled(AiOutlineCloseCircle)`
  font-size: 1.5rem;
  cursor: pointer;
`;

export const AddMemberIconContainer = Styled.div`
  display: flex;  
`;

export const AddMemberButton = Styled.div`
  background-color: white;
  color: ${(props) => props.theme.custom.azureLight};
  padding: 15px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: ${(props) => props.theme.custom.transition};
  border-radius: 50%;
  display: flex;
  margin: 5px;

  &:hover {
    transform: scale(1.1);
    background-color: ${(props) => props.theme.custom.azureLight};
    color: white;
  }
`;

export const InviteIcon = Styled(LiaUserPlusSolid)`
  font-size: 1.8rem;
`;

export const infoText = Styled.p`
  font-size: 0.8rem;
  color: gray;
  margin-top: 5px;
`;

export const LinkContainer = Styled.div`
  background-color: #61616175;
  padding: 15px 0;
  display: flex;
  justify-content: space-between;
  flex-direction: row ;

  @media (min-width: 480px) {
    padding: 15px 5px;
  }
`;

export const LinkText = Styled.div`
  flex: auto;
  overflow: auto;
  display: flex;
  align-items: center;
  flex-direction: row;
  max-width: 330px;
  padding: 0 5px;

  @media (min-width: 480px) {
    max-width: inherit;  
  }
`;

export const CopyIcon = Styled(MdCopyAll)`
  font-size: 3rem;
  cursor: pointer;
`;
