import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useLocation, useNavigate } from 'react-router-dom';
import * as c from './components';
import Routes from '../../../commons/routs';
import { useUserInfoContext } from '../../../contexts/userInfoContext';
import UserDTO from '../../../dto/user.dto';
import ProfileDialog from './components/profileDialog';
import ConfirmDialog from '../../confirmDialog';
import ModalPodOTPLogin from '../../modalPodOTPLogin';

function Header() {
  const { userInfo } :
  {userInfo?:UserDTO|null} = useUserInfoContext();
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [loginConfirmDialog, setLoginConfirmDialog] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const navigate = useNavigate();
  const location = useLocation();

  const handleUserIconClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!userInfo) {
      setLoginConfirmDialog(true);
      return;
    }

    if (isMobile) {
      if (location.pathname !== Routes.PROFILE.path) {
        navigate(Routes.PROFILE.path);
      }
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const showContactUs = () => {
    if (location.pathname !== Routes.Contactus.path) {
      navigate(Routes.Contactus.path);
    } else {
      navigate(Routes.HOME.path);
    }
  };

  const closeProfileDialog = () => {
    setAnchorEl(null);
  };

  return (
    <c.HeaderContainer>
      <c.MeetIconContainer onClick={showContactUs}>
        <c.MeetIcon>
          M
        </c.MeetIcon>
      </c.MeetIconContainer>
      <c.UserIconContainer onClick={handleUserIconClick}>
        {
          userInfo?.imageUrl ? <c.UserImage src={userInfo.imageUrl} /> : <c.UserIcon />
        }
      </c.UserIconContainer>
      {
        anchorEl && (
        <ProfileDialog
          onClose={closeProfileDialog}
          anchorEl={anchorEl}
        />
        )
      }
      {loginModalOpen && <ModalPodOTPLogin onClose={() => { setLoginModalOpen(false); }} />}
      {loginConfirmDialog && (
      <ConfirmDialog
        title="login"
        description="do you want login?"
          // acceptMessage="yes"
          // rejectMessage="no"
        onClose={() => {
          setLoginConfirmDialog(false);
        }}
        onAccept={() => {
          setLoginConfirmDialog(false);
          setLoginModalOpen(true);
        }}
        onReject={() => {
          setLoginConfirmDialog(false);
        }}
        marginTop={75}
      />
      )}
    </c.HeaderContainer>
  );
}

export default Header;
