import React, {
  useEffect, useRef, useState,
} from 'react';
import hark from 'hark';
import { toast } from 'react-toastify';
import {
  activeFullscreen, activePictureInPicture, deactivateFullscreen,
  deactivatePictureInPicture, fullscreenEnabled, pictureInPictureEnabled,
} from '../../../commons/helper';
import {
  Container, FullscreenContainer, FullscreenExitIcon,
  FullscreenIcon, InfoContainer, LoadingContainer, PIPContainer,
  PIPExitIcon, PIPIcon, RemoveButton, RemoveIcon,
  UserIcon, UserImage, UsernameContainer, Video, VoiceLevelContainer,
  VoiceLevelItem,
} from './components';
import ConfirmDialog from '../../confirmDialog';
import ConferenceApi from '../../../services/gateway/conferenceApi';
import ConferenceJoinStatus from '../../../types/conferenceJoinStatus';
import Loading1 from '../../loadings/loading1';

function UserInfo({
  name,
  userImage,
}: {
  readonly name?: string|null;
  readonly userImage?: string|null;
}) {
  return (
    <InfoContainer>
      <div>
        {userImage ? <UserImage src={userImage} /> : <UserIcon />}
      </div>
      <UsernameContainer>{name}</UsernameContainer>
    </InfoContainer>
  );
}

UserInfo.defaultProps = {
  name: null,
  userImage: null,
};

function StreamCard({
  width,
  height,
  margin,
  name,
  userImage,
  stream,
  removeEnable,
  roomId,
  memberId,
  joinId,
  joinStatus,
  muted,
  mirror,
}:
  {
    readonly width: number;
    readonly height: number;
    readonly margin: number;
    readonly name?: string|null;
    readonly userImage?: string|null;
    readonly stream?: MediaStream|null;
    readonly removeEnable: boolean;
    readonly roomId: string;
    readonly memberId: string;
    readonly joinId: string;
    readonly joinStatus: number;
    readonly muted: boolean;
    readonly mirror: boolean;
  }) {
  // console.log('stream tracks', stream?.getTracks().length);
  const videoRef = useRef<HTMLVideoElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [fullscreen, setFullscreen] = useState(false);
  const [picInPic, setPicInPic] = useState(false);
  const [soundEnable, setSoundEnable] = useState(false);
  const [leaveConfirmDialog, setLeaveConfirmDialog] = useState(false);

  const toggleFullscreen = () => {
    if (!containerRef.current) return;
    if (!fullscreen) {
      activeFullscreen(containerRef.current);
    } else {
      deactivateFullscreen();
    }
    setFullscreen(!fullscreen);
  };

  const togglePictureInPicture = () => {
    if (!videoRef.current) return;
    if (!picInPic) {
      activePictureInPicture(videoRef.current);
    } else {
      deactivatePictureInPicture(videoRef.current);
    }
    setPicInPic(!picInPic);
  };

  const onFullscreenChange = () => {
    if (document.fullscreenElement) {
      setFullscreen(true);
    } else {
      setFullscreen(false);
    }
  };

  const onPicInPicChange = () => {
    // console.log('picInPic');
    if (document.pictureInPictureElement) {
      setPicInPic(true);
    } else {
      setPicInPic(false);
    }
  };

  const onClick = (e: any) => {
    if (e.detail === 2 && stream) { // double click
      toggleFullscreen();
    }
  };

  const removeJoin = async () => {
    try {
      await ConferenceApi.removeMemberJoin({
        roomId,
        memberId,
        joinId,
      });
    } catch (e: any) {
      toast.error(e.message);
    }
  };

  useEffect(() => {
    if (stream && videoRef.current) {
      videoRef.current.srcObject = stream;
      videoRef.current.play().catch(() => {});
    }
  }, [stream]);

  useEffect(() => {
    containerRef.current?.addEventListener('fullscreenchange', onFullscreenChange);
    videoRef.current?.addEventListener('enterpictureinpicture', onPicInPicChange);
    videoRef.current?.addEventListener('leavepictureinpicture', onPicInPicChange);
    return () => {
      containerRef.current?.removeEventListener('fullscreenchange', onFullscreenChange);
      videoRef.current?.removeEventListener('enterpictureinpicture', onPicInPicChange);
      videoRef.current?.removeEventListener('leavepictureinpicture', onPicInPicChange);
    };
  }, [containerRef.current]);

  useEffect(() => {
    let speechEvents: any;

    if (stream) {
      if (stream.getVideoTracks().length === 0 && videoRef.current) {
        // aftre stop video by remote stream the last picture exists in video
        videoRef.current.load();
        videoRef.current.play().catch(() => {});
      }

      if (stream.getAudioTracks().length > 0) {
        const options = {
          // interval: 500,
          // threshold: 50,
        };
        speechEvents = hark(stream, options);

        speechEvents.on('speaking', () => {
          setSoundEnable(true);
        });

        speechEvents.on('stopped_speaking', () => {
          setSoundEnable(false);
        });

        // speechEvents.on('volume_change', (e) => {
        //   console.log('----', e);
        // });
      }
    }

    return () => {
      speechEvents?.stop();
    };
  }, [stream?.getTracks().length]);

  return (
    <Container
      $width={width}
      $height={height}
      $margin={margin}
      ref={containerRef}
      onClick={onClick}
    >
      {!fullscreen && !stream && <UserInfo name={name} userImage={userImage} />}
      {stream && (
      <Video
        ref={videoRef}
        autoPlay
        muted={muted}
        playsInline
        $mirror={!picInPic && mirror}
      />
      )}
      {(stream && stream.getVideoTracks().length > 0 && fullscreenEnabled()) && (
        <FullscreenContainer>
          {
            fullscreen
              ? <FullscreenExitIcon onClick={toggleFullscreen} />
              : <FullscreenIcon onClick={toggleFullscreen} />
          }
        </FullscreenContainer>
      )}
      {(stream && stream.getVideoTracks().length > 0 && pictureInPictureEnabled()) && (
        <PIPContainer>
          {
            picInPic
              ? <PIPExitIcon onClick={togglePictureInPicture} />
              : <PIPIcon onClick={togglePictureInPicture} />
          }
        </PIPContainer>
      )}
      {
        (stream && stream.getAudioTracks().length > 0)
          && (
          <VoiceLevelContainer>
            <VoiceLevelItem $maxHeight={7} className={soundEnable ? 'voice' : ''} />
            <VoiceLevelItem $maxHeight={10} className={soundEnable ? 'voice' : ''} />
            <VoiceLevelItem $maxHeight={7} className={soundEnable ? 'voice' : ''} />
          </VoiceLevelContainer>
          )
      }
      {
        removeEnable && (
        <RemoveButton
          variant="outlined"
          onClick={() => setLeaveConfirmDialog(true)}
        >
          <RemoveIcon $disabled={false} />
        </RemoveButton>
        )
      }
      {leaveConfirmDialog && (
        <ConfirmDialog
          title="remove"
          description="are you sure?"
          // acceptMessage="yes"
          // rejectMessage="no"
          onClose={() => {
            setLeaveConfirmDialog(false);
          }}
          onAccept={() => {
            setLeaveConfirmDialog(false);
            removeJoin();
          }}
          onReject={() => {
            setLeaveConfirmDialog(false);
          }}
        />
      )}
      {
        joinStatus === ConferenceJoinStatus.DISCONNECTED
        && (
        <LoadingContainer>
          <Loading1 />
        </LoadingContainer>
        )
      }
    </Container>
  );
}

StreamCard.defaultProps = {
  stream: null,
  name: null,
  userImage: null,
};

export default StreamCard;
