import Styled from 'styled-components';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import LoadingButton from '@mui/lab/LoadingButton';
import { IoMdAdd, IoIosArrowDown } from 'react-icons/io';

export const Container = Styled.div`
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

export const ContainerInner = Styled.div`
  pointer-events: all;
  position: relative;
  backdrop-filter: blur(7px);
  background-color: rgb(26 25 25 / 40%);
  padding: 15px;
  width: 80%;
  max-width: 600px;
`;

export const Header = Styled.div`
  padding: 15px 0;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
`;

export const Body = Styled.div`
  margin-bottom: 25px;
`;

export const Footer = Styled.div`
  display: flex;
  justify-content: end;
  margin-top: 25px;
`;

export const AdvanceText = Styled.div`
    margin-top: 30px;
    text-align: center;
    color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
`;

export const ArrowIcon = Styled(IoIosArrowDown)<{$enable: boolean}>`
    transition: 1s;
    transform: scaleY(${(props) => (props.$enable ? -1 : 1)})
`;

export const AdvanceContainer = Styled.div<{$visible: boolean}>`
  margin-top: 15px;
  display: ${(props) => (props.$visible ? 'block' : 'none')};
`;

export const RowContainer = Styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
`;

export const TextContainer = Styled.div`
  display: flex;
  align-items: center;
  flex: 0.5;
  font-size: 1.2rem;
  font-weight: bold;
`;

export const InputContainer = Styled.div`
  flex: 0.5;
`;

export const CloseIcon = Styled(AiOutlineCloseCircle)`
  font-size: 1.5rem;
  cursor: pointer;
`;

export const AddIcon = Styled(IoMdAdd)`
  font-size: 1.6rem;
`;

export const MyLoadinButton = Styled(LoadingButton)`
  background: white;
  color: black;
  transition: ${(props) => props.theme.custom.transition};
  padding: 10px;
  width: inherit;
  border-radius: 50%;
  display: flex;
  min-width: inherit;

  &:hover {
    transform: scale(1.1);
    background: ${(props) => props.theme.custom.greenDark};
    color: white;
  }
`;
