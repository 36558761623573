import Styled from 'styled-components';
import LoadingButton from '@mui/lab/LoadingButton';
import { VscClose } from 'react-icons/vsc';
import { GrGoogle } from 'react-icons/gr';

export const Image = Styled.img`
  margin-top: 15px;
  max-width: 150px;

  @media(min-width:768px) {
    margin-top: 30px;
    max-width: 200px
  }
`;

export const Video = Styled.video`
  border-radius: 50%;
  margin-top: 15px;
  width: 150px;
  filter: grayscale(1);

  @media(min-width:768px) {
    margin-top: 30px;
    width: 200px;
    height: 200px
  }
`;

export const TitleText = Styled.p`
  font-weight:bold;
  font-size: 2em;
  margin: 30px 0 0 0;
`;

export const DescriptionText = Styled.p`
  font-size: 0.875em;
  margin-top: 15px 0 0 0;
`;

export const PhoneInputContainer = Styled.div`
  margin-top: 20px;
  @media(min-width:768px) {
    margin-top: 40px;
  }
`;

export const ButtonContainer = Styled.div`
  margin-top: 30px;
  width: 70%;
  text-align: center;
`;

export const SendButton = Styled(LoadingButton)<{ $active:boolean }>`
  width:${(props) => (props.$active ? '100%' : '50%')};
  transition: ${(props) => props.theme.custom.transition};
  background: white;
  color: black;
  /* &[disabled] {
    width:50%;
  } */
`;

export const PodLogoContainer = Styled.div`
  background-color: white;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  transition: ${(props) => props.theme.custom.transition};

  &:hover {
    transform: scale(1.2)
  }
`;

export const PodLogoStyled = Styled.img`
  max-width: 90px;
  cursor: pointer;
  color: black;
  filter: grayscale(100%);
`;

export const GoogleLogoContainer = Styled.div`
    background-color: white;
    padding: 10px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: ${(props) => props.theme.custom.transition};

    &:hover {
      transform: scale(1.2)
    }
`;

export const GoogleLogoStyled = Styled(GrGoogle)`
  font-size: 3rem;
  cursor: pointer;
  color: black;
`;

export const Divider = Styled.div`
  border-top: 1px solid #bbb;
  margin-top: 35px;
  width: 300px;
`;

export const SsoLoginContainer = Styled.div`
  display: flex;
  justify-content: space-around;
  width: 250px;
  margin: 35px 0;
`;

export const HeaderContainer = Styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
`;

export const CloseIcon = Styled(VscClose)`
  font-size: 2rem;
  margin: 10px;
  cursor: pointer;
`;
