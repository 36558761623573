import Styled from 'styled-components';
import { HiUserPlus } from 'react-icons/hi2';

export const Conitainer = Styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const MembersContainer = Styled.div`
  display: flex;
  flex-direction: column;
  flex:1;
  overflow: hidden;
`;

export const AddMemberContainer = Styled.div`
  display: flex;
  justify-content: center;
  padding: 10px 0;
`;

export const AddMemberIconContainer = Styled.button`
  border: none;
  padding: 15px;
  background-color: white;
  border-radius: 30px;
  color: ${(props) => props.theme.custom.azureLight};
  cursor: pointer;
  font-size: 1.2rem;
  display: flex;
  transition: ${(props) => props.theme.custom.transition};

  &:hover:enabled {
    transform: scale(1.1);
    background-color: ${(props) => props.theme.custom.azureLight};
    color: white;
  }

  &[disabled] {
    background-color: gray;
    transform: scale(1);
    color: #3b3b3b;
  }
`;

export const AddMemberIcon = Styled(HiUserPlus)`
  font-size: 1.5rem;
`;
