import Styled from 'styled-components';
import { CiSettings } from 'react-icons/ci';
import { GrConnect } from 'react-icons/gr';
import { FaHome } from 'react-icons/fa';

export const Container = Styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const BodyContainer = Styled.div`
  flex: 1;
  display: flex;
  overflow: hidden;
`;

export const RoomCardsContainer = Styled.div`
  overflow-y: scroll;
`;

export const ChatContainer = Styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ChatHeaderContainer = Styled.div`
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const JoinButton = Styled.div<{$enable: boolean}>`
  background: white;
  padding: 10px;
  border-radius: 50%;
  color: ${(props) => props.theme.custom.greenLight};
  cursor: pointer;
  margin: 15px;
  font-size: 1.2rem;
  transition: ${(props) => props.theme.custom.transition};
  display: flex;
  pointer-events: ${(props) => (props.$enable ? 'all' : 'none')}; 
  opacity: ${(props) => (props.$enable ? 1 : 0.5)}; 

  &:hover {
    background: ${(props) => props.theme.custom.greenLight};
    transform: scale(1.1);
    color: white;
  }
`;

export const JoinIcon = Styled(GrConnect)`
  
`;

export const ChatBodyContainer = Styled.div`
  flex:1;
  overflow-y: hidden;
  width: 100%;
`;

export const NameContainer = Styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 15px;
`;

export const SettingIcon = Styled(CiSettings)`
  font-size: 1.5rem;
  margin: 0 5px;
  color: gray;
`;

export const NoRoomsContainer = Styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
`;

export const NoRoomText = Styled.span`
  font-size: 2rem;
  font-weight: bold;
`;

export const ReturnHomeButton = Styled.div`
  margin-top: 5px;
  padding: 15px;
  color: black;
  background: white;
  transition: ${(props) => props.theme.custom.transition};
  border-radius: 50%;
  cursor: pointer;
  display: flex;

  &:hover {
    transform: scale(1.1);
  }
`;

export const RoomsLoadingContainer = Styled.div`
  height: 80px;
`;

export const HomeIcon = Styled(FaHome)`
  font-size: 1.5rem;
`;
