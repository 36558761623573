import Styled from 'styled-components';

export const Container = Styled.div`
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
`;

export const Inner = Styled.div`

`;
