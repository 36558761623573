import React from 'react';
import {
  Container, MembersContainer,
} from './components';
import Member from './components/member';
import { useUserInfoContext } from '../../../../../../contexts/userInfoContext';
// import { PubSubEvent, subscribe } from '../../../../hook/pubSub';
import { useMembers } from '../../../../context/members.context';
import RoomMemberRoleType from '../../../../../../types/roomMemberRoleType';

function Members({
  roomId,
}: {
  readonly roomId: string;
}) {
  const {
    members, getMemberByUserId,
  } = useMembers();
  const { userInfo }: any = useUserInfoContext();

  const currentMember = getMemberByUserId(userInfo?.id);

  return (
    <Container>
      <MembersContainer className="scroll">
        {
          members.map((member) => (
            <Member
              key={member.id}
              memberId={member.id}
              roomId={roomId}
              name={member.user.username ?? member.user.nickname ?? ''}
              role={member.role}
              disable={
                userInfo.id === member.user.id
                 || (currentMember.role !== RoomMemberRoleType.ADMIN
                   && currentMember.role !== RoomMemberRoleType.CREATOR)
                 || member.role === RoomMemberRoleType.CREATOR
                }
            />
          ))
        }
      </MembersContainer>
    </Container>
  );
}

export default Members;
