import React, { useEffect } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import RoomsPageMobile from './mobile';
import RoomsPageDesktop from './desktop';

function RoomsPage() {
  useEffect(() => {
    document.title = 'Rooms';
  }, []);

  return (
    <>
      <MobileView renderWithFragment>
        <RoomsPageMobile />
      </MobileView>
      <BrowserView renderWithFragment>
        <RoomsPageDesktop />
      </BrowserView>
    </>
  );
}

export default RoomsPage;
