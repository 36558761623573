import Styled from 'styled-components';
import { IoMdClose } from 'react-icons/io';
import { MdOutlineEdit } from 'react-icons/md';
import { TiTick } from 'react-icons/ti';

export const Container = Styled.div`
  cursor: pointer;
`;

export const ContainerInner = Styled.div<{$editMode: boolean}>`
  display: flex;
  padding: 15px 5px 15px 10px;
  align-items: center;  
  border-radius: 5px;
  background: ${(props) => (props.$editMode ? '#00000057' : 'inherit')};
  transition: ${(props) => props.theme.custom.transition};
`;

export const Input = Styled.input`
  flex: 1;
  background: transparent;
  outline: none;
  border: none;
  font-size: 1rem;
  color: white;
  cursor: pointer;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

export const ApplyContainer = Styled.div`
  font-size: 1.7rem;
  display: flex;
`;

export const CloseContainer = Styled.div`
  font-size: 1.7rem;
  display: flex;
`;

export const EditContainer = Styled.div`
  font-size: 1.7rem;
  display: flex;
`;

export const LoadinContainer = Styled.div`
  font-size: 1rem;
`;

export const IconContainer = Styled.div`
  padding-right: 15px;
`;

export const CloseIcon = Styled(IoMdClose)`
`;

export const ApplyIcon = Styled(TiTick)`
`;

export const EditIcon = Styled(MdOutlineEdit)`
`;

export const TitleContainer = Styled.div`
  margin: 15px 0 5px 0;
  display: flex;
`;
