import React from 'react';
import * as c from './components';

export default function MemberCard({
  name,
  onClick,
}: {
  readonly name: string,
  readonly onClick: any,
}) {
  return (
    <c.Container onClick={onClick}>{name}</c.Container>
  );
}
