import Styled from 'styled-components';
import { BiFullscreen, BiExitFullscreen } from 'react-icons/bi';
import { TbPictureInPictureOn, TbPictureInPictureOff } from 'react-icons/tb';
import { PiUserCircleThin } from 'react-icons/pi';
import { MdDelete } from 'react-icons/md';
import { Button } from '@mui/material';

export const Container = Styled.div<{$width: number; $height: number; $margin: number}>`
  position: relative;
  border-radius: 10px;
  border: 1px solid gray;
  background-color: #8080804b;
  overflow: hidden;
  width: ${(props) => props.$width}px;
  height: ${(props) => props.$height}px;
  margin: ${(props) => props.$margin}px;
  justify-content: center;
  align-items: center;
  display: flex;
`;

export const Video = Styled.video<{ $mirror: boolean }>`
  position: absolute;
  right: 0;
  object-fit: cover;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  border-radius: 10px;
  overflow: hidden;
  left: 0;
  top: 0;
  background-size: cover;
  transform: scaleX(${(props) => (props.$mirror ? -1 : 1)});

  video::-webkit-media-controls-panel {
    transform: scaleX(${(props) => (props.$mirror ? -1 : 1)});
  }
`;

export const Audio = Styled.audio`
  width: 100%;
`;

export const InfoContainer = Styled.div`
  text-align: center;
  z-index: 2;
  color: white;
  display: inline-block;

`;

export const InfoTextContainer = Styled.div`

`;

export const InfoImageContainer = Styled.div`
`;

export const UserIcon = Styled(PiUserCircleThin)`
  font-size: 7rem;
  color: #b1b1b1;
`;

export const FullscreenContainer = Styled.div`
  position: absolute;
  left: 10px;
  bottom: 0;
  color: #ffffff;
  cursor: pointer;
  font-size: 2rem;
`;

export const FullscreenIcon = Styled(BiFullscreen)``;
export const FullscreenExitIcon = Styled(BiExitFullscreen)``;

export const PIPContainer = Styled.div`
  position: absolute;
  left: 50px;
  bottom: 0;
  color: #ffffff;
  cursor: pointer;
  font-size: 2rem;
`;

export const PIPIcon = Styled(TbPictureInPictureOn)``;
export const PIPExitIcon = Styled(TbPictureInPictureOff)``;

export const UserImage = Styled.img`
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
  max-width: 80px;
`;

export const VoiceLevelContainer = Styled.div`
  width: 30px;
  height: 30px;
  background-color: blue;
  border-radius: 50%;
  display:flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 10px;
  top: 10px;
`;

export const VoiceLevelItem = Styled.div<{$maxHeight: number}>`
  width: 5px;
  height: 5px;
  background-color: white;
  border-radius: 3px;
  margin: 0 2px;
  transition: 0.1s;

  &.voice {
    height: ${(props) => props.$maxHeight}px;
  }
`;

export const RemoveButton = Styled(Button)`
  border-radius: 50%;
  padding: 5px;
  min-width: inherit;
  display: flex;
  position: absolute;
  right: 5px;
  bottom: 5px;
  background: white;
  transition: ${(props) => props.theme.custom.transition};
  z-index: 3;

  &:hover {
    background: white;
    transform: scale(1.1);
  }
`;

export const RemoveIcon = Styled(MdDelete)<{$disabled: boolean}>`
  font-size: 1.6rem;
  color: ${(props) => (props.$disabled ? 'gray' : props.theme.custom.redDark)};
  display: flex;

  &:hover {
    color: ${(props) => props.theme.custom.redLight};
  }
`;

export const UsernameContainer = Styled.div`
  margin-top: 5px;
`;

export const LoadingContainer = Styled.div`
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #262626ab;
  z-index: 2;
`;
