import React from 'react';
import Styled from 'styled-components';

export const Start = Styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    overflow: hidden;
    padding: 10px;
    width:100%;
    height:100%;
    max-width: 40px;
    max-height: 40px;

    @keyframes animate {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    &::before {
      content: '';
      width: 50%;
      height: 200%;
      position: absolute;
      background: linear-gradient(#939393, #939393);
      animation: animate 2.5s linear infinite;
      animation-play-state: running;
    }


    &::after {
      content: '';
      position: absolute;
      inset: 3px;
      border-radius: 50%;
      background: #393636;
      transform: scaleX(1.04);
      transition: ${(props) => props.theme.custom.transition};
    }
`;

export const Micon = Styled.div`
  border-radius: 50%;
  padding: 5px;
  background:#ffffffab;
  color: black;
  font-size: 0.6rem;
  z-index: 10;
  line-height: 0.8;
`;
function Loading1() {
  return (
    <Start>
      <Micon>M</Micon>
    </Start>
  );
}

export default Loading1;
