import Styled from 'styled-components';
import { RxExit } from 'react-icons/rx';

export const Container = Styled.div`
  display: flex;
  padding: 15px 0;
  justify-content: center;
`;

export const IconContainer = Styled.div<{$disabled: boolean}>`
  display: flex;
  cursor: pointer;
  padding: 15px;
  pointer-events: ${(props) => (props.$disabled ? 'none' : 'inherit')};
  opacity: ${(props) => (props.$disabled ? 0.5 : 1)};
  color: ${(props) => props.theme.custom.redDark};
  border-radius: 50%;
  background: white;
  transition: ${(props) => props.theme.custom.transition};

  &:hover {
    transform: scale(1.1);
    background: ${(props) => props.theme.custom.redLight};
    color: white;
  }
`;

export const Icon = Styled(RxExit)`
  font-size: 1.6rem;
`;
