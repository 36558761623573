import Styled from 'styled-components';
import { CiKeyboard, CiLogin } from 'react-icons/ci';
import { GrConnect } from 'react-icons/gr';
import { IoMdAdd } from 'react-icons/io';

export const ContainerStyled = Styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  background: ${(props) => props.theme.custom.bodyBackground};
`;

export const BodyContainer = Styled.div`
  flex: 1;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const JoinInfoContainer = Styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
  border-bottom: 1px solid white;
  margin: 5px 0;
`;

export const FooterContainer = Styled.div`
  background: ${(props) => props.theme.palette.primary['500']};
`;

// export const CameraIcon = Styled(HiMiniPlayCircle)`
//   font-size: 3rem;
//   z-index: 10;
//   color: #ffffffab;
// `;

export const KeyboardIcon = Styled(CiKeyboard)`
  font-size: 2.5rem;
  margin: 0 5px 0 0;
  fill: white;
`;

export const JoinInputContainer = Styled.div`
  display: flex;
  opacity: 0.5;
  padding: 10px 5px 5px 5px;
`;

export const JoinInput = Styled.input`
  border: none;
  outline: none;
  background: transparent;
  color: white;
  width: 200px;
  text-transform: lowercase;

  &::placeholder {
  color: #ffffffd6;
  font-size: 1.2em;
  }

  @media (min-width: 768px) {
    width: 300px;
  }
`;

// export const JoinTextContainer = Styled(Button)`
//   padding: 0 10px;
//   font-size: 1.8rem;
// `;

export const JoinButtonContainer = Styled.div<{$active: boolean}>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  margin: 5px;
  border-radius: 50%;
  cursor: pointer;
  font-size: 1.3rem;
  transition: ${(props) => props.theme.custom.transition};
  pointer-events: ${(props) => (props.$active ? 'all' : 'none')};
  opacity: ${(props) => (props.$active ? '1' : '0.5 ')};
  background: ${(props) => (props.$active ? 'white' : 'transparent')};
  color: ${(props) => (props.$active ? 'black' : 'white')};

  &:hover {
    transform: scale(1.1);
    /* background: white;
    color: black */
  }
`;

export const JoinButton = Styled(GrConnect)``;

export const JoinTextContainer = Styled(GrConnect)<{$active: boolean}>`
  padding: 10px;
  border-radius: 50%;
  color: ${(props) => (props.$active ? '#1aa011' : 'white')};
  pointer-events: ${(props) => (props.$active ? 'all' : 'none')};
  opacity: ${(props) => (props.$active ? '1' : '0.5 ')};
  cursor: pointer;
  font-size: 1.4rem;
  transition: ${(props) => props.theme.custom.transition};

  &:hover {
    color: #1aa810;
    transform: scale(1.1);
  }
`;

export const AuthContainerStyled = Styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  padding: 15px 45px;
  min-width: 180px;

  :hover {
    background-color: rgba(145, 158, 171, 0.08);
  }
`;

export const LoginIcon = Styled(CiLogin)`
  font-size: 1.5rem;
  font-weight: 400;
`;

export const AuthTextStyled = Styled.p`
  font-size: 1.25em;
  font-weight: bold;
  margin: 0 15px;
`;

export const StartContainer = Styled.div`
`;

export const Start = Styled.div<{$active: boolean}>`
    z-index:1;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    overflow: hidden;
    padding: 0px 0 40px 0;
    /* cursor: pointer; */
    transition: ${(props) => props.theme.custom.transition};
    /* transform: ${(props) => (!props.$active ? 'scale(0.8)' : 'scale(1)')}; */


    @keyframes animate {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    &::before {
      content: '';
      width: 50%;
      height: 200%;
      position: absolute;
      background: linear-gradient(#939393, #939393);
      animation: animate 4.5s linear infinite;
      animation-play-state: ${(props) => (props.$active ? 'running' : 'paused')};
    }


    &::after {
      content: '';
      position: absolute;
      inset: 3px;
      border-radius: 50%;
      background: #212121;
      transform: scaleX(1.04);
      transition: ${(props) => props.theme.custom.transition};
    }

    /* &:hover {
      transform: scale(1.2);
      &::before {
        background: ${(props) => (props.$active ? 'linear-gradient(white, white)' : 'none')};
      }
      &::after {
        background: #181818;
        animation-play-state: pause;
      }
    } */
`;

export const Micon = Styled.div`
  border-radius: 50%;
  padding: 15px;
  background:white;
  color: black;
  font-size: 1.2rem;
  z-index: 10;
  line-height: 0.8;
`;

export const InfosContainer = Styled.div`
  width: 350px;
  height: 250px;
  /* background: #2f2f2f; */
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10;
  position: relative;

  @media(min-width : 768px) {
    width: 400px;
    height: 300px;
  }
`;

export const AddIconContainer = Styled.div<{$visible: boolean}>`
  position: absolute;
  bottom: -10px;
  background: white;
  display: flex;
  padding: 10px;
  border-radius: 50%;
  color: black;
  cursor: pointer;
  transition: ${(props) => props.theme.custom.transition};
  opacity: ${(props) => (props.$visible ? 1 : 0)};
  pointer-events: ${(props) => (props.$visible ? 'all' : 'none')};

  &:hover {
    transform: scale(1.1);
  }
`;

export const OrSeperate = Styled.div<{$visible: boolean}>`
  position: absolute;
  color: gray;
  bottom: 60px;
  opacity: ${(props) => (props.$visible ? 1 : 0)};
  transition: ${(props) => props.theme.custom.transition};
  font-size: small;
`;

export const AddIcon = Styled(IoMdAdd)`
  font-size: 1.5rem;
`;
