import Styled from 'styled-components';

export const Container = Styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const MembersContainer = Styled.div`
  padding: 0 15px;
  overflow-y: auto;
`;
