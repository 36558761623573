import Styled from 'styled-components';
import { BiMicrophone, BiMicrophoneOff } from 'react-icons/bi';
import { IoMdCall } from 'react-icons/io';
import { RxShare2 } from 'react-icons/rx';
import { FaUserPlus } from 'react-icons/fa';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { BsCameraVideo, BsCameraVideoOff } from 'react-icons/bs';
import { FiMoreHorizontal } from 'react-icons/fi';

export const Container = Styled.div`
  width: 100%;
  height: 100%;
  background: black;
  z-index: 1200;
`;

export const ContainerInner = Styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const TopContainer = Styled.div`
  flex: auto;
  display: flex;
  flex-direction: row-reverse;
  position: relative;
  direction: ltr;
  overflow: hidden;
`;

export const ViewContainer = Styled.div`
    display: flex;
    flex: 1;
    border-radius: 10px;
    gap: 20px;
    max-height: 100%;
    max-width: 100%;
`;

export const LargPlayContainer = Styled.div`
  

`;

export const BottomContainer = Styled.div`
  display: flex;
  justify-content: center;
  padding: 15px 0;
  overflow: auto;
  flex-flow: wrap;
  flex-direction: row-reverse;
  justify-content: space-between;
  flex-direction: row-reverse;

  /* @media(min-width: 768px) {
    justify-content: space-between;
    flex-direction: row-reverse;
  } */
`;

export const BottomCenterContainer = Styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

export const BottomRightContainer = Styled.div`
  display: flex;
`;

export const BottomLeftContainer = Styled.div`
  display: flex;
  color: white;
  padding: 0 5px;
  align-items: center;
  
  @media(min-width: 768px) {
    padding: 0 25px;
  }
`;

export const CallIcon = Styled(IoMdCall)`
  color: ${(props) => props.theme.custom.redLight};
`;

export const InfoIcon = Styled(AiOutlineInfoCircle)`
  font-size: 2.5rem;
  cursor: pointer;
  transition: ${(props) => props.theme.custom.transition};

  &:hover {
    transform: scale(1.1);
  }
`;

export const MicEnableIcon = Styled(BiMicrophone)`
`;

export const MicDisableIcon = Styled(BiMicrophoneOff)`
`;

export const CamEnableIcon = Styled(BsCameraVideo)`
`;

export const CamDisableIcon = Styled(BsCameraVideoOff)`
`;

export const MembersEnableIcon = Styled(FaUserPlus)``;

export const MembersDisableIcon = Styled(FaUserPlus)`
`;

export const MoreIcon = Styled(FiMoreHorizontal)`
  
`;

export const NewMessageIcon = Styled.div`
  position: absolute;
  right: -2px;
  top: -2px;
  width: 15px;
  height: 15px;
  background: #00d100;
  border-radius: 50%;
`;

export const ShareScreenIcon = Styled(RxShare2)``;

export const IconContainer = Styled.div<{$enable: boolean}>`
  border-radius: 50%;
  margin: 0 10px;
  background: white;
  display: flex;
  padding: 8px;
  color:  black;
  cursor: pointer;
  transition: ${(props) => props.theme.custom.transition};
  font-size: 1.5rem;
  position: relative;

  &:hover {
    transform: scale(1.1);
    /* background: ${(props) => (props.$enable ? props.theme.custom.azureLight : props.theme.custom.azureLight)};
    color:  white; */
  }
`;
