import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import * as c from './components';
import ConferenceApi from '../../../services/gateway/conferenceApi';
import ConferenceRoomDTO from '../../../dto/conferenceRoom.dto';
import RoomCard from './components/roomCard';
import WebsocketHandler from '../../../socket/websocket';
import ChatModal from './components/chatModal';
import Loading1 from '../../../components/loadings/loading1';
import Header from '../../../components/layout/header';
import Routes from '../../../commons/routs';

function RoomsPageMobile() {
  const [rooms, setRooms] = useState<ConferenceRoomDTO[]|null>(null);
  const navigate = useNavigate();
  const [selectedRoom, setSelectedRoom] = useState<ConferenceRoomDTO|null>(null);
  const [hasMoreRooms, setHasMoreRooms] = useState(false);
  const loadSize = 20;
  const observerTarget = useRef(null);

  const getRooms = async () => {
    try {
      const { rooms: rms } = await ConferenceApi.getRooms({
        size: loadSize,
        offset: rooms?.length ?? 0,
      });

      const list = rooms || [];
      setRooms([...list, ...rms]);

      setHasMoreRooms(rms.length === loadSize);
    } catch (e) {
      console.log('exp_getRooms', e);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          getRooms();
        }
      },
      { threshold: 1 },
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [rooms?.length]);

  useEffect(() => {
    getRooms();
  }, []);

  const joinConference = async (roomId: string) => {
    try {
      const peerData = WebsocketHandler.getPeerData();

      const {
        id, rtcConfiguration,
      } = await ConferenceApi.joinRoom({
        peerId: peerData.peerId,
        roomId,
      });
      console.log('aaaaaaaaaaaaaaaaaaaa');
      navigate(`/rooms/${roomId}/joins/${id}`, {
        state: { rtcConfiguration },
      });
    } catch (e: any) {
      toast.error(e.message);
    }
  };

  let bodyContent;

  if (rooms === null) {
    bodyContent = <c.NoRoomsContainer><Loading1 /></c.NoRoomsContainer>;
  } else if (rooms.length === 0) {
    bodyContent = (
      <c.NoRoomsContainer>
        <c.NoRoomText>there is no meeting!</c.NoRoomText>
        <c.ReturnHomeButton
          onClick={() => {
            navigate(Routes.HOME.path);
          }}
        >
          <c.HomeIcon />
        </c.ReturnHomeButton>
      </c.NoRoomsContainer>
    );
  } else {
    bodyContent = (
      <c.RoomCardsContainer>
        {
          rooms.map((room) => {
            const name = room.name ?? 'No name';
            const time = moment(room.createdAt).fromNow();
            return (
              <RoomCard
                key={room.id}
                name={name}
                time={time}
                onClickJoin={async () => {
                  await joinConference(room.id);
                }}
                onClick={() => {
                  setSelectedRoom(room);
                }}
              />
            );
          })
        }
        {
          hasMoreRooms
          && (
          <c.RoomsLoadingContainer ref={observerTarget}>
            <Loading1 />
          </c.RoomsLoadingContainer>
          )
        }
      </c.RoomCardsContainer>
    );
  }

  return (
    <c.ContainerStyled>
      <Header />
      <c.BodyContainer>
        {bodyContent}
      </c.BodyContainer>
      { selectedRoom?.threadId && (
        <ChatModal
          room={selectedRoom}
          onClose={() => {
            setSelectedRoom(null);
          }}
          onLeave={() => {
            if (!rooms) return;
            const rms = rooms.filter(
              (room) => room.id !== selectedRoom.id,
            );
            setRooms(rms);
          }}
        />
      )}
    </c.ContainerStyled>
  );
}

export default RoomsPageMobile;
