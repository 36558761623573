import Url from './url';
import { format } from '../../commons/helper';
import ThreadMessage from '../../dto/threadMessage.dto';
import Thread from '../../dto/thread.dto';
import ThreadType from '../../types/threadType';
import ThreadStatus from '../../types/threadStatus';
import RequestHandler from './requestHandler';

class ChatApi {
  private static async request({
    method, url, data, form, headers,
  }:{method:'GET'|'POST'|'DELETE'|'PUT', url: string, data?: any, form?: any, headers: any})
  :Promise<any> {
    const res = RequestHandler.request({
      method, url, data, form, headers,
    });
    return res;
  }

  static async addThread({
    messageConfirmType,
    type,
    status,
    title,
    appId,
    metadata,
    participantUserIds,
    participantUserId,
    readAccessType,
    writeAccessType,
    admiUserIds,
  }: {
    type: number;
    participantUserIds?: any[];
    participantUserId?: string;
    title?: string;
    status?: number;
    appId?: string;
    metadata?: any;
    messageConfirmType?: number;
    readAccessType?: number;
    writeAccessType?: number;
    admiUserIds?: string[];
  })
  :Promise<{ thread: Thread}> {
    const res = await this.request({
      method: 'POST',
      url: Url.CHAT_THREADS,
      data: {
        messageConfirmType,
        type,
        status,
        title,
        appId,
        metadata,
        participantUserIds,
        participantUserId,
        readAccessType,
        writeAccessType,
        admiUserIds,
      },
      headers: {},
    });
    return res;
  }

  static async addThreadMessage({
    threadId,
    messageId,
    text,
  }: {
    threadId: string;
    messageId?: string;
    text: string;
  })
  :Promise<{message: ThreadMessage}> {
    let url = `${format(Url.CHAT_THREADS_MESSAGES, threadId)}`;

    if (messageId) {
      url += `/${messageId}`;
    }

    const res = await this.request({
      method: 'POST',
      url,
      data: {
        text,
      },
      headers: {},
    });
    return res;
  }

  static async getThreadMessages({
    threadId,
    size,
    offset,
    treeMode,
  }: {
    threadId: string;
    size?: number;
    offset?: number;
    treeMode?: boolean;
  })
  :Promise<{messages: ThreadMessage[]}> {
    const res = await this.request({
      method: 'GET',
      url: `${format(Url.CHAT_THREADS_MESSAGES, threadId)}`,
      data: {
        size,
        offset,
        treeMode,
      },
      headers: {},
    });
    return res;
  }

  static async getThreads(
    {
      size,
      offset,
      type,
      status,
      title,
      appId,
      metadata,
    }:
    {
      size: number;
      offset: number;
      type?: ThreadType | ThreadType[];
      status?: ThreadStatus | ThreadStatus[];
      title?: string;
      appId?: string;
      metadata?: any;
     },
  )
  :Promise<{threads: Thread[]}> {
    const res = await this.request({
      method: 'GET',
      url: Url.CHAT_THREADS,
      data: {
        size,
        offset,
        type,
        status,
        title,
        appId,
        metadata,
      },
      headers: {},
    });
    return res;
  }

  static async getThread(
    {
      threadId,
    }:
    {
      threadId: string;
     },
  )
  :Promise<{thread: Thread}> {
    const res = await this.request({
      method: 'GET',
      url: `${format(Url.CHAT_THREAD, threadId)}`,
      data: {
        threadId,
      },
      headers: {},
    });
    return res;
  }
}

export default ChatApi;
