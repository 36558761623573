import Styled from 'styled-components';

export const Container = Styled.div`
  display: flex;
`;

export const CodeContainer = Styled.div`
  display: flex;
  align-items: center;
  /* background: gray; */
  padding: 5px;
  opacity: 0.5;
  margin-right: 5px;
`;

export const NumbersContainer = Styled.div`
  display: flex;
  align-items: center;
`;

export const NumberContainer = Styled.input`
  padding: 2px;
  font-size: 1.5rem;
  border: none;
  border-bottom: 1px solid gray;
  margin: 0 5px;
  cursor: pointer;
  min-width: 15px;
  max-width: 15px;
  outline: none;
  background: transparent;
  color: white;
  transition: ${(props) => props.theme.custom.transition};

  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:focus {
    transform: scale(1.3)
  }
`;

export const NumberParentContainer = Styled.div`
  display: flex;
  align-items: center;
`;

export const Dash = Styled.div`
  border: 1px solid gray;
  width: 8px;
`;
