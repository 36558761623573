import Styled from 'styled-components';
import LoadingButton from '@mui/lab/LoadingButton';
import { VscClose } from 'react-icons/vsc';
import { CiEdit } from 'react-icons/ci';
import { BsArrowRepeat } from 'react-icons/bs';

export const Image = Styled.img`
  margin-top: 30px;
  max-width: 200px;
  max-width: 200px;
`;

export const TitleText = Styled.p`
  font-weight:bold;
  font-size: 1.5em;
  margin: 30px 0 0 0;
`;

export const DescriptionText = Styled.p`
  font-size: 0.875em;
  margin-top: 15px 0 0 0;
`;

export const CodeInputContainer = Styled.div<{disabled: boolean}>`
  pointer-events: ${(props) => (props.disabled ? 'none' : 'all')};
  margin-top: 60px;
  width: 95%;
  display: flex;
  justify-content: center;
`;

export const CodeInput = Styled.input<{'data-index': number}>`
  padding: 12px;
  margin: auto;
  border-radius: 10px;
  border: none;
  border-bottom: 1px solid gray;
  font-size: 1em;
  width: 25px;
  text-align: center;
  outline: none;
  background: #353535;
  color: white;
  transition: ${(props) => props.theme.custom.transition};
  -moz-appearance: textfield;
  
  &:focus {
    transform: scale(1.3)
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const ButtonContainer = Styled.div`
  margin-top: 45px;
  width: 80%;
  text-align: center;
`;

export const SendButton = Styled(LoadingButton)`
  width:100%;
  background: white;
`;

export const BottomContainer = Styled.div`
  margin: 20px 0 70px 0;
  display: flex;
  justify-content: space-between;
  width: 80%;
`;

export const EditPhoneText = Styled.div`
  cursor: pointer;
  padding: 15px;
  background: white;
  border-radius: 50%;
  transition: ${(props) => props.theme.custom.transition};
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 58px;
  height: 58px;
  box-sizing: border-box;

  &:hover {
    transform: scale(1.1);
  }
`;

export const HeaderContainer = Styled.div`
  display: flex;
  justify-content: end; 
  width: 100%;
`;

export const CloseIcon = Styled(VscClose)`
  font-size: 2rem;
  margin: 10px;
  cursor: pointer;
`;

export const EditIcon = Styled(CiEdit)`
  font-size: 1.5rem;
`;

export const Video = Styled.video`
  border-radius: 50%;
  margin-top: 15px;
  width: 150px;
  filter: grayscale(1);

  @media(min-width:768px) {
    margin-top: 30px;
    width: 200px
    height: 200px
  }
`;

export const TimeContainer = Styled.div`
  position: absolute;
  font-size: 0.7rem;
`;

export const ResendText = Styled.div<{disabled: boolean}>`
  cursor: pointer;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'all')};
  opacity: ${(props) => (props.disabled ? '0.7' : '1')};
  /* padding: 1px; */
  background: white;
  border-radius: 50%;
  transition: ${(props) => props.theme.custom.transition};
  color: black;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 58px;
  height: 58px;

  &:hover {
    transform: scale(1.1);
  }
`;

export const RetryIcon = Styled(BsArrowRepeat)`
  font-size: 4rem;
`;
