import Styled from 'styled-components';
import { FaHome } from 'react-icons/fa';

export const ContainerStyled = Styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
`;

export const BodyContainer = Styled.div`
  flex: 1;
  flex-direction: column;
  display: flex;
  align-items: center;
  overflow: hidden;
  background: ${(props) => props.theme.custom.bodyBackground};
`;

export const RoomCardsContainer = Styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: scroll;
`;

export const RoomsLoadingContainer = Styled.div`
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NoRoomsContainer = Styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
`;

export const NoRoomText = Styled.span`
  font-size: 1.8rem;
  font-weight: bold;
`;

export const ReturnHomeButton = Styled.div`
  margin-top: 5px;
  padding: 15px;
  color: black;
  background: white;
  transition: ${(props) => props.theme.custom.transition};
  border-radius: 50%;
  cursor: pointer;
  display: flex;

  &:hover {
    transform: scale(1.1);
  }
`;

export const HomeIcon = Styled(FaHome)`
  font-size: 1.5rem;
`;
