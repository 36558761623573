import React from 'react';
import LogoutPage from '../pages/logout';
import RejoinPage from '../pages/rejoin';
import HomePage from '../pages/home';
import ProfilePage from '../pages/profile';
import RoomsPage from '../pages/rooms';
import ContactusPage from '../pages/contactus';
import RoomPage from '../pages/room';

const Routes = {
  HOME: {
    path: '/',
    component: <HomePage />,
  },
  REJOIN_CODE: {
    path: '/code/:code',
    component: <RejoinPage />,
  },
  REJOIN_ID: {
    path: '/id/:id',
    component: <RejoinPage />,
  },
  LOGOUT: {
    path: '/logout',
    component: <LogoutPage />,
  },
  PROFILE: {
    path: '/profile',
    component: <ProfilePage />,
  },
  ROOMS: {
    path: '/rooms',
    component: <RoomsPage />,
  },
  ROOM: {
    path: '/rooms/:roomId/joins/:joinId',
    component: <RoomPage />,
  },
  Contactus: {
    path: '/contactus',
    component: <ContactusPage />,
  },
};

export default Routes;
