import Styled from 'styled-components';
import { GrConnect } from 'react-icons/gr';

export const Container = Styled.div`
  display: flex;
  flex: 1;
  border-bottom: 1px solid gray;
`;

export const ContainerInner = Styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  width: 100%;
  align-items: center;
`;

export const NameText = Styled.div`
  
`;

export const TimeText = Styled.div`
  font-size: 0.8rem;
  color: gray;
`;

export const JoinButton = Styled.div<{$enable: boolean}>`
  padding: 10px;
  border-radius: 50%;
  color: ${(props) => props.theme.custom.greenLight};
  cursor: pointer;
  font-size: 1.2rem;
  transition: ${(props) => props.theme.custom.transition};
  display: flex;
  background: white;
  pointer-events: ${(props) => (props.$enable ? 'all' : 'none')}; 
  opacity: ${(props) => (props.$enable ? 1 : 0.5)}; 

  &:hover {
    transform: scale(1.1);
  }
`;

export const JoinIcon = Styled(GrConnect)`
  
`;
