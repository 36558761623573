import React, {
  createContext, useContext, useEffect, useMemo, useState,
} from 'react';

export interface MemberInterface {
  id: string,
  user: {
    id: string,
    username?: string,
    nickname: string,
    imageUrl?: string,
  },
  joinsInfo: {
    id: string,
    createdAt: Date,
    peerConnection?: RTCPeerConnection|null,
    remoteStreams: MediaStream[],
    status: number,
  }[],
  role: number;
}

const membersContext = createContext< {
  members: MemberInterface[],
  setMembers: any,
  addMember: any,
  addMembers: any,
  // getCounter: any,
  getMember: any,
  getMemberByUserId: any,
  removeMember: any,
}>({
  members: [],
  setMembers: () => {},
  addMember: () => {},
  addMembers: () => {},
  // getCounter: () => {},
  getMember: () => {},
  getMemberByUserId: () => {},
  removeMember: () => {},
});

export function MembersProvider({ children }: any) {
  const [members, setMembers] = useState<MemberInterface[]>([]);
  // const [counter, setCounter] = useState(0);
  const [rerender, setRerender] = useState(false);

  // const updateStreamCount = (memberList: MemberInterface[]) => {
  /*
    in this section we use (joinInfo.remoteStreams.length + 1) unlike
    other place that we use joinInfo.remoteStreams.length || 1
    because it cause when new stream added, not new render accured
  */
  const streamCount = members.reduce(
    (count, member) => member.joinsInfo.reduce(
      (cnt, joinInfo) => cnt
          + (joinInfo.remoteStreams
            .reduce((num, stream) => num + stream.getTracks().length, cnt) + 1),
      count,
    ),
    0,
  );

  //   setCounter(streamCount);
  // };

  useEffect(() => {
    setRerender(!rerender);
  }, [streamCount]);

  const value = useMemo(
    () => ({
      members,
      setMembers: (list: MemberInterface[]) => {
        setMembers(list);
        // updateStreamCount(list);
        setRerender(!rerender);
      },
      addMember: (member: MemberInterface) => {
        const list = [...members, member];
        setMembers(list);
        // updateStreamCount(list);
        setRerender(!rerender);
      },
      addMembers: (memberList: MemberInterface[]) => {
        const list = [...members, ...memberList];
        setMembers(list);
        // updateStreamCount(list);
        setRerender(!rerender);
      },
      getMember: (id: string) => members.find((mem) => mem.id === id),
      getMemberByUserId: (userId: string) => members.find((mem) => mem.user.id === userId),
      removeMember: (memberId: string) => {
        const mems = members.filter((mem) => mem.id !== memberId);
        setMembers(mems);
        // updateStreamCount(mems);
        setRerender(!rerender);
      },
      // getCounter: () => counter,
    }),
    [rerender],
  );

  return (
    <membersContext.Provider value={value}>
      {children}
    </membersContext.Provider>
  );
}

export function useMembers() {
  return useContext(membersContext);
}
