import React, { useEffect } from 'react';
import { ContainerStyled } from './components';
import Loading1 from '../../components/loadings/loading1';

function LoadingPage() {
  useEffect(() => {
    document.title = 'Loading';
  }, []);

  return (
    <ContainerStyled>
      <Loading1 />
    </ContainerStyled>
  );
}

export default LoadingPage;
