import React, { useState } from 'react';
import {
  FormControl, MenuItem, Select, SelectChangeEvent,
} from '@mui/material';
import { toast } from 'react-toastify';
import {
  Container, ContainerInfo, Icon, SelectContainer, TitleText,
} from './components';
import ConferenceApi from '../../../../../../services/gateway/conferenceApi';
import RoomAccesType from '../../../../../../types/roomAccessType';

function AccessType({
  roomId,
  accessType,
  onChange,
}: {
  readonly roomId: string;
  readonly accessType: number;
  readonly onChange: (type: number) => void
}) {
  const [roomAccess, setRoomAccess] = useState(accessType);

  const handleChange = async (event: SelectChangeEvent) => {
    const at = Number(event.target.value);
    const prevAt = roomAccess;
    setRoomAccess(at);

    try {
      await ConferenceApi.editRoom({
        roomId,
        accessType: at,
      });
      onChange(at);
    } catch (e: any) {
      toast.error(e.message);
      setRoomAccess(prevAt);
    }
  };

  return (
    <Container>
      <Icon />
      <ContainerInfo>
        <TitleText>access type</TitleText>
        <SelectContainer>
          <FormControl size="small" sx={{ width: '70%' }}>
            <Select
              value={String(roomAccess)}
              onChange={handleChange}
            >
              <MenuItem selected value={RoomAccesType.MEMBER}>member</MenuItem>
              <MenuItem value={RoomAccesType.FREE}>free</MenuItem>
              <MenuItem value={RoomAccesType.WITH_REQUEST}>with request</MenuItem>
            </Select>
          </FormControl>
        </SelectContainer>
      </ContainerInfo>
    </Container>
  );
}

export default AccessType;
