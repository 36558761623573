import React, { useEffect } from 'react';
import { MobileView, BrowserView } from 'react-device-detect';
// import * as c from './components';
import { useLocation, useNavigate } from 'react-router-dom';
// import HomePageMobile from './mobile';
import HomePageDesktop from './desktop';

function HomePage() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const redirect = localStorage.getItem('redirectpath');
    if (redirect) {
      localStorage.removeItem('redirectpath');
      navigate(redirect);
    } else {
      const { search } = location;
      const queryParams = new URLSearchParams(search);
      const code = queryParams.get('code');
      if (code) {
        queryParams.delete('code');
        queryParams.delete('authenticator');
        queryParams.delete('scope');
        queryParams.delete('prompt');
        queryParams.delete('authuser');
        navigate({
          search: queryParams.toString(),
        }, {
          replace: true,
        });
      }
    }
  }, []);

  useEffect(() => {
    document.title = 'Meetali';
  }, []);

  return (
    <>
      <MobileView renderWithFragment>
        <HomePageDesktop />
      </MobileView>
      <BrowserView renderWithFragment>
        <HomePageDesktop />
      </BrowserView>
    </>
  );
}

export default HomePage;
