import { setCookie } from 'nookies';
import Config from './config';

/* eslint-disable no-mixed-operators */
export const isValidEmail = (email:string) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const isValidCellphoneNumner = (cellphoneNumner: string) => cellphoneNumner.length === 11;

export const dataURItoBlob = (dataURI:string) => {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  const byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ab], { type: mimeString });
};

export const generateUUID = () => {
  let d = new Date().getTime();
  const textData = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';
  return textData.replace(/[xy]/g, (c) => {
    // eslint-disable-next-line no-bitwise
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);

    // eslint-disable-next-line no-mixed-operators
    // eslint-disable-next-line no-bitwise
    return (c === 'x' ? r : (r & 0x7 | 0x8)).toString(16);
  });
};

export const getBrowserData = () => {
  const module = {
    options: [],
    header: [
      navigator.platform,
      navigator.userAgent,
      navigator.appVersion,
      navigator.vendor,
    ],
    dataos: [
      { name: 'Windows Phone', value: 'Windows Phone', version: 'OS' },
      { name: 'Windows', value: 'Win', version: 'NT' },
      { name: 'iPhone', value: 'iPhone', version: 'OS' },
      { name: 'iPad', value: 'iPad', version: 'OS' },
      { name: 'Kindle', value: 'Silk', version: 'Silk' },
      { name: 'Android', value: 'Android', version: 'Android' },
      { name: 'PlayBook', value: 'PlayBook', version: 'OS' },
      { name: 'BlackBerry', value: 'BlackBerry', version: '/' },
      { name: 'Macintosh', value: 'Mac', version: 'OS X' },
      { name: 'Linux', value: 'Linux', version: 'rv' },
      { name: 'Palm', value: 'Palm', version: 'PalmOS' },
    ],
    databrowser: [
      { name: 'Chrome', value: 'Chrome', version: 'Chrome' },
      { name: 'Firefox', value: 'Firefox', version: 'Firefox' },
      { name: 'Safari', value: 'Safari', version: 'Version' },
      { name: 'Internet Explorer', value: 'MSIE', version: 'MSIE' },
      { name: 'Opera', value: 'Opera', version: 'Opera' },
      { name: 'BlackBerry', value: 'CLDC', version: 'CLDC' },
      { name: 'Mozilla', value: 'Mozilla', version: 'Mozilla' },
    ],
    init() {
      const agent = this.header.join(' ');
      const os = this.matchItem(agent, this.dataos);
      const browser = this.matchItem(agent, this.databrowser);

      return { os, browser };
    },
    matchItem(string: string, data: any) {
      let i = 0;
      let j = 0;
      let regex;
      let regexv;
      let match;
      let matches:any;
      let version;

      for (i = 0; i < data.length; i += 1) {
        regex = new RegExp(data[i].value, 'i');
        match = regex.test(string);
        if (match) {
          regexv = new RegExp(`${data[i].version}[- /:;]([\\d._]+)`, 'i');
          matches = string.match(regexv);
          version = '';
          // eslint-disable-next-line prefer-destructuring
          if (matches) { if (matches[1]) { matches = matches[1]; } }
          if (matches) {
            matches = matches.split(/[._]+/);
            for (j = 0; j < matches.length; j += 1) {
              if (j === 0) {
                version += `${matches[j]}.`;
              } else {
                version += matches[j];
              }
            }
          } else {
            version = '0';
          }
          return {
            name: data[i].name,
            version: parseFloat(version),
          };
        }
      }
      return { name: 'unknown', version: 0 };
    },
  };

  const e = module.init();

  return {
    osName: e.os.name,
    osVersion: e.os.version,
    boewserName: e.browser.name,
    boewserVersion: `${e.browser.version}`,
    appVersion: navigator.appVersion,
    platform: navigator.platform,
    vendor: navigator.vendor,
  };
};

export const convertSecToMinSec = (sec: number) => ({
  minutes: Math.floor(sec / 60),
  seconds: sec - (Math.floor(sec / 60)) * 60,
});

export const userLoginAction = ({ token, reload }:any) => {
  // add user and token data to storage
  // localStorage.setItem('user', JSON.stringify(user));
  setCookie(null, 'token', token.accessToken, {
    secure: false,
    httpOnly: false,
    maxAge: Config.TOKEN_MAX_AGE_SEC,
  });
  if (reload) {
    window.location.reload();
  }
};

export const isValidUsername = (username: string) => {
  // eslint-disable-next-line no-useless-escape
  const reg: RegExp = /^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{4,29}$/;
  const res = reg.test(username);
  return res;
};

export const sleep = (delay = 0) => new Promise((resolve) => {
  setTimeout(resolve, delay);
});

export const format = (str:string, ...args: string[]) => {
  let newStr = str;
  let i = 0;
  while (/%s/.test(newStr)) {
    newStr = newStr.replace('%s', args[i]);
    i += 1;
  }
  return newStr;
};

export const activeFullscreen = (element: any) => {
  if (element.requestFullscreen) {
    element.requestFullscreen();
  } else if (element.mozRequestFullScreen) {
    element.mozRequestFullScreen();
  } else if (element.webkitRequestFullScreen) {
    element.webkitRequestFullScreen();
  } else if (element.msRequestFullscreen) {
    element.msRequestFullscreen();
  }
};

export const deactivateFullscreen = () => {
  const doc: any = document;
  if (doc.exitFullscreen) {
    doc.exitFullscreen();
  } else if (doc.webkitExitFullscreen) {
    doc.webkitExitFullscreen();
  } else if (doc.mozCancelFullScreen) {
    doc.mozCancelFullScreen();
  } else if (doc.msExitFullscreen) {
    doc.msExitFullscreen();
  }
};

export const activePictureInPicture = (element: HTMLVideoElement) => {
  const elm: any = element;
  if (elm.requestPictureInPicture) {
    elm.requestPictureInPicture();
  } else if (elm.webkitSupportsPresentationMode
      && elm.webkitSupportsPresentationMode('picture-in-picture')
      && typeof elm.webkitSetPresentationMode === 'function') {
    elm.webkitSetPresentationMode('picture-in-picture');
  }
};

export const deactivatePictureInPicture = (element?: HTMLVideoElement) => {
  const elm: any = element;
  if (document.exitPictureInPicture) {
    document.exitPictureInPicture();
  } else if (elm.webkitSupportsPresentationMode
    && elm.webkitSupportsPresentationMode('picture-in-picture')
    && typeof elm.webkitSetPresentationMode === 'function') {
    elm.webkitSetPresentationMode('inline');
  }
};

export const pictureInPictureEnabled = () => 'pictureInPictureEnabled' in document && document.pictureInPictureEnabled;

export const fullscreenEnabled = () => {
  const doc: any = document;
  return doc.fullscreenEnabled
  || doc.mozFullscreenEnabled
  || doc.webkitFullscreenEnabled
  || doc.msFullscreenEnabled;
};
