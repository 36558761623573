import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ConferenceApi from './services/gateway/conferenceApi';
import WebsocketHandler from './socket/websocket';
import ConferenceEventType from './types/conferenceEventType';
import { generateUUID } from './commons/helper';
import ConferenceInviteNotif from './components/notification/conferenceInvite';
import ConferenceRoomDTO from './dto/conferenceRoom.dto';
import { useConferenceInfo } from './components/conference/context/conferenceInfo.context';

function Main() {
  const navigate = useNavigate();
  const { isConferenceShow } = useConferenceInfo();

  const joinConference = async (roomId: string) => {
    try {
      const peerData = WebsocketHandler.getPeerData();

      const {
        id, rtcConfiguration,
      } = await ConferenceApi.joinRoom({
        peerId: peerData.peerId,
        roomId,
      });

      navigate(`/rooms/${roomId}/joins/${id}`, {
        state: { rtcConfiguration },
      });
    } catch (e: any) {
      toast.error(e.message);
    }
  };

  const onMessage = async ({ message }: any) => {
    const { serverAppId, content } = message;
    if (serverAppId === 'CONFERENCE') {
      switch (content.type) {
        case ConferenceEventType.INVITE: {
          if (isConferenceShow()) {
            return;
          }

          const { fromUser, room } = content.content;
          const toastId = generateUUID();
          toast(
            ConferenceInviteNotif({
              user: fromUser,
              onAccept: async () => {
                try {
                  const peerData = WebsocketHandler.getPeerData();

                  const { id, rtcConfiguration } = await ConferenceApi.joinRoom({
                    roomId: room.id,
                    peerId: peerData.peerId,
                  });

                  navigate(`/rooms/${room.id}/joins/${id}`, {
                    state: { rtcConfiguration },
                  });
                } catch (e: any) {
                  toast.error(e.message);
                }
                toast.dismiss(toastId);
              },
              onReject: async () => {
                try {
                  await ConferenceApi.reject({
                    roomId: room.id,
                  });
                } catch (e: any) {
                  toast.error(e.message);
                }
                toast.dismiss(toastId);
              },
            }),
            {
              position: 'bottom-left',
              hideProgressBar: true,
              closeOnClick: false,
              icon: false,
              toastId,
              autoClose: false,
              closeButton: false,
              style: {
                background: 'transparent',
                boxShadow: 'none',
              },
            },
          );
          break;
        }
        case ConferenceEventType.REQUEST_STATUS: {
          const { room, accepted }: {
            room : ConferenceRoomDTO, requestId? : string, accepted : boolean
          } = content.content;
          // console.log('request status', requestId, accepted, room);
          if (accepted) {
            await joinConference(room.id);
          } else {
            toast.error('request rejected');
          }
          break;
        }
        default: {
          // TODO:
        }
      }
    }
  };

  useEffect(() => {
    WebsocketHandler.addEventListener('message', onMessage);

    return () => {
      WebsocketHandler.removeEventListener('message', onMessage);
    };
  }, [onMessage]);

  return null;
}

export default Main;
