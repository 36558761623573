import Styled from 'styled-components';
import { SlDislike, SlLike } from 'react-icons/sl';

export const Container = Styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
`;

export const ContainerInner = Styled.div<{ $marginTop: number }>`
  pointer-events: all;
  border-radius: 50%;
  background: #161616;
  width: 300px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: ${(props) => props.$marginTop}px;
  /* overflow: hidden; */
`;
export const TitleText = Styled.div`
  text-align: center;
`;

export const DescriptionText = Styled.div`
    white-space: nowrap;
    text-align: center;
`;

export const AcceptContainer = Styled.div`
  background: white;
  /* border: 1px solid #098809; */
  color: ${(props) => props.theme.custom.greenLight};
  transition: ${(props) => props.theme.custom.transition};
  padding: 15px;
  margin: 5px;
  border-radius: 50%;
  display: flex;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
    background: ${(props) => props.theme.custom.greenLight};
    color: white;
  }
`;

export const AcceptIcon = Styled(SlLike)`
  font-size: 1.2rem;
`;

export const RejectContainer = Styled.div`
  background: white;
  /* border: 1px solid #ad0000; */
  color: ${(props) => props.theme.custom.redLight};
  transition: ${(props) => props.theme.custom.transition};
  padding: 15px;
  margin: 5px;
  border-radius: 50%;
  display: flex;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
    background: ${(props) => props.theme.custom.redLight};
    color: white;
  }
`;

export const RejectIcon = Styled(SlDislike)`
  font-size: 1.2rem;
`;

export const ButtonsContainer = Styled.div`
    display: flex;
    justify-content: center;
    gap: 20px;
`;
