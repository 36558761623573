import Styled from 'styled-components';
import { MdOutlineMailOutline } from 'react-icons/md';
import { FaLinkedinIn, FaPhone } from 'react-icons/fa6';
import { FaTelegramPlane, FaWhatsapp } from 'react-icons/fa';

export const ContainerStyled = Styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  background: ${(props) => props.theme.custom.bodyBackground};
`;

export const BodyContainer = Styled.div`
  flex: 1;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FooterContainer = Styled.div`
  background: ${(props) => props.theme.palette.primary['500']};
`;

export const InfosContainer = Styled.div`
  width: 250px;
  height: 250px;
  /* background: #2f2f2f; */
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  position: relative;

  @media(min-width : 768px) {
    width: 300px;
    height: 300px;
  }
`;

export const InfoContainer = Styled.div`
  display: flex;
  margin: 10px 0;
`;

export const SocialsContainer = Styled.div`
  display: flex;
  gap: 15px;
  justify-content: center;
`;

export const IconContainer = Styled.a`
  display: flex;
  padding: 15px;
  color: black;
  background-color: white;
  border-radius: 50%;
  cursor: pointer;
  transition: ${(props) => props.theme.custom.transition};

  &:hover {
    transform: scale(1.1);
  }
`;

export const LinkdinIcon = Styled(FaLinkedinIn)`
  font-size: 1.6rem;
`;

export const PhoneIcon = Styled(FaPhone)`
  font-size: 1.6rem;
`;

export const EmailIcon = Styled(MdOutlineMailOutline)`
  font-size: 1.6rem;
`;

export const WhatsappIcon = Styled(FaWhatsapp)`
  font-size: 1.6rem;
`;

export const TelegramIcon = Styled(FaTelegramPlane)`
  font-size: 1.6rem;
`;

export const Start = Styled.div<{$active: boolean}>`
    z-index:1;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    overflow: hidden;
    padding: 40px;
    cursor: pointer;
    transition: ${(props) => props.theme.custom.transition};
    transform: ${(props) => (!props.$active ? 'scale(0.8)' : 'scale(1)')};


    @keyframes animate {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    &::before {
      content: '';
      width: 50%;
      height: 200%;
      position: absolute;
      background: linear-gradient(#939393, #939393);
      animation: animate 4.5s linear infinite;
      animation-play-state: ${(props) => (props.$active ? 'running' : 'paused')};
    }


    &::after {
      content: '';
      position: absolute;
      inset: 3px;
      border-radius: 50%;
      background: #393636;
      transform: scaleX(1.04);
      transition: ${(props) => props.theme.custom.transition};
    }

    &:hover {
      transform: scale(1.2);
      &::before {
        background: ${(props) => (props.$active ? 'linear-gradient(white, white)' : 'none')};
      }
      &::after {
        background: #181818;
      }
    }
`;
