import Styled from 'styled-components';
import { Link } from 'react-router-dom';
import { PiUsersThreeBold } from 'react-icons/pi';
import { RiLogoutBoxRLine } from 'react-icons/ri';
import { LuContact } from 'react-icons/lu';
import { FaHome } from 'react-icons/fa';

export const ContainerStyled = Styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
`;

export const BodyContainer = Styled.div`
  flex: 1;
  flex-direction: column;
  display: flex;
  align-items: center;
  overflow: hidden;
  background: ${(props) => props.theme.custom.bodyBackground};
`;

export const ItemsContainer = Styled.div`
  margin-top: 64px;
  width: 70%;
`;

export const ItemContainer = Styled.div`
  padding: 10px 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: white;
  transition: ${(props) => props.theme.custom.transition};

  &:hover {
    background: #8080801a;
    transform: scale(1.05);
  }
`;

export const ItemIconContainer = Styled.div`
  background: white;
  display: flex;
  padding: 10px;
  border-radius: 50%;
  color: black;
  cursor: pointer;
  transition: ${(props) => props.theme.custom.transition};
`;

export const ItemTextContainer = Styled.div`
  margin-left: 15px;
`;

export const ItemDivider = Styled.div`
  border-top: 1px solid #bbbbbb87;
  margin: 5px 0;
  width: 100%;
`;

export const ILink = Styled(Link)`
  text-decoration: none;
`;

export const UsersIcon = Styled(PiUsersThreeBold)`
  font-size: 1.5rem;
`;

export const HomeIcon = Styled(FaHome)`
  font-size: 1.5rem;
`;

export const ContactusIcon = Styled(LuContact)`
  font-size: 1.5rem;
`;

export const LogoutIcon = Styled(RiLogoutBoxRLine)`
  font-size: 1.5rem;
`;
