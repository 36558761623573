import React from 'react';
import {
  AcceptContainer, AcceptIcon, ButtonsContainer,
  Conianer, ContainerInner, ImageContainer,
  RejectContainer, RejectIcon, UserIcon, UserImage,
  UsernameContainer,
} from './components';

function ConferenceInviteNotif({
  user,
  onAccept,
  onReject,
}: {
  readonly user: {
    id: string;
    username: string;
    nickname: string;
    imageUrl?: string;
  };
  readonly onAccept: any;
  readonly onReject: any;
}) {
  return (
    <Conianer>
      <ContainerInner>
        <ImageContainer>
          {
            user.imageUrl ? <UserImage src={user.imageUrl} /> : <UserIcon />
          }
        </ImageContainer>
        <UsernameContainer>{user.username || user.nickname}</UsernameContainer>
        <ButtonsContainer>
          <RejectContainer onClick={onReject}>
            <RejectIcon />
          </RejectContainer>
          <AcceptContainer onClick={onAccept}>
            <AcceptIcon />
          </AcceptContainer>
        </ButtonsContainer>
      </ContainerInner>
    </Conianer>
  );
}

export default ConferenceInviteNotif;
