export default {
  AUTHENTICATION_LOGIN: '/auth/login',
  AUTHENTICATION_LOGOUT: '/auth/logout',
  AUTHENTICATION_OTP_POD_LOGIN: '/auth/otp/pod/login',
  AUTHENTICATION_OTP_POD_VERIFY: '/auth/otp/pod/verify',
  AUTHENTICATION_USER_PROFILE: '/auth/user',
  AUTHENTICATION_USERS: '/auth/users',

  TIMEBOX_BUSINESSES: '/timebox/businesses',
  TIMEBOX_MANAGE_BUSINESSES: '/timebox/manage/businesses',
  TIMEBOX_BUSINESSES_SERVICES: '/timebox/businesses/%s/services',
  TIMEBOX_MAMAGE_BUSINESSES_SERVICES: '/timebox/manage/businesses/%s/services/%s',
  TIMEBOX_MAMAGE_BUSINESSES_RESERVES: '/timebox/manage/businesses/%s/reserves',
  TIMEBOX_MAMAGE_RESERVE_STATUS: '/timebox/manage/reserves/%s/status',
  TIMEBOX_UPLOAD_IMAGE: '/timebox/upload/image',
  TIMEBOX_POST_ENTITIES: '/timebox/post/entities',
  TIMEBOX_SERVICES: '/timebox/services',
  TIMEBOX_SERVICES_TIMES: '/timebox/services/%s/times',
  TIMEBOX_SERVICES_RESERVES: '/timebox/services/%s/reserves',
  TIMEBOX_RESERVE_INVOICES_CALLBACK: '/timebox/reserves/%s/callback',
  TIMEBOX_RESERVES: '/timebox/reserves',
  TIMEBOX_RESERVE: '/timebox/reserves/%s',
  TIMEBOX_RESERVE_PAYMENT: '/timebox/reserves/%s/payment',

  SOCIAL_POST_GET_ENTITIES: '/social/post/entities',
  SOCIAL_RATING_ENTITY_RATE: '/social/rating/entities/%s/rate',
  SOCIAL_POST_MANAGE_ENTITIES: '/social/post/manage/entities',
  SOCIAL_LIKE_ENTITY_LIKE_VALUE: '/social/like/entities/%s/like-value',
  SOCIAL_LIKE_ENTITY_LIKE_INFO: '/social/like/entities/%s/like-info',
  SOCIAL_FOLLOW_ENTITY_ADD: '/social/follow/entities/%s/follow',
  SOCIAL_FOLLOW_ENTITY_REMOVE: '/social/follow/entities/%s/unfollow',
  SOCIAL_FOLLOW_ENTITY_INFO: '/social/follow/entities/%s/info',

  CHAT_THREADS: '/chat/threads',
  CHAT_THREAD: '/chat/threads/%s',
  CHAT_THREADS_MESSAGES: '/chat/threads/%s/messages',

  CONFERENCE_ROOMS: '/conference/rooms',
  CONFERENCE_ROOM: '/conference/rooms/%s',
  CONFERENCE_ROOM_INFO: '/conference/room/info',
  CONFERENCE_ROOM_JOIN: '/conference/rooms/%s/join',
  CONFERENCE_ROOM_RECONNECT: '/conference/rooms/%s/reconnect',
  CONFERENCE_ROOM_LEAVE: '/conference/rooms/%s/leave',
  CONFERENCE_ROOM_MEMBER_REMOVE: '/conference/rooms/%s/members/%s',
  CONFERENCE_ROOM_MEMBER_JOIN_REMOVE: '/conference/rooms/%s/members/%s/joins/%s',
  CONFERENCE_ROOM_MEMBER_EDIT: '/conference/rooms/%s/members/%s',
  CONFERENCE_ROOM_JOIN_LEAVE: '/conference/rooms/%s/joins/%s/leave',
  CONFERENCE_ROOM_INVITE: '/conference/rooms/%s/invite',
  CONFERENCE_ROOM_REJECT: '/conference/rooms/%s/reject',
  CONFERENCE_ROOM_WEBRTC_NEGOTIATE: '/conference/rooms/%s/webrtc/negotiate',
  CONFERENCE_ROOM_WEBRTC_ICE_CANDIDATE: '/conference/rooms/%s/webrtc/ice-candidate',
  // CONFERENCE_ROOM_WEBRTC_SYNC: '/conference/rooms/%s/webrtc/sync',
  CONFERENCE_ROOM_MEMBERS_JOIN: '/conference/rooms/%s/members/join',
  CONFERENCE_ROOM_MEMBERS: '/conference/rooms/%s/members',
  CONFERENCE_ROOM_REQUESTS: '/conference/rooms/requests',
  CONFERENCE_ROOM_REQUESTS_ACCEPT: '/conference/rooms/requests/%s/accept',
  CONFERENCE_ROOM_REQUESTS_REJECT: '/conference/rooms/requests/%s/reject',
  CONFERENCE_NODES_GET: '/conference/webrtc/nodes',

  WEBRTC_MANAGER_CLIENT_SYNC: '/webrtc-manager/rooms/%s/clients/%s/sync',
  WEBRTC_MANAGER_CLIENT_PING: '/webrtc-manager/rooms/%s/clients/%s/ping',
};
