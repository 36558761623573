import { Divider, Modal } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Body,
  CloseIcon,
  CodeIcon,
  Container,
  Header,
  InnerContainer,
  MemberSessionIcon,
  MembersContainer,
  MembersIcon,
  NameIcon,
  RowContainer,
} from './components';
import ConferenceRoomDTO from '../../../../../dto/conferenceRoom.dto';
import TextEditor from './components/textEditor';
import ConferenceApi from '../../../../../services/gateway/conferenceApi';
import Config from '../../../../../commons/config';
import RoomLink from './components/roomLink';
import SendMessage from './components/sendMessage';
import AccessType from './components/accessType';
import Members from './components/members';
import Leave from './components/leave';

function RoomSettingModal(
  {
    onClose,
    onNameChange,
    onCodeChange,
    onMaxMemberChange,
    onMaxMemberJoinChange,
    onAccessTypeChange,
    onLeave,
    room,
  }:
  {
    readonly onClose: () => void;
    readonly onNameChange: (name: string) => void;
    readonly onCodeChange: (code: string) => void;
    readonly onMaxMemberChange: (count: number) => void;
    readonly onMaxMemberJoinChange: (count: number) => void;
    readonly onAccessTypeChange: (type: number) => void;
    readonly onLeave: () => void;
    readonly room: ConferenceRoomDTO;
  },
) {
  const dialogHashName = '#room-setting-dialog';
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const onSubmitName = async (name: string) => {
    await ConferenceApi.editRoom({
      roomId: room.id,
      name,
    });

    onNameChange(name);
  };

  const onSubmitMaxMember = async (count: number) => {
    await ConferenceApi.editRoom({
      roomId: room.id,
      maxMember: count,
    });

    onMaxMemberChange(count);
  };

  // const onSubmitAccessType = async (count: number) => {
  //   await ConferenceApi.editRoom({
  //     roomId: room.id,
  //     maxMember: count,
  //   });

  //   onMaxMemberChange(count);
  // };

  const onSubmitMaxMemberJoin = async (count: number) => {
    await ConferenceApi.editRoom({
      roomId: room.id,
      maxMemberJoin: count,
    });

    onMaxMemberJoinChange(count);
  };

  const leaveRoom = async () => {
    try {
      await ConferenceApi.leaveRoom({
        roomId: room.id,
      });
    } catch (e) {
      console.log('leave_err', e);
    }

    onLeave();
  };

  const onSubmitCode = async (code: string) => {
    await ConferenceApi.editRoom({
      roomId: room.id,
      code,
    });

    onCodeChange(code);
  };

  const close = () => {
    navigate(-1);
    onClose();
  };

  const handleOnHashChange = () => {
    const isHashMatch = window.location.hash === dialogHashName;
    if (!isHashMatch && isOpen) {
      onClose();
    }
  };

  useEffect(() => {
    setIsOpen(true);
    navigate(dialogHashName);

    return () => {
      setIsOpen(false);
    };
  }, []);

  useEffect(() => {
    window.addEventListener('hashchange', handleOnHashChange);

    return () => window.removeEventListener('hashchange', handleOnHashChange);
  }, [handleOnHashChange]);

  let link = Config.WEBSITE_ADDRESS;
  if (room.code) {
    link += `/code/${room.code}`;
  } else {
    link += `/id/${room.id}`;
  }

  return (
    <Modal
      open
      onClose={close}
    >
      <Container>
        <InnerContainer>
          <Header>
            <div>setting</div>
            <CloseIcon onClick={close} />
          </Header>
          <Body className="scroll">
            <RowContainer>
              <TextEditor
                title="name"
                text={room.name}
                placeholder="please enter name."
                onSubmit={onSubmitName}
                icon={<NameIcon />}
              />
            </RowContainer>
            <Divider />
            <RowContainer>
              <TextEditor
                title="code"
                text={room.code}
                placeholder="please enter code."
                onSubmit={onSubmitCode}
                icon={<CodeIcon />}
              />
            </RowContainer>
            <Divider />
            <RowContainer $margin="15px 0">
              <RoomLink link={link} />
            </RowContainer>
            <Divider />
            <RowContainer>
              <SendMessage onClick={close} />
            </RowContainer>
            <Divider />
            <RowContainer>
              <AccessType
                roomId={room.id}
                accessType={room.accessType}
                onChange={onAccessTypeChange}
              />
            </RowContainer>
            <Divider />
            <RowContainer>
              <TextEditor
                title="max member"
                text={room.maxMember}
                placeholder="please enter max member count"
                onSubmit={onSubmitMaxMember}
                icon={<MembersIcon />}
                type="number"
              />
            </RowContainer>
            <Divider />
            <RowContainer>
              <TextEditor
                title="max member join"
                text={room.maxMemberJoin}
                placeholder="please enter max member join"
                onSubmit={onSubmitMaxMemberJoin}
                icon={<MemberSessionIcon />}
                type="number"
              />
            </RowContainer>
            <Divider />
            <MembersContainer>
              <Members roomId={room.id} />
            </MembersContainer>
            <Divider />
            <RowContainer>
              <Leave
                onLeave={leaveRoom}
              />
            </RowContainer>
          </Body>
        </InnerContainer>
      </Container>
    </Modal>
  );
}

export default RoomSettingModal;
