import React from 'react';
import {
  Container, ContainerInfo, Icon, TitleText,
} from './components';

function SendMessage({
  onClick,
}: {
  onClick: () => void
}) {
  return (
    <Container onClick={onClick}>
      <Icon />
      <ContainerInfo>
        <TitleText>send message</TitleText>
      </ContainerInfo>
    </Container>
  );
}

export default SendMessage;
