import React, { useState } from 'react';
import {
  FormControl, MenuItem, Select, SelectChangeEvent,
} from '@mui/material';
import { toast } from 'react-toastify';
import {
  Container, NameContainer, RemoveButton, RemoveContainer, RemoveIcon, RoleContainer,
} from './components';
import RoomMemberRoleType from '../../../../../../../../../types/roomMemberRoleType';
import ConferenceApi from '../../../../../../../../../services/gateway/conferenceApi';

function Member({
  name,
  own,
  role,
  memberId,
  roomId,
  onRemove,
}: {
  name: string;
  own: boolean;
  role: number;
  memberId: string;
  roomId: string;
  onRemove: () => void;
}) {
  const [userRole, setUserRole] = useState(role);
  const [removeLoading, setRemoveLoading] = useState(false);

  // const getRoleName = (roleType: number) => {
  //   if (roleType === RoomMemberRoleType.MEMBER) return 'member';
  //   if (roleType === RoomMemberRoleType.ADMIN) return 'admin';
  //   if (roleType === RoomMemberRoleType.CREATOR) return 'creator';
  //   return '';
  // };

  const handleChange = async (event: SelectChangeEvent) => {
    const roleType = Number(event.target.value);
    setUserRole(roleType);

    try {
      await ConferenceApi.editRoomMember({
        memberId,
        roomId,
        role: roleType,
      });
    } catch (e: any) {
      toast.error(e.message);
    }
  };

  const onClickRemove = async () => {
    setRemoveLoading(true);
    try {
      onRemove();
    } catch (e: any) {
      toast.error(e.message);
      setRemoveLoading(true);
    }
  };

  return (
    <Container>
      <NameContainer>{name}</NameContainer>
      <RoleContainer>
        <FormControl size="small" sx={{ minWidth: '100%' }}>
          <Select
            value={String(userRole)}
            onChange={handleChange}
            disabled={own || role === RoomMemberRoleType.CREATOR}
          >
            <MenuItem value={RoomMemberRoleType.MEMBER}>member</MenuItem>
            <MenuItem value={RoomMemberRoleType.ADMIN}>admin</MenuItem>
            { role === RoomMemberRoleType.CREATOR
              && <MenuItem value={RoomMemberRoleType.CREATOR}>creator</MenuItem> }
          </Select>
        </FormControl>
      </RoleContainer>
      <RemoveContainer>
        <RemoveButton
          variant="outlined"
          disabled={own || removeLoading}
          onClick={onClickRemove}
        >
          <RemoveIcon $disabled={own || removeLoading} />
        </RemoveButton>
      </RemoveContainer>
    </Container>
  );
}

export default Member;
