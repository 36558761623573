import Styled from 'styled-components';
import { MdCopyAll } from 'react-icons/md';
import { FiLink2 } from 'react-icons/fi';

export const Container = Styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
`;

export const TitleText = Styled.div`
  
`;

export const LinkText = Styled.div`
  color: gray;
  font-size: 0.8rem;
`;

export const ContainerInfo = Styled.div`
  flex:1;
`;

export const CopyContainer = Styled.div`

`;

export const CopyIcon = Styled(MdCopyAll)`
  font-size: 1.7rem;
  cursor: pointer;
  margin-right: 5px;
`;

export const LinkIcon = Styled(FiLink2)`
  padding-right: 15px;
`;
