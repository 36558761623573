import React, { useEffect, useState } from 'react';
import {
  Container, ContainerInfo, Icon, MembersContainer, TitleContainer, TitleText,
} from './components';
import ConferenceApi from '../../../../../../../services/gateway/conferenceApi';
import ConferenceRoomMemberDTO from '../../../../../../../dto/conferenceRoomMember.dto';
import Member from './components/member';
import { useUserInfoContext } from '../../../../../../../contexts/userInfoContext';

function Members({
  roomId,
}: {
  roomId: string;
}) {
  const [members, setMembers] = useState<ConferenceRoomMemberDTO[]>([]);
  const { userInfo }: any = useUserInfoContext();

  const removeMember = async (memberId: string) => {
    await ConferenceApi.removeRoomMember({
      memberId,
      roomId,
    });

    setMembers(members.filter((member) => member.id !== memberId));
  };

  useEffect(() => {
    (async () => {
      try {
        const { members: mbrs } = await ConferenceApi.getRoomMembers({
          roomId,
        });

        setMembers(mbrs);
      } catch (e) {
        console.log('exp_get room members', e);
      }
    })();
  }, []);

  return (
    <Container>
      <TitleContainer>
        <Icon />
        <ContainerInfo>
          <TitleText>members</TitleText>
        </ContainerInfo>
      </TitleContainer>
      <MembersContainer className="scroll">
        {
          members.map((member) => (
            <Member
              key={member.id}
              memberId={member.id}
              roomId={roomId}
              name={member.user.username || member.user.nickname || ''}
              own={userInfo.id === member.user.id}
              role={member.role}
              onRemove={() => removeMember(member.id)}
            />
          ))
        }
      </MembersContainer>
    </Container>
  );
}

export default Members;
