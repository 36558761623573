import { useState, useEffect } from 'react';

const useTimer = ({ seconds, id }: {seconds: number, id: string }) => {
  // initialize timeLeft with the seconds prop
  // id for reinit timer
  const [timeLeft, setTimeLeft] = useState(seconds);

  useEffect(() => {
    // exit early when we reach 0
    if (!timeLeft) return;

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    // eslint-disable-next-line consistent-return
    return () => clearInterval(intervalId);
  }, [timeLeft]);

  useEffect(() => {
    setTimeLeft(seconds);
  }, [id]);

  return (timeLeft);
};

export default useTimer;
