import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import * as c from './components';
import ConferenceApi from '../../../services/gateway/conferenceApi';
import ConferenceRoomDTO from '../../../dto/conferenceRoom.dto';
import RoomCard from './components/roomCard';
import WebsocketHandler from '../../../socket/websocket';
import Chat from '../../../components/chat';
import RoomSettingModal from './components/roomSetting';
import Loading1 from '../../../components/loadings/loading1';
import Header from '../../../components/layout/header';
import Routes from '../../../commons/routs';

function RoomsPageDesktop() {
  const [rooms, setRooms] = useState<ConferenceRoomDTO[]|null>(null);
  const navigate = useNavigate();
  const [selectedRoom, setSelectedRoom] = useState<ConferenceRoomDTO|null>(null);
  const [settingModal, setSettingModal] = useState(false);
  const [hasMoreRooms, setHasMoreRooms] = useState(true);
  const [joinEnable, setJoinEnable] = useState(true);
  const loadSize = 20;
  const observerTarget = useRef(null);

  const getRooms = async () => {
    try {
      const { rooms: rms } = await ConferenceApi.getRooms({
        size: loadSize,
        offset: rooms?.length ?? 0,
      });

      const list = rooms || [];
      setRooms([...list, ...rms]);

      if (rms.length !== loadSize) {
        setHasMoreRooms(false);
      }
    } catch (e) {
      console.log('exp_getRooms', e);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          getRooms();
        }
      },
      { threshold: 1 },
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [rooms?.length]);
  // }, []);

  useEffect(() => {
    getRooms();
  }, []);

  const joinConference = async (roomId: string) => {
    try {
      setJoinEnable(false);
      const peerData = WebsocketHandler.getPeerData();

      const { id, rtcConfiguration } = await ConferenceApi.joinRoom({
        peerId: peerData.peerId,
        roomId,
      });

      navigate(`/rooms/${roomId}/joins/${id}`, {
        state: { rtcConfiguration },
      });
    } catch (e: any) {
      toast.error(e.message);
    }
    setJoinEnable(true);
  };

  let bodyContent;

  if (rooms === null) {
    bodyContent = <c.NoRoomsContainer><Loading1 /></c.NoRoomsContainer>;
  } else if (rooms.length === 0) {
    bodyContent = (
      <c.NoRoomsContainer>
        <c.NoRoomText>there is no meeting!</c.NoRoomText>
        <c.ReturnHomeButton
          onClick={() => {
            navigate(Routes.HOME.path);
          }}
        >
          <c.HomeIcon />
        </c.ReturnHomeButton>
      </c.NoRoomsContainer>
    );
  } else {
    bodyContent = (
      <>
        <c.RoomCardsContainer className="scroll">
          {
            rooms.map((room) => {
              const name = room.name ?? 'No name';
              const time = moment(room.createdAt).fromNow();
              return (
                <RoomCard
                  key={room.id}
                  name={name}
                  time={time}
                  selected={selectedRoom?.id === room.id}
                  onClick={() => {
                    setJoinEnable(true);
                    setSelectedRoom(room);
                  }}
                />
              );
            })
          }
          {
            hasMoreRooms
            && (
            <c.RoomsLoadingContainer ref={observerTarget}>
              <Loading1 />
            </c.RoomsLoadingContainer>
            )
          }
        </c.RoomCardsContainer>
        <c.ChatContainer>
          {
            selectedRoom
              ? (
                <>
                  <c.ChatHeaderContainer>
                    <c.NameContainer onClick={() => setSettingModal(true)}>
                      {selectedRoom.name || '' }
                      <c.SettingIcon />
                    </c.NameContainer>
                    <c.JoinButton
                      $enable={joinEnable}
                      onClick={() => {
                        joinConference(selectedRoom.id);
                      }}
                    >
                      <c.JoinIcon />
                    </c.JoinButton>
                  </c.ChatHeaderContainer>
                  <c.ChatBodyContainer>
                    { selectedRoom?.threadId && (
                    <Chat
                      threadId={selectedRoom.threadId}
                      header={false}
                    />
                    )}
                  </c.ChatBodyContainer>
                </>
              )
              : (
                <div>
                  please select room.
                </div>
              )
          }
        </c.ChatContainer>
      </>
    );
  }

  return (
    <c.Container>
      <Header />
      <c.BodyContainer>
        {bodyContent}
      </c.BodyContainer>
      {
        settingModal && selectedRoom && (
        <RoomSettingModal
          room={selectedRoom}
          onClose={() => setSettingModal(false)}
          onNameChange={(name) => {
            selectedRoom.name = name;
            setSelectedRoom({ ...selectedRoom });
          }}
          onCodeChange={(code) => {
            selectedRoom.code = code;
            setSelectedRoom({ ...selectedRoom });
          }}
          onAccessTypeChange={(type) => {
            selectedRoom.accessType = type;
            setSelectedRoom({ ...selectedRoom });
          }}
          onMaxMemberChange={(count) => {
            selectedRoom.maxMember = count;
            setSelectedRoom({ ...selectedRoom });
          }}
          onMaxMemberJoinChange={(count) => {
            selectedRoom.maxMemberJoin = count;
            setSelectedRoom({ ...selectedRoom });
          }}
          onLeave={() => {
            if (rooms) {
              const rms = rooms?.filter((room) => room.id !== selectedRoom.id);
              setRooms(rms);
              setSelectedRoom(null);
              setSettingModal(false);
            }
          }}
        />
        )
      }
    </c.Container>
  );
}

export default RoomsPageDesktop;
