import React, { useEffect, useState } from 'react';
import {
  BrowserRouter, Route, Routes,
} from 'react-router-dom';
import './translation/i18n';
import { createTheme, ThemeProvider } from '@mui/material';
import { StyleSheetManager } from 'styled-components';
import rtlcss from 'stylis-rtlcss';
import { ToastContainer } from 'react-toastify';
// import jMoment from 'moment-jalaali';
import 'react-toastify/dist/ReactToastify.css';
import { parseCookies, destroyCookie } from 'nookies';
import ReactGA from 'react-ga';
import { UserInfoProvider } from './contexts/userInfoContext';
import LoadingPage from './pages/loading';
import UserInterface from './dto/user.dto';
import GlobalStyles from './styles/globalStyles';
import Config from './commons/config';
import Authenticator from './types/Authenticator';
import { userLoginAction } from './commons/helper';
import RoutesInfo from './commons/routs';
import WebsocketHandler from './socket/websocket';
import AuthApi from './services/gateway/authApi';
import Main from './main';
import { ConferenceInfoProvider } from './components/conference/context/conferenceInfo.context';

// jMoment.loadPersian({ dialect: 'persian-modern', usePersianDigits: false });

const theme = createTheme({
  direction: 'ltr',
  typography: {
    fontFamily: 'IRANSans, Roboto,sans-serif',
  },
  palette: {
    mode: 'dark',
    primary: {
      100: '#382bf0',
      200: '#5e43f3',
      300: '#7a5af5',
      400: '#9171f8',
      500: '#a688fa',
      600: '#ba9ffb',
    },
    // background: {
    //   paper: '#282828',
    //   default: '#121212',
    // },
  },
}, {
  custom: {
    headerBackground: '#181818',
    bodyBackground: '#212121',
    blueDark: '#2d44b5',
    blueLight: '#3d5bf3',
    redDark: '#ad0000',
    redLight: 'red',
    greenDark: '#0b6405',
    greenLight: '#098a00',
    azureDark: '#5d40ab',
    azureLight: '#885efa',
    grayDark: '#3f3f3f',
    grayLight: '#545252',
    transition: '1s',
  },
});

function App() {
  const [userInfo, setUserInfo] = useState<UserInterface|null>(null);
  const [loading, setLoading] = useState(true);
  // const location = useLocation();

  const checkUserLoginState = async () => {
    const { search } = window.location;
    const queryParams = new URLSearchParams(search);
    const code = queryParams.get('code');
    const authenticatora = Number(queryParams.get('authenticator')) || Authenticator.POD;
    let uInfo;

    try {
      if (code) {
        // check redirect code that return from pod;
        const { user, token } = await AuthApi.login({
          code,
          redirectUri: authenticatora === Authenticator.POD
            ? Config.POD_LOGIN_REDIRECT_URI
            : Config.GOOGLE_LOGIN_REDIRECT_URI,
          clientCode: authenticatora === Authenticator.POD
            ? Config.POD_CLIENT_CODE
            : Config.GOOGLE_CLIENT_CODE,
        });
        uInfo = user;
        uInfo.token = token.accessToken;

        userLoginAction({ token, reload: false });
        setUserInfo(uInfo);
      } else {
        const { token } = parseCookies(null, 'token');

        if (token) {
          const ueserProfile = await AuthApi.getUserProfile({});
          uInfo = ueserProfile.user;
          uInfo.token = token;
          setUserInfo(uInfo);
        }
      }
    } catch (e: any) {
      if (e.status === 401) { // user token is not valid
        destroyCookie(null, 'token');
      }
      // login failed
    }
    setLoading(false);

    return uInfo;
  };

  useEffect(() => {
    ReactGA.initialize(Config.GOOGLE_ANALYTICS_TRACKING_ID);
    ReactGA.pageview(window.location.pathname + window.location.search);

    (async () => {
      const uInfo = await checkUserLoginState();

      WebsocketHandler.initSocket({ token: uInfo?.token });
    })();
  }, []);

  const routes = Object.values(RoutesInfo).map((route) => (
    <Route
      path={route.path}
      key={route.path}
      element={route.component}
    />
  ));

  return (
    <UserInfoProvider userInfo={userInfo}>
      <ThemeProvider theme={theme}>
        <ConferenceInfoProvider>
          <StyleSheetManager stylisPlugins={[rtlcss]}>
            <ToastContainer
              position="bottom-left"
              // rtl
              theme="dark"
              progressStyle={{
                background: 'white',
              }}
            />
            <BrowserRouter>
              <GlobalStyles />
              <Main />
              {
                loading
                  ? <LoadingPage />
                  : (
                    <Routes>
                      {routes}
                    </Routes>
                  )
              }
            </BrowserRouter>
          </StyleSheetManager>
        </ConferenceInfoProvider>
      </ThemeProvider>
    </UserInfoProvider>
  );
}

export default App;
