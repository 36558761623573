import Url from './url';
import { format } from '../../commons/helper';
import RequestHandler from './requestHandler';
import ConferenceRoomDTO from '../../dto/conferenceRoom.dto';
import ConferenceRoomMemberDTO from '../../dto/conferenceRoomMember.dto';
import WebRTCNodeDTO from '../../dto/webrtcNode.dto';

class ConferenceApi {
  private static async request({
    method, url, data, form, headers,
  }:{method:'GET'|'POST'|'DELETE'|'PUT'|'PATCH', url: string, data?: any, form?: any, headers: any})
  :Promise<any> {
    const res = RequestHandler.request({
      method, url, data, form, headers,
    });
    return res;
  }

  static async addRoom({
    threadId,
    name,
    code,
    accessType,
    maxMember,
    maxMemberJoin,
    nodeId,
  }: {
    threadId?: string;
    name?: string;
    code?: string;
    accessType?: number;
    maxMember?: number;
    maxMemberJoin?: number;
    nodeId?: string;
  }) :Promise<{
    id: string,
    code: string,
    joinInfo?: {
    id: string;
    createdAt: string;
  } }> {
    const res = await this.request({
      method: 'POST',
      url: Url.CONFERENCE_ROOMS,
      data: {
        threadId,
        name,
        code,
        accessType,
        maxMember,
        maxMemberJoin,
        nodeId,
      },
      headers: {},
    });
    return res;
  }

  static async getRoom({
    roomId,
  }: {
    roomId: string;
  }) :Promise<{ room: ConferenceRoomDTO }> {
    const res = await this.request({
      method: 'GET',
      url: `${format(Url.CONFERENCE_ROOM, roomId)}`,
      data: {},
      headers: {},
    });
    return res;
  }

  static async getRoomInfo({
    id,
    code,
  }: {
    id?: string;
    code?: string;
  }) :Promise<{
    memberCount: number;
    onlineMemberCount: number;
    onlineMembers: ConferenceRoomMemberDTO[]
  }> {
    const res = await this.request({
      method: 'GET',
      url: `${format(Url.CONFERENCE_ROOM_INFO)}`,
      data: {
        id,
        code,
      },
      headers: {},
    });
    return res;
  }

  static async editRoom({
    roomId,
    name,
    code,
    maxMember,
    maxMemberJoin,
    accessType,
  }: {
    roomId: string;
    name?: string;
    code?: string;
    maxMember?: number;
    maxMemberJoin?: number;
    accessType?: number;
  }) :Promise<any> {
    const res = await this.request({
      method: 'PATCH',
      url: `${format(Url.CONFERENCE_ROOM, roomId)}`,
      data: {
        name,
        code,
        accessType,
        maxMember,
        maxMemberJoin,
      },
      headers: {},
    });
    return res;
  }

  static async editRoomMember({
    roomId,
    memberId,
    role,
  }: {
    roomId: string;
    memberId: string;
    role: number;
  }) :Promise<any> {
    const res = await this.request({
      method: 'PATCH',
      url: `${format(Url.CONFERENCE_ROOM_MEMBER_EDIT, roomId, memberId)}`,
      data: { role },
      headers: {},
    });
    return res;
  }

  static async removeRoomMember({
    roomId,
    memberId,
  }: {
    roomId: string;
    memberId: string;
  }) :Promise<any> {
    const res = await this.request({
      method: 'DELETE',
      url: `${format(Url.CONFERENCE_ROOM_MEMBER_REMOVE, roomId, memberId)}`,
      data: {},
      headers: {},
    });
    return res;
  }

  static async removeMemberJoin({
    roomId,
    memberId,
    joinId,
  }: {
    roomId: string;
    memberId: string;
    joinId: string;
  }) :Promise<any> {
    const res = await this.request({
      method: 'DELETE',
      url: `${format(Url.CONFERENCE_ROOM_MEMBER_JOIN_REMOVE, roomId, memberId, joinId)}`,
      data: {},
      headers: {},
    });
    return res;
  }

  static async getRooms({
    size = 50,
    offset = 0,
  }: {
    size?: number;
    offset?: number;
  }) :Promise<{ rooms: ConferenceRoomDTO[] }> {
    const res = await this.request({
      method: 'GET',
      url: Url.CONFERENCE_ROOMS,
      data: { size, offset },
      headers: {},
    });
    return res;
  }

  static async joinRoom({
    roomId,
    peerId,
  }: {
    roomId: string;
    peerId: string;
  }) :Promise<{ id: string, createdAt: string, room: { code: string }, rtcConfiguration: any, }> {
    const res = await this.request({
      method: 'POST',
      url: `${format(Url.CONFERENCE_ROOM_JOIN, roomId)}`,
      data: { peerId },
      headers: {},
    });
    return res;
  }

  static async reconnect({
    roomId,
    peerId,
    joinId,
  }: {
    roomId: string;
    peerId: string;
    joinId: string;
  }) :Promise<{ id: string }> {
    const res = await this.request({
      method: 'POST',
      url: `${format(Url.CONFERENCE_ROOM_RECONNECT, roomId)}`,
      data: { peerId, joinId },
      headers: {},
    });
    return res;
  }

  static async leaveRoom({
    roomId,
  }: {
    roomId: string;
  }) :Promise<{ room: ConferenceRoomDTO}> {
    const res = await this.request({
      method: 'POST',
      url: `${format(Url.CONFERENCE_ROOM_LEAVE, roomId)}`,
      data: {},
      headers: {},
    });
    return res;
  }

  static async leaveRoomJoin({
    roomId,
    joinId,
  }: {
    roomId: string;
    joinId: string;
  }) :Promise<{ room: ConferenceRoomDTO}> {
    const res = await this.request({
      method: 'POST',
      url: `${format(Url.CONFERENCE_ROOM_JOIN_LEAVE, roomId, joinId)}`,
      data: {},
      headers: {},
    });
    return res;
  }

  static async reject({
    roomId,
  }: {
    roomId: string;
  }) :Promise<{ room: ConferenceRoomDTO}> {
    const res = await this.request({
      method: 'POST',
      url: `${format(Url.CONFERENCE_ROOM_REJECT, roomId)}`,
      data: {},
      headers: {},
    });
    return res;
  }

  static async invite({
    roomId,
    members,
  }: {
    roomId: string;
    members: {
      userId: string
    }[];
  }) :Promise<{members: {
    id: string;
    roomId: string;
    userId: string;
    role: number;
  }[]}> {
    const res = await this.request({
      method: 'POST',
      url: `${format(Url.CONFERENCE_ROOM_INVITE, roomId)}`,
      data: {
        members,
      },
      headers: {},
    });
    return res;
  }

  static async webrtcNegotiate({
    roomId,
    fromJoinId,
    toJoinId,
    description,
  }: {
    roomId: string;
    description?: {
      sdp: string,
      type: string,
    }|null;
    fromJoinId: string;
    toJoinId?: string;
  }) :Promise<{ answer?: { type: any, sdp: string }}> {
    const res = await this.request({
      method: 'POST',
      url: `${format(Url.CONFERENCE_ROOM_WEBRTC_NEGOTIATE, roomId)}`,
      data: {
        description,
        fromJoinId,
        toJoinId,
      },
      headers: {},
    });
    return res;
  }

  static async webrtcIceCandidate({
    roomId,
    candidate,
    toJoinId,
    fromJoinId,
  }: {
    roomId: string;
    candidate: any;
    toJoinId?: string;
    fromJoinId: string;
  }) :Promise<any> {
    const res = await this.request({
      method: 'POST',
      url: `${format(Url.CONFERENCE_ROOM_WEBRTC_ICE_CANDIDATE, roomId)}`,
      data: {
        toJoinId,
        fromJoinId,
        candidate,
      },
      headers: {},
    });
    return res;
  }

  static async getRoomJoinMembers({
    roomId,
  }: {
    roomId: string;
  }) :Promise<{ members: {
    id: string;
    imageUrl?: string,
    username?: string;
    nickname?: string;
    joinsInfo: {
      id: string;
    }[]
  }[]}> {
    const res = await this.request({
      method: 'GET',
      url: `${format(Url.CONFERENCE_ROOM_MEMBERS_JOIN, roomId)}`,
      data: {},
      headers: {},
    });
    return res;
  }

  static async getRoomMembers({
    roomId,
  }: {
    roomId: string;
  }) :Promise<{ members: ConferenceRoomMemberDTO[]}> {
    const res = await this.request({
      method: 'GET',
      url: `${format(Url.CONFERENCE_ROOM_MEMBERS, roomId)}`,
      data: {},
      headers: {},
    });
    return res;
  }

  static async roomRequest({
    id,
    code,
    peerId,
  }: {
    id?: string;
    code?: string;
    peerId: string;
  }) :Promise<{ id: string, canJoin: boolean, room: { id: string}}> {
    const res = await this.request({
      method: 'POST',
      url: Url.CONFERENCE_ROOM_REQUESTS,
      data: { peerId, code, id },
      headers: {},
    });
    return res;
  }

  static async requestAccept({
    requestId,
  }: {
    requestId: string;
  }) :Promise<{
    memberId: string;
  }> {
    const res = await this.request({
      method: 'POST',
      url: `${format(Url.CONFERENCE_ROOM_REQUESTS_ACCEPT, requestId)}`,
      data: {},
      headers: {},
    });
    return res;
  }

  static async requestReject({
    requestId,
  }: {
    requestId: string;
  }) :Promise<any> {
    const res = await this.request({
      method: 'POST',
      url: `${format(Url.CONFERENCE_ROOM_REQUESTS_REJECT, requestId)}`,
      data: {},
      headers: {},
    });
    return res;
  }

  // static async sync({
  //   roomId,
  //   joinId,
  // }: {
  //   roomId: string;
  //   joinId: string;
  // }) :Promise<{ id: string }> {
  //   const res = await this.request({
  //     method: 'POST',
  //     url: `${format(Url.CONFERENCE_ROOM_WEBRTC_SYNC, roomId)}`,
  //     data: { joinId },
  //     headers: {},
  //   });
  //   return res;
  // }

  static async getNodes({
    size = 50,
    offset = 0,
  }: {
    size?: number;
    offset?: number;
  }) :Promise<{ nodes: WebRTCNodeDTO[] }> {
    const res = await this.request({
      method: 'GET',
      url: Url.CONFERENCE_NODES_GET,
      data: { size, offset },
      headers: {},
    });
    return res;
  }
}

export default ConferenceApi;
