import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Chat from '../../chat';
import MembersTab from './membersTab';
import {
  ChatIcon,
  CloseIcon, Conitainer,
  HeaderContainer, InnerContainer,
  MembersIcon,
  SettingIcon,
  TabContainer, TabsContainer, ViewContainer,
} from './components';
import SettingTab from './settingTab';
import ConferenceRoomDTO from '../../../dto/conferenceRoom.dto';

export enum SideViewType {
  NONE= 0,
  CHAT= 1,
  MEMBERS= 2,
  SETTING= 3,
}

const HeaderName: any = {
  [SideViewType.CHAT]: 'chat',
  [SideViewType.MEMBERS]: 'members',
  [SideViewType.SETTING]: 'setting',
};

function Sidebar(
  {
    viewType,
    threadId,
    onClose,
    open,
    room,
    onNewMessage,
  }:
  {
    readonly viewType: SideViewType;
    readonly threadId?: string;
    readonly onClose?: any;
    readonly onNewMessage?: any;
    readonly open: boolean;
    readonly room: ConferenceRoomDTO;
  },
) {
  const [view, setView] = useState<SideViewType>(viewType);
  const componentHashName = '#side-menu';
  const navigate = useNavigate();

  const close = async () => {
    navigate(-1);
    onClose();
  };

  const handleOnHashChange = () => {
    const isHashMatch = window.location.hash === componentHashName;
    if (!isHashMatch && open) {
      onClose();
    }
  };

  useEffect(() => {
    if (!open) {
      return;
    }

    navigate(componentHashName);
  }, [open]);

  useEffect(() => {
    window.addEventListener('hashchange', handleOnHashChange);

    return () => window.removeEventListener('hashchange', handleOnHashChange);
  }, [handleOnHashChange]);

  return (
    <Conitainer $enable={open}>
      <InnerContainer>
        <HeaderContainer>
          <div>{HeaderName[view]}</div>
          <CloseIcon onClick={close} />
        </HeaderContainer>
        <ViewContainer className="scroll">
          {
            view === SideViewType.CHAT && (
              <Chat
                threadId={threadId}
                header={false}
                onNewMessage={onNewMessage}
              />
            )
          }
          {
            view === SideViewType.MEMBERS && (
            <MembersTab roomId={room.id} />
            )
          }
          {
            view === SideViewType.SETTING && (
              <SettingTab
                room={room}
              />
            )
          }
        </ViewContainer>
        <TabsContainer>
          <TabContainer
            $active={view === SideViewType.CHAT}
            onClick={() => setView(SideViewType.CHAT)}
          >
            <ChatIcon $active={view === SideViewType.CHAT} />
          </TabContainer>
          <TabContainer
            $active={view === SideViewType.MEMBERS}
            onClick={() => setView(SideViewType.MEMBERS)}
          >
            <MembersIcon $active={view === SideViewType.MEMBERS} />
          </TabContainer>
          <TabContainer
            $active={view === SideViewType.SETTING}
            onClick={() => setView(SideViewType.SETTING)}
          >
            <SettingIcon $active={view === SideViewType.SETTING} />
          </TabContainer>
        </TabsContainer>
      </InnerContainer>
    </Conitainer>
  );
}

Sidebar.defaultProps = {
  threadId: null,
  onClose: null,
  onNewMessage: null,
};

export default Sidebar;
