import Styled from 'styled-components';

export const Container = Styled.div`
  overflow: hidden;
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  flex: 1;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
`;

export const Tmp = Styled.div``;
