import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
/* @font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: normal;
  src: url('/fonts/IRANSansWeb_Medium.eot');
  src: url('/fonts/IRANSansWeb_Medium.eot?#iefix') format('embedded-opentype'),
  url('/fonts/IRANSansWeb_Medium.woff2') format('woff2'),
  url('/fonts/IRANSansWeb_Medium.woff') format('font-woff'),
  url('/fonts/IRANSansWeb_Medium.ttf') format('truetype');
} */

html,
body {
  padding: 0;
  margin: 0;
  font-family: IRANSans, Roboto,sans-serif;
  color : ${(props) => props.theme.palette.text.primary};
  overflow-x : hidden;
  scroll-behavior: smooth;
  background: ${(props) => props.theme.palette.grey['900']};
  width: 100%;
  height: 100%;
}

#root {
  height: 100%;
  outline: none;
}

.MuiModal-root {
  outline: none;

  >div {
    outline: none;
  }
}

.unselectable {
  user-select: none;
  -webkit-touch-callout:none;
  -webkit-user-select:none;
  -khtml-user-select:none;
  -moz-user-select:none;
  -webkit-tap-highlight-color:rgba(0,0,0,0);
}

.scroll {
  &::-webkit-scrollbar {
    width: 20px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
  }
}
`;

export default GlobalStyles;
