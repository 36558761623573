import Styled from 'styled-components';

export const Container = Styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #121111;
  width: 40px;
  height: 40px;
  font-size: 1.2rem;
  position: relative;

  &:nth-child(even) {
    padding: 5px;
  }
`;

export const LetterContainer = Styled.div`
  position: absolute; 
`;
