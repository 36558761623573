import React from 'react';
import { Divider } from '@mui/material';
import {
  CodeIcon, Conitainer, MemberSessionIcon,
  MembersIcon, NameIcon, RowContainer,
} from './components';
import TextEditor from './components/textEditor';
import ConferenceApi from '../../../../services/gateway/conferenceApi';
import RoomLink from './components/roomLink';
import AccessType from './components/accessType';
import ConferenceRoomDTO from '../../../../dto/conferenceRoom.dto';
import Config from '../../../../commons/config';
import { PubSubEvent, usePub } from '../../hook/pubSub';
import { useMembers } from '../../context/members.context';
import UserDTO from '../../../../dto/user.dto';
import { useUserInfoContext } from '../../../../contexts/userInfoContext';
import RoomMemberRoleType from '../../../../types/roomMemberRoleType';

function SettingTab({
  room,
}: {
  readonly room: ConferenceRoomDTO,
}) {
  const publish = usePub();
  const { getMemberByUserId } = useMembers();
  const { userInfo } :
  {userInfo?:UserDTO|null} = useUserInfoContext();

  const currentMember = getMemberByUserId(userInfo?.id);

  const onSubmitCode = async (code: string) => {
    await ConferenceApi.editRoom({
      roomId: room.id,
      code: code.toLowerCase(),
    });

    publish(PubSubEvent.ROOM_CODE_CHANGE, {
      code,
    });
  };

  const onSubmitName = async (name: string) => {
    await ConferenceApi.editRoom({
      roomId: room.id,
      name,
    });

    publish(PubSubEvent.ROOM_NAME_CHANGE, {
      name,
    });
  };

  const onSubmitMaxMember = async (count: number) => {
    await ConferenceApi.editRoom({
      roomId: room.id,
      maxMember: count,
    });

    publish(PubSubEvent.ROOM_MAX_MEMBER_CHANGE, {
      count,
    });
  };

  const onSubmitMaxMemberJoin = async (count: number) => {
    await ConferenceApi.editRoom({
      roomId: room.id,
      maxMemberJoin: count,
    });

    publish(PubSubEvent.ROOM_MAX_MEMBER_JOIN_CHANGE, {
      count,
    });
  };

  const onAccessTypeChange = async (type: number) => {
    publish(PubSubEvent.ROOM_ACCESS_TYPE_CHANGE, {
      type,
    });
  };

  let link = Config.WEBSITE_ADDRESS;
  if (room.code) {
    link += `/code/${room.code}`;
  } else {
    link += `/id/${room.id}`;
  }

  return (
    <Conitainer $enable={currentMember.role === RoomMemberRoleType.ADMIN
      || currentMember.role === RoomMemberRoleType.CREATOR}
    >
      <RowContainer>
        <RowContainer>
          <TextEditor
            title="name"
            text={room.name}
            placeholder="please enter name."
            onSubmit={onSubmitName}
            icon={<NameIcon />}
          />
        </RowContainer>
        <Divider />
        <TextEditor
          title="code"
          text={room.code}
          placeholder="please enter code."
          onSubmit={onSubmitCode}
          style={{
            textTransform: 'lowercase',
          }}
          icon={<CodeIcon />}
        />
      </RowContainer>
      <Divider />
      <RowContainer $margin="15px 0">
        <RoomLink link={link} />
      </RowContainer>
      <Divider />
      <RowContainer>
        <AccessType
          roomId={room.id}
          accessType={room.accessType}
          onChange={onAccessTypeChange}
        />
      </RowContainer>
      <Divider />
      <RowContainer>
        <TextEditor
          title="max member"
          text={room.maxMember}
          placeholder="please enter max member count"
          onSubmit={onSubmitMaxMember}
          icon={<MembersIcon />}
          type="number"
        />
      </RowContainer>
      <Divider />
      <RowContainer>
        <TextEditor
          title="max member join"
          text={room.maxMemberJoin}
          placeholder="please enter max member join"
          onSubmit={onSubmitMaxMemberJoin}
          icon={<MemberSessionIcon />}
          type="number"
        />
      </RowContainer>
    </Conitainer>
  );
}

export default SettingTab;
