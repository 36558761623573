import React, { useEffect, useState } from 'react';
import { Modal } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as c from './components';
import Chat from '../../../../../components/chat';
import WebsocketHandler from '../../../../../socket/websocket';
import ConferenceApi from '../../../../../services/gateway/conferenceApi';
import ConferenceRoomDTO from '../../../../../dto/conferenceRoom.dto';
import RoomSettingModal from '../../../desktop/components/roomSetting';

function ChatModal({
  room,
  onClose,
  onLeave,
}: {
  readonly room: ConferenceRoomDTO;
  readonly onClose: () => void;
  readonly onLeave: () => void;
}) {
  const dialogHashName = '#room-chat-dialog';
  const [settingModal, setSettingModal] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState<ConferenceRoomDTO>(room);
  const [joinEnable, setJoinEnable] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const joinConference = async () => {
    try {
      const peerData = WebsocketHandler.getPeerData();

      const {
        id,
        rtcConfiguration,
      } = await ConferenceApi.joinRoom({
        peerId: peerData.peerId,
        roomId: room.id,
      });

      navigate(`/rooms/${room.id}/joins/${id}`, {
        state: { rtcConfiguration },
      });
    } catch (e: any) {
      toast.error(e.message);
    }
  };

  const close = () => {
    navigate(-1);
    onClose();
  };

  const handleOnHashChange = () => {
    const isHashMatch = window.location.hash === dialogHashName;
    if (!isHashMatch && isOpen) {
      onClose();
    }
  };

  useEffect(() => {
    setIsOpen(true);
    navigate(dialogHashName);

    return () => {
      setIsOpen(false);
    };
  }, []);

  useEffect(() => {
    window.addEventListener('hashchange', handleOnHashChange);

    return () => window.removeEventListener('hashchange', handleOnHashChange);
  }, [handleOnHashChange]);

  return (
    <Modal open onClose={close}>
      <c.Container>
        <c.ContainerInner>
          <c.Header>
            <c.NameContainer onClick={() => setSettingModal(true)}>
              {room.name ?? ''}
              <c.SettingIcon />
            </c.NameContainer>
            <c.HeaderRightContainer>
              <c.JoinButton
                $enable={joinEnable}
                onClick={async () => {
                  setJoinEnable(false);
                  await joinConference();
                  setJoinEnable(true);
                }}
              >
                <c.JoinIcon />
              </c.JoinButton>
              <c.CloseIcon onClick={close} />
            </c.HeaderRightContainer>

          </c.Header>
          <c.Body>
            <Chat threadId={room.threadId} header={false} />
          </c.Body>
        </c.ContainerInner>
        {settingModal && (
          <RoomSettingModal
            room={selectedRoom}
            onClose={() => setSettingModal(false)}
            onNameChange={(name) => {
              selectedRoom.name = name;
              setSelectedRoom({ ...selectedRoom });
            }}
            onCodeChange={(code) => {
              selectedRoom.code = code;
              setSelectedRoom({ ...selectedRoom });
            }}
            onAccessTypeChange={(type) => {
              selectedRoom.accessType = type;
              setSelectedRoom({ ...selectedRoom });
            }}
            onMaxMemberChange={(count) => {
              selectedRoom.maxMember = count;
              setSelectedRoom({ ...selectedRoom });
            }}
            onMaxMemberJoinChange={(count) => {
              selectedRoom.maxMemberJoin = count;
              setSelectedRoom({ ...selectedRoom });
            }}
            onLeave={onLeave}
          />
        )}
      </c.Container>
    </Modal>
  );
}

export default ChatModal;
