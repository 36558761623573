import React, {
  ChangeEvent, useEffect, useRef, useState,
} from 'react';
import { CircularProgress } from '@mui/material';
import { toast } from 'react-toastify';
import {
  ApplyContainer,
  ApplyIcon,
  CloseContainer,
  CloseIcon,
  Container,
  ContainerInner,
  EditContainer,
  EditIcon,
  IconContainer,
  Input,
  LoadinContainer,
  TitleContainer,
} from './components';

function TextEditor({
  title,
  text,
  placeholder,
  onSubmit,
  icon,
  type,
  style,
}: {
  readonly title: string;
  readonly text?: string|number;
  readonly placeholder?: string;
  readonly icon?:any;
  readonly type?:string;
  readonly style?:any;
  readonly onSubmit: (value: any) => Promise<void>
}) {
  const [currentText, setCurrentText] = useState(text);
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const ref: any = useRef();

  useEffect(() => {
    function handleClickOutside(event: MouseEvent): void {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setEditMode(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCurrentText(e.target.value);
  };

  const apply = async () => {
    if (!currentText) return;
    setLoading(true);

    try {
      await onSubmit(type === 'number' ? Number(currentText) : currentText);
      setEditMode(false);
    } catch (e: any) {
      toast.error(e.message);
    }
    setLoading(false);
  };

  return (
    <Container
      ref={ref}
      onClick={() => {
        if (!editMode) {
          setEditMode(true);
        }
      }}
    >
      <TitleContainer>
        <IconContainer>
          {icon}
        </IconContainer>
        {title}
      </TitleContainer>
      <ContainerInner $editMode={editMode}>
        <Input
          value={currentText ?? ''}
          placeholder={placeholder}
          onChange={onInputChange}
          disabled={loading}
          style={style}
          type={type}
        />
        <CloseContainer onClick={() => {
          setEditMode(false);
          setCurrentText(text);
        }}
        >
          { editMode && !loading && <CloseIcon />}
        </CloseContainer>
        <ApplyContainer
          onClick={apply}
        >
          { editMode && !loading && <ApplyIcon /> }
        </ApplyContainer>
        <EditContainer>
          { !editMode && !loading && <EditIcon /> }
        </EditContainer>
        <LoadinContainer>
          { loading && <CircularProgress size={23} />}
        </LoadinContainer>
      </ContainerInner>
    </Container>
  );
}

TextEditor.defaultProps = {
  text: null,
  placeholder: null,
  icon: null,
  style: null,
  type: 'text',
};

export default TextEditor;
