import Styled from 'styled-components';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { CiSettings, CiChat1 } from 'react-icons/ci';
import { PiUsersFourLight } from 'react-icons/pi';

export const Conitainer = Styled.div<{$enable: boolean}>`
  transform: ${(props) => (props.$enable ? 'translateX(15px)' : 'translateX(100%)')};
  position: absolute;
  right: 0;  
  width: 20%;
  min-width: 360px;
  height: 100%;
  transition: ${(props) => props.theme.custom.transition};
  z-index: 4;
`;

export const InnerContainer = Styled.div`
  background: ${(props) => props.theme.custom.bodyBackground};
  border-radius: 15px 0 0 15px;
  margin: 15px 15px 15px 0;
  height: 95%;
  display: flex;
  flex-direction: column;
`;

export const HeaderContainer = Styled.div`
  background: ${(props) => props.theme.custom.headerBackground};
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 10px 15px;
  align-items: center;
  flex: 0;
`;

export const CloseIcon = Styled(AiOutlineCloseCircle)`
  font-size: 1.5rem;
  cursor: pointer;
`;

export const ViewContainer = Styled.div`
  flex: auto;
  overflow-y: auto;
`;

export const TabsContainer = Styled.div`
  display: flex;
  justify-content: space-around;
  background: #181818;
  cursor: pointer;
`;

export const TabContainer = Styled.div<{$active: boolean}>`
  padding: 10px 10px 10px 10px;
  display: flex;
  align-items: center;
  border-top: ${(props) => (props.$active ? '2px solid white' : 'none')};
  transition: ${(props) => props.theme.custom.transition};
  font-size: 1.2rem;
  flex: 0.33;
  justify-content: center;

  &:hover {
    transform: scale(1.1)
  }
`;

export const SettingIcon = Styled(CiSettings)<{$active: boolean}>`
  font-size: 1.5rem;
  margin: 0 5px;
  color: ${(props) => (props.$active ? 'white' : 'gray')};
`;

export const MembersIcon = Styled(PiUsersFourLight)<{$active: boolean}>`
  color: ${(props) => (props.$active ? 'white' : 'gray')};
`;

export const ChatIcon = Styled(CiChat1)<{$active: boolean}>`
  color: ${(props) => (props.$active ? 'white' : 'gray')};
`;
