import React from 'react';
import moment from 'moment-jalaali';
import { useUserInfoContext } from '../../../contexts/userInfoContext';
import ThreadMessage from '../../../dto/threadMessage.dto';
import {
  Container, ContainerInner, ImageContainer,
  InfoContainer,
  TextContainer,
  TimeContainer, UserIcon, UserImage,
} from './components';

function MessageView({ message }: {message: ThreadMessage}) {
  const { userInfo }: any = useUserInfoContext();
  const own = userInfo && (userInfo.id === message.user.id);
  return (
    <Container $own={own}>
      <ContainerInner $own={own}>
        <ImageContainer>
          {message.user.imageUrl ? <UserImage src={message.user.imageUrl} /> : <UserIcon />}
        </ImageContainer>
        <InfoContainer $own={own}>
          <TextContainer>{message.text}</TextContainer>
          <TimeContainer>{moment(message.createdAt).fromNow()}</TimeContainer>
        </InfoContainer>
      </ContainerInner>
    </Container>
  );
}

export default MessageView;
