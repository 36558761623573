import React, { useEffect } from 'react';
import { destroyCookie } from 'nookies';
import { useNavigate } from 'react-router-dom';
import Loading1 from '../../components/loadings/loading1';
import * as c from './components';
import AuthApi from '../../services/gateway/authApi';

function LogoutPage() {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Logout';
    (async () => {
      try {
        await AuthApi.logout({});
      } catch (e) {
        // TODO check logout fail
      }

      destroyCookie(null, 'token');
      localStorage.removeItem('user');
      navigate('/');
      window.location.reload();
    })();
  }, []);

  return (
    <c.Container>
      <Loading1 />
    </c.Container>
  );
}

export default LogoutPage;
