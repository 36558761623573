import React, { createContext, useContext, useMemo } from 'react';
import UserInterface from '../dto/user.dto';
import BusinessInterface from '../dto/business.dto';

const userInfoContext = createContext<{
  userInfo?: UserInterface|null;businessInfo?: BusinessInterface|null
}>({ userInfo: null, businessInfo: null });

export function UserInfoProvider({ children, userInfo, businessInfo }:
   {userInfo: UserInterface|null, businessInfo?: BusinessInterface|null, children:any}) {
  // const value = { userInfo, businessInfo };
  const value = useMemo(
    () => ({ userInfo, businessInfo }),
    [userInfo, businessInfo],
  );

  return (
    <userInfoContext.Provider value={value}>
      {children}
    </userInfoContext.Provider>
  );
}

UserInfoProvider.defaultProps = {
  businessInfo: null,
};

export function useUserInfoContext() {
  return useContext(userInfoContext);
}
