import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { sleep } from '../../commons/helper';
import ModalPodOTPLogin from '../../components/modalPodOTPLogin';
import { useUserInfoContext } from '../../contexts/userInfoContext';
import UserInterface from '../../dto/user.dto';
import ConferenceApi from '../../services/gateway/conferenceApi';
import WebsocketHandler from '../../socket/websocket';
import ConferenceEventType from '../../types/conferenceEventType';
import * as c from './components';
import ConferenceRoomMemberDTO from '../../dto/conferenceRoomMember.dto';
import Loading1 from '../../components/loadings/loading1';
import Member from './components/member';
import Header from '../../components/layout/header';

function RejoinPage() {
  const { code, id } = useParams();
  const { userInfo } :
  {userInfo?:UserInterface|null} = useUserInfoContext();
  const [localStream, setLocalStream] = useState<MediaStream|null>(null);
  const [micEnable, setMicEnable] = useState(false);
  const [camEnable, setCamEnable] = useState(false);
  const [joinEnable, setJoinEnable] = useState(true);
  const [podLoginModalOpen, setPodLoginModalOpen] = useState(false);
  const streamRef = useRef<HTMLVideoElement|null>(null);
  const navigate = useNavigate();
  const [roomInfo, setRoomInfo] = useState<{
    memberCount: number;
    onlineMemberCount: number;
    onlineMembers: ConferenceRoomMemberDTO[]
  }|null>(null);

  const toggleMic = async () => {
    const enable = !micEnable;
    if (enable) {
      try {
        checkMedia({ micEnable: true, camEnable: false });
        setMicEnable(true);
      } catch (e) {
        setMicEnable(false);
      }
    } else {
      if (localStream) {
        const tracks = localStream.getAudioTracks();
        // tracks.forEach((track: MediaStreamTrack) => { track.enabled = false; });
        tracks.forEach((track: MediaStreamTrack) => {
          track.stop();
          localStream.removeTrack(track);
        });

        if (localStream.getTracks().length === 0) {
          setLocalStream(null);
        }
      }
      setMicEnable(false);
    }
  };

  const toggleCam = () => {
    const enable = !camEnable;
    if (enable) {
      try {
        checkMedia({ micEnable: false, camEnable: true });
        setCamEnable(true);
      } catch (e) {
        setCamEnable(false);
      }
    } else {
      if (localStream) {
        const tracks = localStream.getVideoTracks();
        // tracks.forEach((track: MediaStreamTrack) => { track.enabled = false; });
        tracks.forEach((track: MediaStreamTrack) => {
          track.stop();
          localStream.removeTrack(track);
        });

        if (localStream.getTracks().length === 0) {
          setLocalStream(null);
        }
      }
      setCamEnable(false);
    }
  };

  const checkMedia = async ({
    camEnable: camEnb, micEnable: micEnb,
  }: {
    camEnable: boolean;
    micEnable: boolean;
  }) => {
    if (!camEnb && !micEnb) return;
    if (localStream) {
      const videoTracks = localStream.getVideoTracks();
      const audioTracks = localStream.getAudioTracks();

      if ((camEnb && videoTracks.length === 0)
         || (micEnb && audioTracks.length === 0)) {
        const mStream = await navigator.mediaDevices.getUserMedia({
          video: camEnb,
          audio: micEnb,
        });

        const tracks = mStream.getTracks();

        if (tracks.length > 0) {
          tracks.forEach((track) => {
            localStream.addTrack(track);
          });
        }
      }
    } else {
      const mStream = await navigator.mediaDevices.getUserMedia({
        video: camEnb,
        audio: micEnb,
      });
      setLocalStream(mStream);
    }
  };

  const joinConference = async (roomId: string) => {
    try {
      const peerData = WebsocketHandler.getPeerData();

      const { id: jId, rtcConfiguration } = await ConferenceApi.joinRoom({
        peerId: peerData.peerId,
        roomId,
      });

      navigate(`/rooms/${roomId}/joins/${jId}`, {
        state: { rtcConfiguration },
      });

      stopMedia();
    } catch (e: any) {
      toast.error(e.message);
    }
  };

  const stopMedia = () => {
    if (!micEnable && !camEnable && !localStream) return;

    if (localStream) {
      const tracks = localStream.getVideoTracks();
      tracks.forEach((track: MediaStreamTrack) => {
        track.stop();
        localStream.removeTrack(track);
      });

      if (localStream.getTracks().length === 0) {
        setLocalStream(null);
      }
      setCamEnable(false);
      setMicEnable(false);
    }
  };

  const backToHome = () => {
    navigate('/');
  };

  const join = async () => {
    if (!code && !id) return;

    if (!userInfo) {
      setPodLoginModalOpen(true);
      return;
    }

    try {
      setJoinEnable(false);
      const peerData = WebsocketHandler.getPeerData();

      const { canJoin, room } = await ConferenceApi.roomRequest({
        peerId: peerData.peerId,
        code,
        id,
      });

      if (canJoin) {
        await joinConference(room.id);
      } else {
        await sleep(30000);
      }
    } catch (e: any) {
      toast.error(e.message);
    } finally {
      setJoinEnable(true);
    }
  };

  const onMessage = async ({ message }: any) => {
    const { serverAppId, content } = message;
    if (serverAppId === 'CONFERENCE') {
      switch (content.type) {
        case ConferenceEventType.REQUEST_STATUS: {
          // const { room, accepted } : {
          //   room : ConferenceRoomDTO, requestId? : string, accepted : boolean
          // } = content.content;
          // console.log('request status', requestId, accepted, room);
          setJoinEnable(true);
          break;
        }
        default: {
          // TODO:
        }
      }
    }
  };

  useEffect(() => {
    if (localStream) {
      if (!streamRef.current) return;
      streamRef.current.srcObject = localStream;
      streamRef.current.play();
    }
  }, [localStream]);

  useEffect(() => {
    WebsocketHandler.addEventListener('message', onMessage);

    return () => {
      WebsocketHandler.removeEventListener('message', onMessage);
    };
  }, [onMessage]);

  useEffect(() => {
    (async () => {
      try {
        const res = await ConferenceApi.getRoomInfo({
          id,
          code,
        });
        res.onlineMembers = res.onlineMembers.slice(0, 2);
        // res.onlineMembers = [res.onlineMembers[0], res.onlineMembers[0]];
        setRoomInfo(res);
      } catch (e: any) {
        console.log('getRoomInfo_err', e);
      }
    })();
  }, []);

  useEffect(() => {
    document.title = 'Join';
  }, []);

  let infoData;

  if (roomInfo === null) {
    infoData = <c.LoadinContainer><Loading1 /></c.LoadinContainer>;
  } else if (roomInfo.onlineMemberCount > 0) {
    infoData = (
      <div>
        online member :
        {' '}
        {roomInfo.onlineMemberCount}
        {' '}
        person
        <c.MembersContainer>
          {roomInfo.onlineMembers.map((member) => (
            <Member
              key={member.id}
              member={member}
            />
          ))}
        </c.MembersContainer>
      </div>
    );
  // } else if (roomInfo.memberCount > 0) {
  //   infoData = (
  //     <div>
  //       member :
  //       {' '}
  //       {roomInfo.memberCount}
  //       {' '}
  //       person
  //     </div>
  //   );
  } else {
    infoData = <c.NoMemberContainer>no one else is here</c.NoMemberContainer>;
  }

  return (
    <c.ContainerStyled>
      <Header />
      <c.InnerContainerStyled>
        <c.InfoContainer>
          <c.Text1Container>Ready to join?</c.Text1Container>
          <c.Text2Container>
            {infoData}
          </c.Text2Container>
          <c.ButtonsContainer>
            <c.JoinButtonContainer onClick={join} $enable={joinEnable}>
              <c.JoinButton />
            </c.JoinButtonContainer>
            <c.ReturnButtonContainer onClick={backToHome}>
              <c.ReturnButton />
            </c.ReturnButtonContainer>
          </c.ButtonsContainer>
        </c.InfoContainer>
        <c.MediaContainer>
          { !camEnable && <c.CameraOfText>Camera is off</c.CameraOfText> }
          <c.Video ref={streamRef} muted />
          <c.IconsContainer>
            <c.IconContainer
              $enable={micEnable}
              onClick={() => toggleMic()}
            >
              { micEnable ? <c.MicEnableIcon /> : <c.MicDisableIcon /> }
            </c.IconContainer>
            <c.IconContainer
              $enable={camEnable}
              onClick={() => toggleCam()}
            >
              { camEnable ? <c.CamEnableIcon /> : <c.CamDisableIcon /> }
            </c.IconContainer>
          </c.IconsContainer>
        </c.MediaContainer>
      </c.InnerContainerStyled>
      {podLoginModalOpen && <ModalPodOTPLogin onClose={() => setPodLoginModalOpen(false)} />}
    </c.ContainerStyled>
  );
}

export default RejoinPage;
