import config from '../commons/config';
import ReceiveMessageType from './types/receiveMessageType';

interface SocketEventMap {
  'message': any,
  'connect': any,
  'disconnect': any,
  'reconnect': any,
}
class Socket {
  ws: WebSocket | undefined;

  intervalId: any;

  pongCheckIntervalId: any;

  peerData: any;

  callbacks: any;

  constructor() {
    try {
      const peerStr = sessionStorage.getItem('peerData');
      if (peerStr) {
        const data = JSON.parse(peerStr);
        if (data.peerId && data.peerCode) {
          this.peerData = data;
        }
      }
    } catch (e) {
      //
    }

    this.callbacks = {};
  }

  private closeSocket() {
    this.ws?.close(4001);
  }

  private sendPing() {
    // console.log('sendPing');
    // wait until connection open;
    if (this.ws?.readyState !== 1) return;

    const obj = { type: 1, content: {} };
    this.ws?.send(JSON.stringify(obj));
  }

  private sendPong() {
    // console.log('sendPong');
    // wait until connection open;
    if (this.ws?.readyState !== 1) return;

    const obj = { type: 2, content: {} };
    this.ws?.send(JSON.stringify(obj));
  }

  private initPingPongInterval() {
    // this.intervalId = setInterval(() => {
    //   this.sendPing();
    //   this.pongCheckIntervalId = setTimeout(this.closeSocket, 4000);
    // }, 5000);

    this.sendPing();
    this.pongCheckIntervalId = setTimeout(this.closeSocket, 4000);
    this.intervalId = setTimeout(() => { this.initPingPongInterval(); }, 5000);
  }

  private fireEvent(eventName: string, data: any) {
    if (this.callbacks[eventName]) {
      this.callbacks[eventName].forEach((callback:Function) => {
        callback(data);
      });
    }
  }

  public initSocket({ token }: {token?: string}) {
    let address = `${config.WEBSOCKET_ADDRESS}?serverName=chat`;
    if (token) {
      address += `&token=${token}`;
    }

    if (this.peerData) {
      address += `&peerId=${this.peerData.peerId}`;
      address += `&peerCode=${this.peerData.peerCode}`;
    }

    // let error = false;
    const ws = new WebSocket(address);

    // (window as any).ws = ws;

    ws.onopen = () => {
      console.log('socket_open');
      // this.initPingPongInterval();
    };

    ws.onmessage = (e) => {
      // console.log('message');
      const data = JSON.parse(e.data);

      if (this.pongCheckIntervalId) {
        clearTimeout(this.pongCheckIntervalId);
        this.pongCheckIntervalId = null;
      }

      switch (data.type) {
        case ReceiveMessageType.SERVER_REGISTER: // server register
          if (this.peerData && this.peerData.peerId !== data.content.peerId) {
            this.peerData = data.content;
            this.fireEvent('reconnect', { peerData: this.peerData });
          } else {
            this.peerData = data.content;
            this.fireEvent('connect', { peerData: this.peerData });
          }
          sessionStorage.setItem('peerData', JSON.stringify(this.peerData));
          break;

        case ReceiveMessageType.PONG:
          // TODO: handle PONG
          break;

        case ReceiveMessageType.PING:
          this.sendPong();
          break;

        case ReceiveMessageType.MESSAGE: // message event
          this.fireEvent('message', { message: data.content, id: data.id });
          break;

        default:
          // TODO
      }
    };

    ws.onclose = (e) => {
      console.log('socket_close', e.code, e);
      // clearInterval(this.intervalId);
      clearTimeout(this.intervalId);
      this.fireEvent('disconnect', {});
      setTimeout(() => {
        this.initSocket({ token });
      }, 3000);
    };

    ws.onerror = (e) => {
      // error = true;
      console.log('socket_error', e);
    };
    this.ws = ws;
  }

  addEventListener<K extends keyof SocketEventMap>(eventName: K, callback:Function) {
    if (!this.callbacks[eventName]) {
      this.callbacks[eventName] = [];
    }

    this.callbacks[eventName].push(callback);
  }

  removeEventListener<K extends keyof SocketEventMap>(eventName: K, callback: Function) {
    if (this.callbacks[eventName]) {
      for (let i = this.callbacks[eventName].length - 1; i >= 0; i -= 1) {
        if (this.callbacks[eventName][i] === callback) {
          this.callbacks[eventName].splice(i, 1);
        }
      }
    }
  }

  getPeerData() {
    return this.peerData;
  }
}

const WebsocketHandler = new Socket();

export default WebsocketHandler;
