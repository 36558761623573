import React, { useEffect, useRef, useState } from 'react';
import useWindowSize from '../../../commons/hook/useWindowSize';
import { useMembers } from '../context/members.context';
import StreamCard from '../streamCard';
import * as c from './components';
import RoomMemberRoleType from '../../../types/roomMemberRoleType';
import { useUserInfoContext } from '../../../contexts/userInfoContext';
import UserDTO from '../../../dto/user.dto';

export interface UserCardRef {}

function Streams({
  localStream,
  displayStream,
  joinId,
  roomId,
}: {
  readonly localStream?: MediaStream | null;
  readonly displayStream?: MediaStream | null;
  readonly joinId: string;
  readonly roomId: string;
}) {
  const { members, getMemberByUserId } = useMembers();
  const streamCount = members.reduce(
    (count, member) => member.joinsInfo.reduce(
      (cnt, joinInfo) => cnt
          + (joinInfo.remoteStreams.length || 1),
      count,
    ),
    displayStream && localStream ? 1 : 0,
  );
  const { userInfo } :
  {userInfo?:UserDTO|null} = useUserInfoContext();

  const { width, height } = useWindowSize();
  const containerRef = useRef<HTMLDivElement>(null);

  const [info, setInfo] = useState({
    dimentions: {
      width: 0,
      height: 0,
    },
    // aspectRatio: 9 / 16,
    aspectRatio: 3 / 4,
    margin: 10,
    cardWidth: 0,
    cardHeight: 0,
  });

  const area = (increment: number) => {
    let i = 0;
    let w = 0;
    let h = increment * info.aspectRatio + info.margin * 2;
    while (i < streamCount) {
      if (w + increment > info.dimentions.width) {
        w = 0;
        h = h + increment * info.aspectRatio + info.margin * 2;
      }
      w = w + increment + info.margin * 2;
      i += 1;
    }
    if (h > info.dimentions.height || increment > info.dimentions.width) { return false; }
    return increment;
  };

  const resize = () => {
    if (!containerRef.current) return;

    info.dimentions.width = containerRef.current.offsetWidth - info.margin * 2;
    info.dimentions.height = containerRef.current.offsetHeight - info.margin * 2;

    // TODO loop (i recommend you optimize this)
    let max = 0;
    let i = 1;
    while (i < 5000) {
      const ar = area(i);
      if (ar === false) {
        max = i - 1;
        break;
      }
      i += 1;
    }

    // remove margins
    max -= info.margin * 2;

    info.cardWidth = max;
    info.cardHeight = max * info.aspectRatio;

    // console.log('resize', info.cardWidth, info.cardHeight);

    setInfo({ ...info });
  };

  useEffect(() => {
    resize();
  }, [width, height, streamCount]);

  const currentMember = getMemberByUserId(userInfo?.id);
  return (
    <c.Container ref={containerRef}>
      {
        members.map((member) => {
          const cards: {
            id: string;
            stream?: MediaStream | null,
            removeEnable: boolean;
            roomId: string;
            memberId: string;
            joinId: string;
            joinStatus: number;
            muted: boolean;
            mirror: boolean;
          }[] = [];

          member.joinsInfo.forEach((joinInfo) => {
            if (joinInfo.id === joinId) {
              if (localStream) {
                cards.push({
                  id: localStream.id,
                  stream: localStream,
                  removeEnable: false,
                  roomId,
                  memberId: member.id,
                  joinId: joinInfo.id,
                  joinStatus: joinInfo.status,
                  muted: true,
                  mirror: true,
                });
              }

              if (displayStream) {
                cards.push({
                  id: displayStream.id,
                  stream: displayStream,
                  removeEnable: false,
                  roomId,
                  memberId: member.id,
                  joinId: joinInfo.id,
                  joinStatus: joinInfo.status,
                  muted: true,
                  mirror: false,
                });
              }

              if (!displayStream && !localStream) {
                cards.push({
                  id: joinInfo.id,
                  stream: null,
                  removeEnable: false,
                  roomId,
                  memberId: member.id,
                  joinId: joinInfo.id,
                  joinStatus: joinInfo.status,
                  muted: true,
                  mirror: false,
                });
              }
            } else if (joinInfo.remoteStreams.length > 0) {
              joinInfo.remoteStreams.forEach((stream) => {
                cards.push({
                  id: stream.id,
                  stream,
                  removeEnable: ((currentMember?.role === RoomMemberRoleType.ADMIN
                  || currentMember?.role === RoomMemberRoleType.CREATOR)
                  && member.role !== RoomMemberRoleType.CREATOR)
                  || (member.user.id === currentMember.user.id && joinInfo.id !== joinId),
                  roomId,
                  memberId: member.id,
                  joinId: joinInfo.id,
                  joinStatus: joinInfo.status,
                  muted: false,
                  mirror: false,
                });
              });
            } else {
              cards.push({
                id: joinInfo.id,
                stream: null,
                removeEnable: ((currentMember?.role === RoomMemberRoleType.ADMIN
                  || currentMember?.role === RoomMemberRoleType.CREATOR)
                  && member.role !== RoomMemberRoleType.CREATOR)
                  || (member.user.id === currentMember.user.id && joinInfo.id !== joinId),
                roomId,
                memberId: member.id,
                joinId: joinInfo.id,
                joinStatus: joinInfo.status,
                muted: false,
                mirror: false,
              });
            }
          });

          return cards.map((data) => (
            <StreamCard
              width={info.cardWidth}
              height={info.cardHeight}
              margin={info.margin}
              key={data.id}
              name={userInfo?.id !== member.user?.id ? member.user?.username ?? member.user?.nickname : 'you'}
              userImage={member.user?.imageUrl}
              stream={data.stream}
              removeEnable={data.removeEnable}
              roomId={data.roomId}
              memberId={data.memberId}
              joinId={data.joinId}
              joinStatus={data.joinStatus}
              muted={data.muted}
              mirror={data.mirror}
            />
          ));
        })
      }
    </c.Container>
  );
}

Streams.defaultProps = {
  localStream: null,
  displayStream: null,
};

export default Streams;
