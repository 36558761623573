import { Modal } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FirstStep from './firstStep';
import * as c from './components';
import SecondStep from './secondStep';
import { userLoginAction } from '../../commons/helper';

function ModalPodOTPLogin({ onClose }: {onClose:any}) {
  const [step, setStep] = useState(1);
  const [{ expiresIn, keyId, cellphoneNumber }, setStepInfo] = useState<{
    expiresIn : number,
    keyId: string,
    cellphoneNumber: string,
  }>({ expiresIn: 120, keyId: '', cellphoneNumber: '' });
  const componentHashName = '#login-dialog';
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const goToSecondStep = (params: {expiresIn : number, keyId: string, cellphoneNumber: string}) => {
    setStepInfo({
      expiresIn: params.expiresIn,
      keyId: params.keyId,
      cellphoneNumber: params.cellphoneNumber,
    });
    setStep(2);
  };

  const goToFirstStep = () => {
    setStep(1);
  };

  const onUserLogin = (e:any) => {
    e.reload = true;
    userLoginAction(e);
  };

  const handleOnHashChange = () => {
    const isHashMatch = window.location.hash === componentHashName;
    if (!isHashMatch && isOpen) {
      onClose();
    }
  };

  const close = () => {
    navigate(-1);
    onClose();
  };

  useEffect(() => {
    // if (!isOpen) return;
    window.addEventListener('hashchange', handleOnHashChange);

    return () => window.removeEventListener('hashchange', handleOnHashChange);
  }, [handleOnHashChange]);

  useEffect(() => {
    setTimeout(() => {
      navigate(componentHashName);
      setIsOpen(true);
    }, 200);

    return () => {
      setIsOpen(false);
    };
  }, []);

  return (
    <Modal
      open
      onClose={close}
    >
      <c.Container>
        <c.ContainerInner>
          <c.ContentContainer>
            {
              step === 1
                ? (
                  <FirstStep
                    onComplete={goToSecondStep}
                    onClose={close}
                  />
                )
                : (
                  <SecondStep
                    keyId={keyId}
                    expiresIn={expiresIn}
                    cellphoneNumber={cellphoneNumber}
                    onPrevStep={goToFirstStep}
                    onLogin={onUserLogin}
                    onClose={close}
                  />
                )
            }
          </c.ContentContainer>
        </c.ContainerInner>
      </c.Container>
    </Modal>
  );
}

export default ModalPodOTPLogin;
