import React, { useEffect, useState } from 'react';
import {
  Divider, FormControl, MenuItem, Modal, Select, SelectChangeEvent, TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import RoomAccesType from '../../../../types/roomAccessType';
import {
  RowContainer,
  InputContainer,
  TextContainer,
  AdvanceContainer,
  AdvanceText,
  Body,
  Container,
  ContainerInner,
  Footer,
  Header,
  CloseIcon,
  AddIcon,
  MyLoadinButton,
  ArrowIcon,
} from './components';
import WebRTCNodeDTO from '../../../../dto/webrtcNode.dto';
import ConferenceApi from '../../../../services/gateway/conferenceApi';

function ConferenceSetupModal({
  onClose,
  onRegister,
}:{
  readonly onClose: () => any;
  readonly onRegister: (param: {
    code: string|undefined;
    name: string;
    accessType: number;
    maxMember?: number;
    maxMemberJoin?: number;
    nodeId?: string;
  }) => any;
}) {
  const dialogHashName = '#conference-setup';
  const [registerLoading, setRegisterLoading] = useState(false);
  const [advanceVisible, setAdvanceVisible] = useState(false);
  const [name, setName] = useState<string>();
  const [nameError, setNameError] = useState<string|null>();
  const [code, setCode] = useState<string>();
  const [codeError, setCodeError] = useState<string|null>();
  const [maxMember, setMaxMember] = useState<number>();
  const [maxMemberError, setMaxMemberError] = useState<string|null>();
  const [maxMemberJoin, setMaxMemberJoin] = useState<number>();
  const [maxMemberJoinError, setMaxMemberJoinError] = useState<string|null>();
  const [roomAccess, setRoomAccess] = useState(RoomAccesType.MEMBER);
  const [nodes, setNodes] = useState<WebRTCNodeDTO[]>([]);
  const [selectedNodeId, setSelectedNodeId] = useState<string>();
  const navigate = useNavigate();

  const { t } = useTranslation('conferenceSetup');

  const register = async () => {
    if (!name) {
      setNameError('please enter name');
      return;
    }

    if (name.length < 5) {
      setNameError('name length is lower than 5');
      return;
    }

    if (code && code.length < 10) {
      setCodeError('code length is lower than 10');
      return;
    }

    setRegisterLoading(true);
    await onRegister({
      code,
      name,
      accessType: roomAccess,
      maxMember,
      maxMemberJoin,
      nodeId: selectedNodeId,
    });
    setRegisterLoading(false);
  };

  const handleAccessTypeChange = async (event: SelectChangeEvent) => {
    setRoomAccess(Number(event.target.value));
  };

  const handleSelectedNodeChange = async (event: SelectChangeEvent) => {
    setSelectedNodeId(event.target.value);
  };

  const closeModal = () => {
    if (window.location.hash === '#conference-setup') {
      navigate(-1);
    }
    onClose();
  };

  useEffect(() => {
    window.location.assign(dialogHashName);

    (async () => {
      try {
        const { nodes: nodeList } = await ConferenceApi.getNodes({});
        setNodes(nodeList);
      } catch (e) {
        console.log('get_nodes_err', e);
      }
    })();
  }, []);

  const handleOnHashChange = () => {
    const isHashMatch = window.location.hash === dialogHashName;
    if (!isHashMatch) {
      closeModal();
    }
  };

  useEffect(() => {
    window.addEventListener('hashchange', handleOnHashChange);

    return () => window.removeEventListener('hashchange', handleOnHashChange);
  }, [handleOnHashChange]);

  return (
    <Modal
      open
      onClose={closeModal}
    >
      <Container>
        <ContainerInner>
          <Header>
            <div>{t('setup')}</div>
            <div>
              <CloseIcon
                onClick={closeModal}
              />
            </div>
          </Header>
          <Divider />
          <Body>
            <RowContainer>
              <TextContainer>name</TextContainer>
              <InputContainer>
                <TextField
                  required
                  error={typeof nameError === 'string'}
                  helperText={nameError}
                  // label={t('name')}
                  placeholder="enter room name"
                  variant="standard"
                  fullWidth
                  inputProps={{ style: { padding: '15px 15px' } }}
                  onChange={(e) => {
                    setName(e.target.value);
                    if (nameError) {
                      setNameError(null);
                    }
                  }}
                />
              </InputContainer>
            </RowContainer>
            <AdvanceText
              onClick={() => setAdvanceVisible(!advanceVisible)}
            >
              {t('advance')}
              <ArrowIcon $enable={advanceVisible} />
            </AdvanceText>
            <AdvanceContainer $visible={advanceVisible}>
              <RowContainer>
                <TextContainer>code </TextContainer>
                <InputContainer>
                  <TextField
                    error={typeof codeError === 'string'}
                    helperText={codeError}
                    // label={t('code')}
                    placeholder="enter room code"
                    variant="standard"
                    inputProps={{ style: { padding: '15px 15px', textTransform: 'lowercase' } }}
                    fullWidth
                    onChange={(e) => {
                      setCode(e.target.value?.toLowerCase());
                      if (codeError) {
                        setCodeError(null);
                      }
                    }}
                  />
                </InputContainer>
              </RowContainer>
              <RowContainer>
                <TextContainer>access type </TextContainer>
                <InputContainer>
                  <FormControl size="small" sx={{ width: '100%' }}>
                    <Select
                      value={String(roomAccess)}
                      onChange={handleAccessTypeChange}
                    >
                      <MenuItem selected value={RoomAccesType.MEMBER}>member</MenuItem>
                      <MenuItem value={RoomAccesType.FREE}>free</MenuItem>
                      <MenuItem value={RoomAccesType.WITH_REQUEST}>with request</MenuItem>
                    </Select>
                  </FormControl>
                </InputContainer>
              </RowContainer>
              <RowContainer>
                <TextContainer>host</TextContainer>
                <InputContainer>
                  <FormControl size="small" sx={{ width: '100%' }}>
                    <Select
                      value={selectedNodeId || ''}
                      onChange={handleSelectedNodeChange}
                    >
                      {
                        nodes.map((node) => (
                          <MenuItem
                            key={node.id}
                            selected={node.id === selectedNodeId}
                            value={node.id}
                          >
                            {node.name ?? 'no-name'}
                          </MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                </InputContainer>
              </RowContainer>
              <RowContainer>
                <TextContainer>max member</TextContainer>
                <InputContainer>
                  <TextField
                    error={typeof maxMemberError === 'string'}
                    helperText={maxMemberError}
                    placeholder="enter max member"
                    variant="standard"
                    inputProps={{ style: { padding: '15px 15px' } }}
                    fullWidth
                    type="number"
                    onChange={(e) => {
                      setMaxMember(Number(e.target.value));
                      if (maxMemberError) {
                        setMaxMemberError(null);
                      }
                    }}
                  />
                </InputContainer>
              </RowContainer>
              <RowContainer>
                <TextContainer>max member join</TextContainer>
                <InputContainer>
                  <TextField
                    error={typeof maxMemberJoinError === 'string'}
                    helperText={maxMemberJoinError}
                    placeholder="enter max member join"
                    variant="standard"
                    inputProps={{ style: { padding: '15px 15px' } }}
                    fullWidth
                    type="number"
                    onChange={(e) => {
                      setMaxMemberJoin(Number(e.target.value));
                      if (maxMemberJoinError) {
                        setMaxMemberJoinError(null);
                      }
                    }}
                  />
                </InputContainer>
              </RowContainer>
            </AdvanceContainer>
          </Body>
          <Divider />
          <Footer>
            <MyLoadinButton
              loading={registerLoading}
              loadingPosition="center"
              onClick={register}
              variant="contained"
            >
              <AddIcon />
            </MyLoadinButton>
          </Footer>
        </ContainerInner>
      </Container>
    </Modal>
  );
}

export default ConferenceSetupModal;
