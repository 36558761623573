import React, {
  createContext, useContext, useMemo, useState,
} from 'react';
import RoomDTO from '../../../dto/conferenceRoom.dto';

const conferenceInfoContext = createContext< {
  conference: RoomDTO|null,
  setConference: any,
  isConferenceShow: any,
}>({
  conference: null,
  setConference: () => {},
  isConferenceShow: () => {},
});

export function ConferenceInfoProvider({ children }: any) {
  const [conference, setConference] = useState<RoomDTO|null>(null);
  const value = useMemo(
    () => ({
      conference,
      setConference,
      isConferenceShow: () => conference != null,
    }),
    [conference],
  );

  return (
    <conferenceInfoContext.Provider value={value}>
      {children}
    </conferenceInfoContext.Provider>
  );
}

export function useConferenceInfo() {
  return useContext(conferenceInfoContext);
}
